import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreateCompanyTypeFilterArgs,
  CreateCompanyTypeFilterData,
  DeleteCompanyTypeFilterArgs,
  DeleteCompanyTypeFilterData,
  FetchCompanyTypeFiltersArgs,
  FetchCompanyTypeFiltersData,
  UpdateCompanyTypeFilterArgs,
  UpdateCompanyTypeFilterData,
} from "./CompanyTypeFiltersTypes";

const CompanyTypeFiltersServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanyTypeFilters: builder.query<
      FetchCompanyTypeFiltersData,
      FetchCompanyTypeFiltersArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `auditions/company-type-filter?search=${args.search}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchCompanyTypeFiltersData };
      },
      providesTags: ["CompanyTypeFilters"],
    }),
    createCompanyTypeFilter: builder.mutation<
      CreateCompanyTypeFilterData,
      CreateCompanyTypeFilterArgs
    >({
      query: (args) => ({
        url: "auditions/company-type-filter",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["CompanyTypeFilters"],
    }),
    updateCompanyTypeFilter: builder.mutation<
      UpdateCompanyTypeFilterData,
      UpdateCompanyTypeFilterArgs
    >({
      query: (args) => ({
        url: `auditions/company-type-filter/${args.id}`,
        method: "PUT",
        body: {
          title: args.title,
        },
      }),
      invalidatesTags: ["CompanyTypeFilters"],
    }),
    deleteCompanyTypeFilter: builder.mutation<
      DeleteCompanyTypeFilterData,
      DeleteCompanyTypeFilterArgs
    >({
      query: (args) => ({
        url: `auditions/company-type-filter/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CompanyTypeFilters"],
    }),
  }),
});

export const {
  useCreateCompanyTypeFilterMutation,
  useFetchCompanyTypeFiltersQuery,
  useLazyFetchCompanyTypeFiltersQuery,
  useUpdateCompanyTypeFilterMutation,
  useDeleteCompanyTypeFilterMutation,
} = CompanyTypeFiltersServices;
