import { InitialCompanyStateType } from "../assets/data/initialState";
import { FetchCompaniesItem } from "../services/CompanyServices/CompanyTypes";
import { CompanyLocation } from "../types/MainTypes/CompanyLocation";
import { CompanyType } from "../types/MainTypes/CompanyType";
import { FormikType, OmitFields } from "../types/types";

type Props = FormikType<InitialCompanyStateType> & {
  selectedCompany: FetchCompaniesItem;
};

const setCompanyFormikValues = ({ formik, selectedCompany }: Props) => {
  formik.setFieldValue("name", selectedCompany.name);

  formik.setFieldValue("companyLocation", selectedCompany.companyLocation);
  formik.setFieldValue("image", selectedCompany.image);
  formik.setFieldValue("newImage", undefined);
  formik.setFieldValue("appearance", selectedCompany.appearance);
  formik.setFieldValue("description", selectedCompany.description);
  formik.setFieldValue("websiteLink", selectedCompany.websiteLink);
  formik.setFieldValue("instagramLink", selectedCompany.instagramLink);
  formik.setFieldValue("emailLink", selectedCompany.emailLink);
  formik.setFieldValue("isActive", selectedCompany.isActive);

  //COMPANY TYPE
  const companyTypes: CompanyType[] = [];

  if (selectedCompany.companyToCompanyTypes) {
    selectedCompany.companyToCompanyTypes.map((c) =>
      companyTypes.push(c.companyType)
    );
  }

  formik.setFieldValue("companyTypes", companyTypes);
};

export default setCompanyFormikValues;
