import { AuditionIcon, DiscoverIcon, HubIcon, UserIcon } from "../icons/Icons";

export const navbarTabs = [
  {
    icon: AuditionIcon,
    page: "auditions",
    link: "/auditions",
  },
  {
    icon: DiscoverIcon,
    page: "discover",
    link: "/discover",
  },
  {
    icon: HubIcon,
    page: "hub",
    link: "/hub",
  },
  {
    icon: UserIcon,
    page: "users",
    link: "/users",
  },
];
