import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { navbarTabs } from "../../assets/data/arrays";
import { LogoutIcon } from "../../assets/icons/Icons";
import { AlertModal, LogoCard } from "../Components";
import { useFetchStatisticsQuery } from "../../services/AdminServices/AdminServices";

type Props = {
  page: "auditions" | "discover" | "hub" | "users";
};

export default function Navbar({ page }: Props) {
  const navigate = useNavigate();

  const [isAlertModalVisible, setIsAlertModalVisible] =
    useState<boolean>(false);

  const { data: statistics } = useFetchStatisticsQuery();

  const renderNavbarIcon = () => {
    return (
      <>
        {navbarTabs.map((tab, index) => (
          <tab.icon
            key={index}
            onClick={() => navigate(tab.link)}
            className={`cursor-pointer hover:opacity-70 ${
              page === tab.page ? "opacity-100" : "opacity-20"
            } transition-all duration-500`}
          />
        ))}
      </>
    );
  };

  const handleLogout = () => {
    toast.success("Successfully logged-out");
    localStorage.removeItem("IAAdminToken");
    navigate("/login");
  };

  return (
    <div className="w-screen h-20 flex items-center px-8 border-b-2 border-black justify-between">
      <AlertModal
        title="Logout"
        description="Are you sure you want to log out?"
        buttonText="Logout"
        onClickButton={handleLogout}
        isOpen={isAlertModalVisible}
        closeModal={() => setIsAlertModalVisible(false)}
      />
      <div className="flex">
        <LogoCard />
        <div className="ml-12 w-full h-full relative flex flex-row justify-center items-center space-x-10">
          {renderNavbarIcon()}
        </div>
      </div>
      <div className="flex justify-end">
        {statistics && (
          <div className="flex mr-2 items-center">
            <p className="text-xl font-bold mr-2">
              {statistics.subscribedUsers}
            </p>
            <p className="text-sm mr-4">Subscribers</p>
            <p className="text-xl font-bold mr-2">{statistics.totalUsers}</p>
            <p className="text-sm mr-4">Users</p>
          </div>
        )}
        <LogoutIcon onClick={() => setIsAlertModalVisible(true)} />
      </div>
    </div>
  );
}
