import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchSubmissionsItem } from "../../services/SubmissionServices/SubmissionTypes";
import { RootState } from "../store";

export type initialSubmissionStateType = {
  submissions: FetchSubmissionsItem[] | null;
  selectedSubmission: FetchSubmissionsItem | null;
};

export const initialSubmissionState: initialSubmissionStateType = {
  submissions: null,
  selectedSubmission: null,
};

export const submissionsSlice = createSlice({
  name: "submissions",
  initialState: initialSubmissionState,
  reducers: {
    setSubmissions: (
      state,
      action: PayloadAction<FetchSubmissionsItem[] | null>
    ) => {
      state.submissions = action.payload;
    },
    setSelectedSubmission: (
      state,
      action: PayloadAction<FetchSubmissionsItem | null>
    ) => {
      state.selectedSubmission = action.payload;
    },
  },
});

export const selectedSubmissionSelector = (state: RootState) =>
  state.submissionsSlice.selectedSubmission;

export const submissionsSelector = (state: RootState) =>
  state.submissionsSlice.submissions;

export const { setSelectedSubmission, setSubmissions } =
  submissionsSlice.actions;

export default submissionsSlice.reducer;
