import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { useLazyFetchAgentsQuery } from "../../../../services/AgentServices/AgentServices";
import { Agent } from "../../../../types/MainTypes/Agent";
import { FormikType } from "../../../../types/types";
import asyncTimeout from "../../../../utils/asyncTimeout";
import CustomButton from "../../buttons/CustomButton";
import DataRowCard from "../../cards/DataRowCard";
import SearchBar from "../../inputfields/SearchInput";
import BasicLoader from "../../loader/BasicLoader";

type Props = FormikType<InitialAuditionStateType> & {
  agents: Agent[];
  setAgents: (values: Agent[]) => void;
  selectedAgent: Agent | null;
  setSelectedAgent: (values: Agent | null) => void;
  closeModal: () => void;
};

export default function LinkToAgentAgents({
  formik,
  agents,
  setAgents,
  selectedAgent,
  setSelectedAgent,
  closeModal,
}: Props) {
  const [search, setSearch] = useState<string>("");

  const take = 10;
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  //APIS
  const [fetchAgentsQuery, { isLoading, isFetching }] =
    useLazyFetchAgentsQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;

    if (search) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchAgents();
      }, 300);
    } else {
      fetchAgents();
    }
    return () => clearTimeout(timeout);
  }, [search]);

  const fetchAgents = async () => {
    try {
      const response = await fetchAgentsQuery(
        {
          search,
          active: "true",
          skip: 0,
          take: take,
        },
        true
      ).unwrap();

      if (response) {
        const filteredAgents = response.filter(
          (a) => selectedAgent?.id !== a.id
        );

        setAgents(filteredAgents);
      }

      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsTyping(false);
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreAgents();
    }
  };

  const fetchMoreAgents = async () => {
    setIsLoadingMore(true);
    try {
      const [response] = await Promise.all([
        fetchAgentsQuery(
          {
            search,
            active: "true",
            skip: skip + take,
            take: take,
          },
          true
        ).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!agents) return;

        const filteredAgents = response.filter(
          (a) => selectedAgent?.id !== a.id
        );

        const newAgents = agents.concat(filteredAgents);

        setAgents(newAgents);
      }

      setSkip(skip + take);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleClickAgent = ({ agent }: { agent: Agent }) => {
    if (selectedAgent) {
      const filteredAgents = agents.filter((a) => a.id !== agent.id);
      filteredAgents.push(selectedAgent);
      setAgents(filteredAgents);
    } else {
      const filteredAgents = agents.filter((a) => a.id !== agent.id);
      setAgents(filteredAgents);
    }
    setSelectedAgent(agent);
  };

  const handleSetLinks = () => {
    formik.setFieldValue("agent", selectedAgent);
    closeModal();
  };

  const renderAgents = () => {
    if (isLoading || isFetching || isTyping)
      return (
        <div className="w-full">
          <BasicLoader color="black" />
        </div>
      );
    if (!agents)
      return (
        <div className="w-full">
          <p className="font-Switzer text-base text-black">No Agents</p>
        </div>
      );

    return (
      <div className="w-full h-[calc(27vh)] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={50}
        >
          <div className="grid grid-cols-2 gap-y-2 gap-x-5">
            {agents.map((a, i) => (
              <DataRowCard
                key={i}
                text={a.name}
                onClickIcon={() => handleClickAgent({ agent: a })}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="h-full w-full justify-between flex flex-col">
      <div>
        <p className="font-bold font-Switzer text-2xl text-black my-4">
          Agents
        </p>
        <div className="ml-14">
          <SearchBar value={search} onChange={setSearch} />
        </div>
        {renderAgents()}
      </div>
      <div className="flex-row flex items-center justify-start my-4">
        <CustomButton
          text="Set Links"
          className="medium-black-button"
          onClick={handleSetLinks}
        />
        <CustomButton
          className="medium-white-button ml-2"
          text="Cancel"
          onClick={closeModal}
        />
      </div>
    </div>
  );
}
