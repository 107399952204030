import React, { useEffect } from "react";
import { FetchCompaniesItem } from "../../../../services/CompanyServices/CompanyTypes";
import { Company } from "../../../../types/MainTypes/Company";
import DataRowCard from "../../cards/DataRowCard";

type Props = {
  companies: Company[];
  selectedCompany: Company | null;
  setSelectedCompany: (values: Company | null) => void;
};

export default function LinkToCompanySelectedCompanies({
  companies,
  selectedCompany,
  setSelectedCompany,
}: Props) {
  const handleClickCompany = () => {
    if (selectedCompany) {
      companies.unshift(selectedCompany);
    }
    setSelectedCompany(null);
  };

  return (
    <div className="ml-4">
      <p className="font-bold font-Switzer text-2xl text-black my-4">
        Selected Company
      </p>
      <div className="w-full h-[35vh] overflow-y-auto">
        {selectedCompany ? (
          <DataRowCard
            text={selectedCompany.name}
            isSelected={true}
            onClickIcon={() => handleClickCompany()}
          />
        ) : null}
      </div>
    </div>
  );
}
