import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { InitialPostStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  addPostTag,
  deletePostTag,
  selectedPostTagSelector,
  selectedPostTagsSelector,
  setSelectedPostTag,
  updateSelectedPostTag,
} from "../../../../redux/slices/postTagsSlice";
import { isErrorWithMessage } from "../../../../services/helper";
import {
  useCreatePostTagMutation,
  useDeletePostTagMutation,
  useUpdatePostTagMutation,
} from "../../../../services/PostTagsServices/PostTagsServices";
import { FormikType } from "../../../../types/types";
import asyncTimeout from "../../../../utils/asyncTimeout";
import updateItemInArrayIfExists from "../../../../utils/updateItemInArrayIfExists";
import { PostTagTitleSchema } from "../../../../utils/validationSchema";
import CustomButton from "../../buttons/CustomButton";
import TextInput from "../../inputfields/TextInput";

type Props = FormikType<InitialPostStateType>;

export default function PostTagsModalEdit({ formik }: Props) {
  const selectedPostTag = useIASelector(selectedPostTagSelector);
  const selectedPostTags = useIASelector(selectedPostTagsSelector);
  const dispatch = useIADispatch();

  //APIS
  const [createPostTag] = useCreatePostTagMutation();
  const [updatePostTag] = useUpdatePostTagMutation();
  const [deletePostTagQuery] = useDeletePostTagMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    setFieldTouched("title", false);
    setFieldError("title", "");

    if (!selectedPostTag) {
      setFieldValue("title", "");
      return;
    }
    setFieldValue("title", selectedPostTag.title);
  }, [selectedPostTag]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      const [response] = await Promise.all([
        createPostTag({
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully created");
        dispatch(addPostTag(response));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async () => {
    if (!selectedPostTag) return;

    setIsLoading(true);

    try {
      const [response] = await Promise.all([
        updatePostTag({
          id: selectedPostTag.id,
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully updated");
        dispatch(updateSelectedPostTag(response));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    setIsDeleting(true);
    if (!selectedPostTag) return;
    try {
      const [response] = await Promise.all([
        deletePostTagQuery({ id: selectedPostTag.id }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully deleted");
        dispatch(setSelectedPostTag(null));
        dispatch(deletePostTag(response.id));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: selectedPostTag ? onUpdate : onSave,
    validationSchema: PostTagTitleSchema,
  });

  return (
    <div className="h-full w-full justify-between flex flex-col border-l-2 border-black">
      <div className="mx-4 ">
        <p className="font-Switzer font-bold text-black text-2xl my-4">
          {selectedPostTag ? "Update Post Tag" : "Create Post Tag"}
        </p>
        <p className="font-Switzer font-normal text-black text-base">
          {selectedPostTag
            ? "Update post tag to the library."
            : "Add a new post tag to the library."}
        </p>
        <TextInput
          label="Post Tag"
          name="Post Tag"
          type="text"
          value={values.title}
          required={true}
          onChange={(text) => setFieldValue("title", text)}
          isTouched={touched.title}
          error={errors.title}
        />
        <CustomButton
          text={selectedPostTag ? "Update Type" : "Add Type"}
          className="medium-green-button mt-6"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading || isDeleting}
        />
      </div>
      <div>
        <CustomButton
          text="Delete Type"
          onClick={onDelete}
          className="medium-white-button mb-4 ml-4"
          loaderColor="black"
          isLoading={isDeleting}
          disabled={isLoading || isDeleting}
        />
      </div>
    </div>
  );
}
