import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { ErrorIcon } from "../../../assets/icons/Icons";
import { useGetPresignedDownloadUrlQuery } from "../../../services/FileServices/FileServices";
import BasicLoader from "../loader/BasicLoader";

type Props = {
  fileName: string | undefined;
};

export default function AsyncVideo({ fileName }: Props) {
  const [isVideoError, setIsVideoError] = useState(false);

  const { data, isLoading, isError } = useGetPresignedDownloadUrlQuery(
    {
      imageKey: fileName ?? "",
    } ?? skipToken
  );

  if (!data || isLoading)
    return (
      <div className="h-full w-full flex justify-center items-center rounded-lg">
        <BasicLoader color="black" />
      </div>
    );

  if (isError || isVideoError)
    return (
      <div
        className="w-full h-full flex items-center font-medium text-lg text-ErrorRed justify-center"
        role="alert"
      >
        <ErrorIcon className="mr-2" width={"28"} height={"28"} />
        Video unavailable
      </div>
    );

  return (
    <video
      className="h-full w-full rounded-lg object-cover"
      src={data}
      onError={(error) => setIsVideoError(true)}
      controls
    />
  );
}
