import API from "../../redux/api";

import {
  CreateCompanyTypesArgs,
  CreateCompanyTypesReturnedData,
  DeleteCompanyTypeArgs,
  DeleteCompanyTypeReturnedData,
  FetchCompanyTypesArgs,
  FetchCompanyTypesReturnedData,
  UpdateCompanyTypeArgs,
  UpdateCompanyTypeReturnedData,
} from "./CompanyTypesTypes";

const CompanyTypesServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanyTypes: builder.query<
      FetchCompanyTypesReturnedData,
      FetchCompanyTypesArgs
    >({
      query: (args) => ({
        url: `companies/type?search=${args.search}&take=${args.take}&skip=${args.skip}`,
        method: "GET",
      }),
      providesTags: ["CompanyTypes"],
    }),
    createCompanyType: builder.mutation<
      CreateCompanyTypesReturnedData,
      CreateCompanyTypesArgs
    >({
      query: (args) => ({
        url: "companies/type",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["CompanyTypes"],
    }),
    updateCompanyType: builder.mutation<
      UpdateCompanyTypeReturnedData,
      UpdateCompanyTypeArgs
    >({
      query: (args) => ({
        url: `companies/type/${args.id}`,
        method: "PUT",
        body: {
          title: args.title,
        },
      }),
      invalidatesTags: ["CompanyTypes"],
    }),
    deleteCompanyType: builder.mutation<
      DeleteCompanyTypeReturnedData,
      DeleteCompanyTypeArgs
    >({
      query: (args) => ({
        url: `companies/type/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CompanyTypes"],
    }),
  }),
});

export const {
  useLazyFetchCompanyTypesQuery,
  useCreateCompanyTypeMutation,
  useUpdateCompanyTypeMutation,
  useDeleteCompanyTypeMutation,
} = CompanyTypesServices;
