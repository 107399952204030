import React, { useState } from "react";
import { TrashBinIcon } from "../../assets/icons/Icons";
import { AlertModal } from "../Components";
import { useDeleteUserMutation } from "../../services/UserServices/UserServices";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import { closeOverlay, openOverlay } from "../../redux/slices/overlaySlice";
import { deleteUser, selectedUserSelector } from "../../redux/slices/userSlice";
import asyncTimeout from "../../utils/asyncTimeout";
import { toast } from "react-toastify";
import { isErrorWithMessage } from "../../services/helper";
import API from "../../redux/api";

export default function UserDelete() {
  const [isAlertModalVisible, setIsAlertModalVisible] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteUserQuery] = useDeleteUserMutation();
  const dispatch = useIADispatch();
  const selectedUser = useIASelector(selectedUserSelector);

  const onDelete = async () => {
    dispatch(
      openOverlay({
        text: "Deleting",
      })
    );
    if (!selectedUser) return;
    setIsDeleting(true);
    try {
      await Promise.all([
        deleteUserQuery({
          userId: selectedUser.id,
        }).unwrap(),
        asyncTimeout(300),
      ]);
      dispatch(API.util.invalidateTags(["UserStatistics"]));
      dispatch(deleteUser(selectedUser.id));
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsDeleting(false);
      dispatch(closeOverlay());
    }
  };

  return (
    <div className="mt-28 mr-8 flex flex-col justify-between h-fit">
      <AlertModal
        title="Delete User"
        description="By deleting this user you will lose this user's data and details. This action is irreversible."
        buttonText="Delete User"
        onClickButton={onDelete}
        isOpen={isAlertModalVisible}
        closeModal={() => setIsAlertModalVisible(false)}
      />
      <button
        className="mt-8 hover:opacity-80 h-14 w-14 flex justify-center items-center rounded-lg border-black border-2"
        onClick={() => setIsAlertModalVisible(true)}
        disabled={isDeleting}
      >
        <TrashBinIcon />
      </button>
    </div>
  );
}
