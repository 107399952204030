import { Dialog } from "@headlessui/react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  InitialTouchedUserState,
  InitialUserState,
} from "../../../../assets/data/initialState";
import { useIADispatch } from "../../../../redux/hooks";
import { addUser, setSelectedUser } from "../../../../redux/slices/userSlice";
import {
  isErrorWithMessage,
  isErrorWithMultipleMessages,
} from "../../../../services/helper";
import { useCreateUserMutation } from "../../../../services/UserServices/UserServices";
import mobileParser from "../../../../utils/mobileParser";
import { CreateUserSchema } from "../../../../utils/validationSchema";
import CustomButton from "../../buttons/CustomButton";
import CustomModal from "../../CustomModal";
import ModalHeader from "../../headers/ModalHeader";
import MobileInput from "../../inputfields/MobileInput";
import TextInput from "../../inputfields/TextInput";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function CreateUserModal({ isOpen, closeModal }: Props) {
  useEffect(() => {
    setValues(InitialUserState);
    setTouched(InitialTouchedUserState);
  }, [isOpen]);

  const [createUser, { isLoading }] = useCreateUserMutation();
  const dispatch = useIADispatch();

  const onCreate = async () => {
    try {
      const formattedNumber = mobileParser(values.mobile);

      const response = await createUser({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobile: formattedNumber,
        password: values.password,
        productId: "iafree",
        isDiscountCodeUsed: false,
      }).unwrap();

      if (!response) return;
      dispatch(addUser(response.userDetails));
      dispatch(setSelectedUser(response.userDetails));
      closeModal();
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again"
      );
    }
  };

  const {
    setTouched,
    setValues,
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobile: "",
    },
    onSubmit: onCreate,
    validationSchema: CreateUserSchema,
  });

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/2">
        <ModalHeader
          title="User"
          description="Create a user with a free account"
          closeModal={closeModal}
        />
        <div className="px-4 h-full">
          <div className="grid grid-cols-2 gap-4">
            <TextInput
              label="First Name"
              name="First Name"
              type="text"
              value={values.firstName}
              required={true}
              onChange={(text) => setFieldValue("firstName", text)}
              isTouched={touched.firstName}
              error={errors.firstName}
            />
            <TextInput
              label="Last Name"
              name="Last Name"
              type="text"
              value={values.lastName}
              required={true}
              onChange={(text) => setFieldValue("lastName", text)}
              isTouched={touched.lastName}
              error={errors.lastName}
            />
          </div>
          <TextInput
            label="Email"
            name="Email"
            type="text"
            value={values.email}
            required={true}
            onChange={(text) => setFieldValue("email", text)}
            isTouched={touched.email}
            error={errors.email}
          />
          <MobileInput
            value={values.mobile}
            onChange={(text) => setFieldValue("mobile", text)}
            isTouched={touched.mobile}
            error={errors.mobile}
          />
          <TextInput
            label="Password"
            name="Password"
            type="password"
            value={values.password}
            required={true}
            onChange={(text) => setFieldValue("password", text)}
            isTouched={touched.password}
            error={errors.password}
          />
          <TextInput
            label="Confirm Password"
            name="Confirm Password"
            type="password"
            value={values.confirmPassword}
            required={true}
            onChange={(text) => setFieldValue("confirmPassword", text)}
            isTouched={touched.confirmPassword}
            error={errors.confirmPassword}
          />
          <CustomButton
            onClick={handleSubmit}
            text={"Create"}
            className="medium-black-button my-4"
            isLoading={isLoading}
          />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
