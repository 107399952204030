import { buildQueries } from "@testing-library/react";
import API from "../../redux/api";
import {
  LoginArgs,
  LoginReturnedData,
  FetchMeArgs,
  FetchMeReturnedData,
  FetchStatisticsData,
} from "./AdminTypes";

const AdminServices = API.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginReturnedData, LoginArgs>({
      query: (args) => ({
        url: "admin/login",
        method: "POST",
        body: args,
      }),
    }),
    fetchMe: builder.query<FetchMeReturnedData, void>({
      query: () => ({
        url: "admin/me",
        method: "GET",
      }),
    }),
    fetchStatistics: builder.query<FetchStatisticsData, void>({
      query: () => ({
        url: "admin/statistics",
        method: "GET",
      }),
      providesTags: ["UserStatistics"],
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyFetchMeQuery,
  useFetchStatisticsQuery,
} = AdminServices;
