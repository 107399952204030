import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { InitialPostStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  addSelectedPostTag,
  postTagsSelector,
  selectedPostTagSelector,
  setPostTags,
  setSelectedPostTag,
  setSelectedPostTags,
} from "../../../../redux/slices/postTagsSlice";
import { useLazyFetchPostTagsQuery } from "../../../../services/PostTagsServices/PostTagsServices";
import { FormikType } from "../../../../types/types";
import asyncTimeout from "../../../../utils/asyncTimeout";
import DataRowCard from "../../cards/DataRowCard";
import BasicLoader from "../../loader/BasicLoader";

type Props = FormikType<InitialPostStateType>;

export default function PostTagsModalPostTags({ formik }: Props) {
  const dispatch = useIADispatch();
  const postTags = useIASelector(postTagsSelector);
  const selectedPostTag = useIASelector(selectedPostTagSelector);

  const take = 10;
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  //APIS
  const [fetchPostTagsQuery, { isLoading, isFetching }] =
    useLazyFetchPostTagsQuery();

  useEffect(() => {
    setSkip(0);
    dispatch(setSelectedPostTag(null));
    fetchPostTags();
  }, []);

  const fetchPostTags = async () => {
    try {
      const response = await fetchPostTagsQuery({
        search: "",
        skip: 0,
        take: take,
      }).unwrap();

      if (response) {
        const filteredPostTags = response.filter(
          (p1) => !formik.values.postTags.some((p2) => p1.id === p2.id)
        );

        dispatch(setPostTags(filteredPostTags));
      }

      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMorePostTags();
    }
  };
  const fetchMorePostTags = async () => {
    try {
      const response = await fetchPostTagsQuery({
        search: "",
        skip: skip + take,
        take: take,
      }).unwrap();

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!postTags) return;
        const newPostTags = postTags.concat(response);
        dispatch(setPostTags(newPostTags));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderPostTags = () => {
    if ((isLoading || isFetching) && !isLoadingMore)
      return (
        <div className="w-full">
          <BasicLoader color="black" />
        </div>
      );
    if (!postTags)
      return (
        <div className="w-full">
          <p className="font-Switzer text-base text-black">No Post Tags</p>
        </div>
      );

    return (
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={50}
        >
          {postTags.map((p, i) => (
            <DataRowCard
              key={i}
              text={p.title}
              onClickContainer={() => dispatch(setSelectedPostTag(p))}
              onClickIcon={() => dispatch(addSelectedPostTag(p))}
              isSelectedToUpdate={
                selectedPostTag ? selectedPostTag.id === p.id : false
              }
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div>
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Post Tags
      </p>
      {renderPostTags()}
    </div>
  );
}
