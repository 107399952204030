import React from "react";

export default function AuditionIcon({
  onClick,
  className,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={className}
      onClick={onClick}
    >
      <g
        id="theater_comedy_black_24dp_1_"
        data-name="theater_comedy_black_24dp (1)"
        transform="translate(0.11 -0.463)"
      >
        <g
          id="Group_672"
          data-name="Group 672"
          transform="translate(-0.11 0.463)"
        >
          <rect
            id="Rectangle_1316"
            data-name="Rectangle 1316"
            width="40"
            height="40"
            fill="none"
          />
        </g>
        <g
          id="Group_674"
          data-name="Group 674"
          transform="translate(1.656 3.429)"
        >
          <g id="Group_673" data-name="Group 673">
            <path
              id="Path_1001"
              data-name="Path 1001"
              d="M11,2v9.369h5.823v5.638c.083-1.72,1.9-3.083,4.143-3.083,2.3,0,4.16,1.431,4.16,3.2h-8.3v6.064a9.516,9.516,0,0,0,4.16.954,10.112,10.112,0,0,0,9.983-10.221V2Zm6.655,9.369a1.7,1.7,0,1,1,1.664-1.7A1.689,1.689,0,0,1,17.655,11.369Zm6.655,0a1.7,1.7,0,1,1,1.664-1.7A1.689,1.689,0,0,1,24.311,11.369Z"
              transform="translate(5.638 -2)"
            />
            <path
              id="Path_1002"
              data-name="Path 1002"
              d="M1,20.924a10.112,10.112,0,0,0,9.983,10.221,10.112,10.112,0,0,0,9.983-10.221V9H1Zm9.983,3.2c-2.3,0-4.16-1.431-4.16-3.2h8.319C15.143,22.7,13.279,24.127,10.983,24.127Zm3.328-9.165a1.7,1.7,0,1,1-1.664,1.7A1.689,1.689,0,0,1,14.311,14.962Zm-6.655,0a1.7,1.7,0,1,1-1.664,1.7A1.689,1.689,0,0,1,7.655,14.962Z"
              transform="translate(-1 2.924)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
