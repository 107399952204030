import { Switch } from "@headlessui/react";
import React from "react";

type props = {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export default function BasicSwitch({ isChecked, onChange, disabled }: props) {
  return (
    <Switch
      disabled={disabled}
      checked={isChecked}
      onChange={onChange}
      className={`${isChecked ? "bg-IAGreen" : "bg-IARed"}
            relative inline-flex h-[28px] w-12 items-center rounded-full 
            border-[1px] border-solid border-IAGrey`}
    >
      <span className="absolute font-Switzer left-1 text-[8px] opacity-60">
        ON
      </span>
      <span className="absolute font-Switzer right-1 text-[8px] opacity-60">
        OFF
      </span>

      <span
        aria-hidden="true"
        className={`${isChecked ? "translate-x-5" : "-translate-x-[1px]"}
              inline-block h-[28px] w-[28px] transform rounded-full bg-white border-[2px] border-solid 
              border-HTKBorder transition-all duration-300`}
      />
    </Switch>
  );
}
