import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { SUBMISSION_FIlTERS } from "../../assets/data/enums";
import API from "../../redux/api";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  selectedSubmissionSelector,
  setSelectedSubmission,
  setSubmissions,
  submissionsSelector,
} from "../../redux/slices/submissionsSlice";
import { useLazyFetchSubmissionsQuery } from "../../services/SubmissionServices/SubmissionServices";
import { FetchSubmissionsItem } from "../../services/SubmissionServices/SubmissionTypes";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  BasicLoader,
  Header,
  LogoLoader,
  SearchInput,
  SelectableRoundedButtons,
  SubmissionCard,
} from "../Components";

export default function SubmissionsList() {
  const take = 10;
  const filters = Object.values(SUBMISSION_FIlTERS);
  const [selectedFilter, setSelectedFilter] = useState<
    typeof SUBMISSION_FIlTERS[keyof typeof SUBMISSION_FIlTERS]
  >(SUBMISSION_FIlTERS.VIEW_ALL);
  const [searchText, setSearchText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const dispatch = useIADispatch();
  const submissions = useIASelector(submissionsSelector);
  const selectedSubmission = useIASelector(selectedSubmissionSelector);

  //APIS
  const [fetchSubmissionsQuery, { isLoading, isFetching }] =
    useLazyFetchSubmissionsQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;
    if (searchText) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchSubmissions();
      }, 300);
    } else {
      fetchSubmissions();
    }
    return () => clearTimeout(timeout);
  }, [searchText, selectedFilter]);

  const fetchSubmissions = async () => {
    try {
      const response = await fetchSubmissionsQuery(
        {
          search: searchText,
          skip: 0,
          take: take,
          tag: selectedFilter,
        },
        true
      ).unwrap();
      if (response) {
        dispatch(setSubmissions(response));
      }
      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsTyping(false);
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreSubmissions();
    }
  };

  const fetchMoreSubmissions = async () => {
    setIsLoadingMore(true);
    try {
      const [response] = await Promise.all([
        fetchSubmissionsQuery({
          search: searchText,
          skip: skip + take,
          take: take,
          tag: selectedFilter,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!submissions) return;
        const newSubmissions = submissions.concat(response);
        dispatch(setSubmissions(newSubmissions));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderSubmissionsList = () => {
    if ((isLoading || isFetching || isTyping) && !isLoadingMore)
      return (
        <div className="w-full">
          <LogoLoader className="mt-10" />
        </div>
      );

    if (!submissions) return <div></div>;

    if (!submissions.length)
      return (
        <div className="w-full flex flex-col items-center font-Switzer font-semibold text-lg">
          NO SUBMISSIONS
        </div>
      );

    return (
      <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={150}
        >
          {submissions.map(
            (submission: FetchSubmissionsItem, index: number) => (
              <SubmissionCard
                key={index}
                submission={submission}
                isSelected={
                  selectedSubmission
                    ? selectedSubmission.id === submission.id
                    : false
                }
                onClick={() => {
                  dispatch(setSelectedSubmission(submission));
                }}
              />
            )
          )}
        </InfiniteScroll>
      </div>
    );
  };

  const refreshSubmissions = async () => {
    setSkip(0);
    dispatch(API.util.invalidateTags(["Submissions"]));
    await fetchSubmissions();
  };

  return (
    <div className="w-full">
      <Header
        text="Submissions"
        type="empty"
        isRefreshButton={true}
        onRefresh={refreshSubmissions}
      />
      <SearchInput value={searchText} onChange={setSearchText} />
      <SelectableRoundedButtons
        items={filters}
        selectedItem={selectedFilter}
        setSelectedItem={setSelectedFilter}
      />
      {renderSubmissionsList()}
    </div>
  );
}
