import React from "react";
import { FetchSubmissionsItem } from "../../services/SubmissionServices/SubmissionTypes";
import { UserAvatar } from "../Components";

type Props = {
  submission: FetchSubmissionsItem;
};

export default function SubmissionUserCard({ submission }: Props) {
  const user = submission.user;

  const getSubscriptionPlan = () => {
    if (user.isDiscountCodeUsed) return "Foundation Member";
    if (user.productId === "iamonthly") return "Monthly Plan";
    if (user.productId === "iaannual") return "Annual Plan";
    if (user.productId === "iafoundtaion") return "Foundation Member";
  };

  return (
    <div
      className={`transition-all duration-200 w-full border-2 border-black flex flex-row items-center text-white bg-black p-2 group mb-4`}
    >
      <UserAvatar fileName={user.avatar} />
      <div className="ml-[10px]">
        <p className="font-Switzer font-bold text-2xl">
          {user.firstName + " " + user.lastName}
        </p>
        <div className="flex flex-row items-center">
          <p className="font-Switzer font-bold text-base mr-2">
            Subscribed User:
          </p>
          <p className="font-Switzer font-normal text-base">
            {getSubscriptionPlan()}
          </p>
        </div>
      </div>
    </div>
  );
}
