import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Representation } from "../../types/MainTypes/Representation";
import updateItemInArrayIfExists from "../../utils/updateItemInArrayIfExists";
import { RootState } from "../store";

export type intialAgentRepresentationsStateType = {
  agentRepresentations: Representation[] | null;
  selectedAgentRepresentation: Representation | null;
  selectedAgentRepresentations: Representation[];
};

export const intialAgentRepresentationsState: intialAgentRepresentationsStateType =
  {
    agentRepresentations: null,
    selectedAgentRepresentation: null,
    selectedAgentRepresentations: [],
  };

export const agentRepresentationsSlice = createSlice({
  name: "agentRepresentations",
  initialState: intialAgentRepresentationsState,
  reducers: {
    setAgentRepresentations: (
      state,
      action: PayloadAction<Representation[]>
    ) => {
      state.agentRepresentations = action.payload;
    },
    addSelectedAgentRepresentation: (
      state,
      action: PayloadAction<Representation>
    ) => {
      if (!state.agentRepresentations) return;
      state.selectedAgentRepresentations.unshift(action.payload);
      const filteredAgentRepresentations = state.agentRepresentations.filter(
        (a) => a.id !== action.payload.id
      );
      state.agentRepresentations = filteredAgentRepresentations;
    },
    removeSelectedAgentRepresentation: (
      state,
      action: PayloadAction<Representation>
    ) => {
      if (!state.agentRepresentations) return;
      const filteredSelectedAgentRepresentations =
        state.selectedAgentRepresentations.filter(
          (a) => a.id !== action.payload.id
        );
      state.selectedAgentRepresentations = filteredSelectedAgentRepresentations;
      state.agentRepresentations.unshift(action.payload);
    },
    setSelectedAgentRepresentations: (
      state,
      action: PayloadAction<Representation[]>
    ) => {
      state.selectedAgentRepresentations = action.payload;
    },
    addAgentRepresentation: (state, action: PayloadAction<Representation>) => {
      if (!state.agentRepresentations) return;
      state.agentRepresentations.unshift(action.payload);
    },
    deleteAgentRepresentation: (state, action: PayloadAction<string>) => {
      if (!state.agentRepresentations) return;
      const filteredAgentRepresentations = state.agentRepresentations.filter(
        (a) => a.id !== action.payload
      );
      state.agentRepresentations = filteredAgentRepresentations;
    },
    updateSelectedAgentRepresentation: (
      state,
      action: PayloadAction<Representation>
    ) => {
      if (!state.selectedAgentRepresentation) return;
      updateItemInArrayIfExists({
        array: state.selectedAgentRepresentations,
        item: action.payload,
      });
      updateItemInArrayIfExists({
        array: state.agentRepresentations,
        item: action.payload,
      });
    },
    setSelectedAgentRepresentation: (
      state,
      action: PayloadAction<Representation | null>
    ) => {
      if (!state.agentRepresentations) return;

      if (state.selectedAgentRepresentation?.id === action.payload?.id) {
        state.selectedAgentRepresentation = null;
        return;
      }
      state.selectedAgentRepresentation = action.payload;
    },
  },
});

export const agentRepresentationsSelector = (state: RootState) =>
  state.agentRepresentationsSlice.agentRepresentations;

export const selectedAgentRepresentationSelector = (state: RootState) =>
  state.agentRepresentationsSlice.selectedAgentRepresentation;

export const selectedAgentRepresentationsSelector = (state: RootState) =>
  state.agentRepresentationsSlice.selectedAgentRepresentations;

export const {
  setAgentRepresentations,
  addAgentRepresentation,
  deleteAgentRepresentation,
  updateSelectedAgentRepresentation,
  addSelectedAgentRepresentation,
  removeSelectedAgentRepresentation,
  setSelectedAgentRepresentation,
  setSelectedAgentRepresentations,
} = agentRepresentationsSlice.actions;
export default agentRepresentationsSlice.reducer;
