import moment from "moment";
import React from "react";
import { FetchAuditionsItem } from "../../services/AuditionServices/AuditionsTypes";

type Props = {
  audition: FetchAuditionsItem;
  isSelected: boolean;
  onClick: () => void;
};

export default function AuditionCard({ audition, isSelected, onClick }: Props) {
  const getLocationText = () => {
    if (audition.isOnline) return "Online";
    if (audition.auditionLocation && audition.auditionLocation.fullAddress) {
      return audition.auditionLocation.fullAddress;
    }
    return "N/A";
  };

  return (
    <div
      className={`transition-all duration-200 w-full border-2 border-black cursor-pointer ${
        isSelected ? "bg-black text-white" : "bg-white text-black"
      }  p-4 group mb-4`}
      onClick={onClick}
    >
      <div className="w-full flex tracking-tight font-Switzer text-[0.5em]">
        <p className="font-medium">Published</p>
        <p className="ml-1">
          {moment(audition.createdAt).isValid()
            ? moment(audition.createdAt).format("DD MMMM YYYY")
            : "N/A"}
        </p>
      </div>
      <p className="font-Switzer text-3xl tracking-tighter font-medium">
        {audition.title ? audition.title : "N/A"}
      </p>
      <div
        className={`w-full grid grid-cols-auditionDetails text-base tracking-tight pb-3`}
      >
        <p className="font-bold">When</p>
        <p>
          {moment(audition.openDate).isValid()
            ? moment(audition.openDate).format("MMMM Do YYYY")
            : "N/A"}
        </p>
      </div>

      <div
        className={`w-full grid grid-cols-auditionDetails text-base tracking-tight border-y-2 ${
          isSelected ? "border-white" : "border-black"
        } py-3`}
      >
        <p className="font-bold">Location</p>
        <p>{getLocationText()}</p>
      </div>
      <div className="w-full grid grid-cols-auditionDetails text-base tracking-tight pt-3">
        <p className="font-bold">For</p>
        <p>{audition.auditioningFor ? audition.auditioningFor : "N/A"}</p>
      </div>
    </div>
  );
}
