import React, { useState } from "react";
import { toast } from "react-toastify";
import { InitialCompanyStateType } from "../../assets/data/initialState";
import { TrashBinIcon } from "../../assets/icons/Icons";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  removeCompany,
  selectedCompanySelector,
  setSelectedCompany,
} from "../../redux/slices/companiesSlice";
import { closeOverlay, openOverlay } from "../../redux/slices/overlaySlice";
import { useDeleteCompanyMutation } from "../../services/CompanyServices/CompanyServices";
import { isErrorWithMessage } from "../../services/helper";
import { FormikType } from "../../types/types";
import asyncTimeout from "../../utils/asyncTimeout";
import { AlertModal, BasicLoader, CustomButton } from "../Components";

export type Props = FormikType<InitialCompanyStateType>;

export default function CompaniesDeleteSave({ formik }: Props) {
  const dispatch = useIADispatch();
  const selectedCompany = useIASelector(selectedCompanySelector);
  //APIS
  const [deleteCompany] = useDeleteCompanyMutation();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isAlertModalVisible, setIsAlertModalVisible] =
    useState<boolean>(false);

  const onDelete = async () => {
    dispatch(
      openOverlay({
        text: "Deleting",
      })
    );
    if (!selectedCompany) return;
    setIsDeleting(true);
    try {
      const [response] = await Promise.all([
        deleteCompany({
          id: selectedCompany.id,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        dispatch(removeCompany(selectedCompany.id));
        dispatch(setSelectedCompany(null));
        toast.success("Successfully deleted");
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsDeleting(false);
      dispatch(closeOverlay());
    }
  };

  return (
    <div className="mt-28 mr-8 flex flex-col justify-between h-fit">
      <AlertModal
        title="Delete Content"
        description="By deleting this content you will lose all data and details you previously created. This action is irreversible."
        buttonText="Delete Content"
        onClickButton={onDelete}
        isOpen={isAlertModalVisible}
        closeModal={() => setIsAlertModalVisible(false)}
      />
      <div>
        <div className="p-4 border-2 border-black mb-4">
          <p className="font-Switzer font-bold text-2xl text-black">
            Save & Publish
          </p>
          <p className="font-Switzer mb-2 text-black">
            Click the button to Save & Publish changes to the app.
          </p>
          <CustomButton
            text="Save & Publish"
            className="medium-green-button"
            onClick={formik.handleSubmit}
            isLoading={formik.isSubmitting}
            disabled={isDeleting || formik.isSubmitting}
          />
        </div>
        <div className="bg-IAGrey p-4 mb-4">
          <p className="font-Switzer mb-2 text-black">
            When Saving and Publishing, all content added or created in this
            section will be uploaded and made publicly visible to users on the
            Industry Auditions App.
          </p>
        </div>
      </div>
      {selectedCompany && selectedCompany.id !== "new" ? (
        <button
          className="hover:opacity-80 h-14 w-14 flex justify-center items-center rounded-lg border-black border-2"
          onClick={() => setIsAlertModalVisible(true)}
          disabled={isDeleting || formik.isSubmitting}
        >
          <TrashBinIcon />
        </button>
      ) : null}
    </div>
  );
}
