import moment from "moment";
import React from "react";
import { POST_TYPES } from "../../assets/data/enums";
import { FetchPostsItem } from "../../services/PostServices/PostTypes";
import { CustomButton } from "../Components";

type Props = {
  post: FetchPostsItem;
  onClick: () => void;
  isSelected: boolean;
};

export default function PostCard({ post, onClick, isSelected = false }: Props) {
  const getTypeText = () => {
    switch (post.type) {
      case POST_TYPES.MASTERCLASS:
        return "Masterclass";
      case POST_TYPES.NEWS:
        return "News";
      case POST_TYPES.GUIDES:
        return "Guides";
      case POST_TYPES.UNDER_THE_SPOTLIGHT:
        return "Under The Spotlight";
      case POST_TYPES.TIPS_AND_TRICKS:
        return "Tips & Tricks";
      default:
        break;
    }
  };

  const getBackgroundColor = () => {
    switch (post.type) {
      case POST_TYPES.GUIDES:
        return "#A6A2D3";
      case POST_TYPES.MASTERCLASS:
        return "#D0CD81";
      case POST_TYPES.NEWS:
        return "#D08D79";
      case POST_TYPES.UNDER_THE_SPOTLIGHT:
        return "black";
      case POST_TYPES.TIPS_AND_TRICKS:
        return "#84B293";
      default:
        break;
    }
  };

  const getTextColor = () => {
    switch (post.type) {
      case POST_TYPES.UNDER_THE_SPOTLIGHT:
        return "white";
      default:
        return "black";
    }
  };

  const getAuthorButtonStyle = () => {
    switch (post.type) {
      case POST_TYPES.UNDER_THE_SPOTLIGHT:
        if (isSelected) return "extra-small-white-button-no-hover";
        return "extra-small-black-button-no-hover";
      default:
        return "extra-small-black-button-no-hover";
    }
  };

  const getTagButtonStyle = () => {
    switch (post.type) {
      case POST_TYPES.UNDER_THE_SPOTLIGHT:
        if (isSelected) return "extra-small-white-border-button-no-hover";
        return "extra-small-transparent-button-no-hover";
      default:
        return "extra-small-transparent-button-no-hover";
    }
  };

  return (
    <div
      style={{
        backgroundColor: isSelected ? getBackgroundColor() : "white",
      }}
      className={`transition-all duration-200 w-full border-2 border-black mb-4 p-2 cursor-pointer group hover:bg-IAYellow`}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <p
          style={{
            color: isSelected ? getTextColor() : "black",
          }}
          className="font-Switzer font-bold text-base tracking-tight"
        >
          {getTypeText()}
        </p>
        <p
          style={{
            color: isSelected ? getTextColor() : "black",
          }}
          className="font-Switzer font-normal text-xs tracking-tight"
        >
          {moment(post.createdAt).isValid()
            ? moment(post.createdAt).format("DD MMM YYYY")
            : 'N/A"'}
        </p>
      </div>
      <p
        style={{
          color: isSelected ? getTextColor() : "black",
        }}
        className="mt-1 mb-2 font-Switzer font-normal text-2xl tracking-tighter"
      >
        {post.title ? post.title : "N/A"}
      </p>
      <div className="flex overflow-x-auto scrollbar-hide">
        <CustomButton
          style={{ color: isSelected ? getBackgroundColor() : "white" }}
          text={post.author ? post.author : "N/A"}
          className={`${getAuthorButtonStyle()} group-hover:text-IAYellow`}
        />
        {post.postToPostTags && post.postToPostTags.length > 0
          ? post.postToPostTags.map((t, i) => (
              <CustomButton
                text={t.postTagTitle}
                className={getTagButtonStyle()}
              />
            ))
          : null}
      </div>
    </div>
  );
}
