import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../assets/styles/quill.css";

type Props = {
  text: string;
  onChange: (text: string) => void;
};

export default function HtmlTextInput({ text, onChange }: Props) {
  const modules = {
    toolbar: [["italic", "bold"]],
  };

  return (
    <ReactQuill
      value={text}
      onChange={(text) => onChange(text)}
      modules={modules}
    />
  );
}
