import React, { useEffect } from "react";
import { InitialPostStateType } from "../../assets/data/initialState";
import { useIASelector } from "../../redux/hooks";
import { selectedPostSelector } from "../../redux/slices/postsSlice";
import { FormikType } from "../../types/types";
import setPostFormikValues from "../../utils/setPostFormikValues";
import {
  Header,
  PostContent,
  PostExternalLinks,
  PostGeneralDetails,
  PostImage,
  PostTags,
  PostType,
  PostVideo,
} from "../Components";

export type Props = FormikType<InitialPostStateType>;

export default function HubDetail({ formik }: Props) {
  const selectedPost = useIASelector(selectedPostSelector);

  useEffect(() => {
    if (!selectedPost) return;

    setPostFormikValues({
      formik: formik,
      selectedPost: selectedPost,
    });
  }, [selectedPost]);

  return (
    <div className="w-full">
      <Header text="Post Details" />
      {selectedPost ? (
        <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
          <PostType formik={formik} />
          <PostGeneralDetails formik={formik} />
          <PostTags formik={formik} />
          <PostImage formik={formik} />
          <PostVideo formik={formik} />
          <PostExternalLinks formik={formik} />
          <PostContent formik={formik} />
        </div>
      ) : (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <p className="font-Switzer font-semibold text-2xl mb-2">
            No Post Selected
          </p>
          <p className="font-Switzer font-normal text-l mb-48">
            Please Select a Post
          </p>
        </div>
      )}
    </div>
  );
}
