import React from "react";
import { FetchCompaniesItem } from "../../services/CompanyServices/CompanyTypes";

type Props = {
  company: FetchCompaniesItem;
  isSelected: boolean;
  onClick: () => void;
};

export default function CompanyCard({ company, isSelected, onClick }: Props) {
  return (
    <div
      onClick={onClick}
      className={`${
        isSelected ? "bg-black text-white" : "bg-white text-black"
      } cursor-pointer p-4 border-2 border-black mb-4 group`}
    >
      <div
        className={`flex flex-row space-x-2 font-Switzer font-semibold text-sm`}
      >
        {company.companyToCompanyTypes.map((t, i) => (
          <p>{t.companyTypeTitle}</p>
        ))}
      </div>

      <p className={`font-Switzer font-semibold text-3xl pb-3`}>
        {company.name ? company.name : "N/A"}
      </p>
      <div
        className={`w-full grid grid-cols-auditionDetails text-base tracking-tight border-t-2 ${
          isSelected ? "border-white" : "border-black"
        }  pt-3`}
      >
        <p className="font-bold">Location</p>
        <p>
          {company.companyLocation
            ? company.companyLocation.fullAddress
            : "N/A"}
        </p>
      </div>
    </div>
  );
}
