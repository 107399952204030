import moment from "moment";
import React from "react";
import { useIASelector } from "../../redux/hooks";
import { selectedUserSelector } from "../../redux/slices/userSlice";
import { subscriber } from "../../services/RevenueCatServices/RevenueCatTypes";

type Props = {
  subscriber: subscriber;
};

export default function SubscriptionCard({ subscriber }: Props) {
  const selectedUser = useIASelector(selectedUserSelector);

  const getSubscriptionTitle = () => {
    const userSubscription = selectedUser?.userSubscription;
    if (!userSubscription) return "";
    const subscriptionId = userSubscription.subscriptionId;
    if (subscriptionId === "iafree") return "Free Account";
    if (subscriptionId === "iaannual") return "Annual Subscription";
    if (subscriptionId === "iamonthly") return "Monthly Subscription";
    if (subscriptionId === "iafoundation")
      return "Foundation Member Subscription";
    return "";
  };

  const getBackgroundColor = () => {
    const userSubscription = selectedUser?.userSubscription;
    if (!userSubscription) return "";
    const subscriptionId = userSubscription.subscriptionId;
    if (subscriptionId === "iaannual") return "#D08D79";
    if (subscriptionId === "iamonthly") return "#D0CD81";
    if (subscriptionId === "iafoundation") return "#A6A2D3";
  };

  const getPrice = () => {
    const userSubscription = selectedUser?.userSubscription;
    if (!userSubscription) return "";
    const subscriptionId = userSubscription.subscriptionId;
    if (subscriptionId === "iaannual") return "$83.00";
    if (subscriptionId === "iamonthly") return "$11.99";
    if (subscriptionId === "iafoundation") return "$59.00";
  };

  const getPriceUnit = () => {
    const userSubscription = selectedUser?.userSubscription;
    if (!userSubscription) return "";
    const subscriptionId = userSubscription.subscriptionId;
    if (subscriptionId === "iamonthly") return "Per Month";

    return "Per Year";
  };

  if (!selectedUser) return <div></div>;

  return (
    <div className="text-black font-Switzer">
      <div
        style={{ backgroundColor: getBackgroundColor() }}
        className={`rounded-lg p-4 mb-2`}
      >
        <p>{getSubscriptionTitle()}</p>
        <div className="flex items-end mb-2">
          <p className="font-semibold text-2xl">{getPrice()}</p>
          <p className="ml-2">{getPriceUnit()}</p>
        </div>
        <p className="text-[8px]">
          Billed Annually After Free Trial Period Ends
        </p>
      </div>
      <div className="flex flex-row items-center">
        <p className="font-semibold mr-2">Renews on:</p>
        <p>
          {moment(subscriber.entitlements.production.expires_date).format(
            "DD MMMM YYYY"
          )}
        </p>
      </div>
    </div>
  );
}
