import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuditionType } from "../../types/MainTypes/AuditionType";
import updateItemInArrayIfExists from "../../utils/updateItemInArrayIfExists";
import { RootState } from "../store";

export type initialAuditionTypesStateType = {
  auditionTypes: AuditionType[] | null;
  selectedAuditionType: AuditionType | null;
  selectedAuditionTypes: AuditionType[];
};

export const initialAuditionTypesState: initialAuditionTypesStateType = {
  auditionTypes: null,
  selectedAuditionType: null,
  selectedAuditionTypes: [],
};

export const auditionTypesSlice = createSlice({
  name: "auditionTypes",
  initialState: initialAuditionTypesState,
  reducers: {
    setAuditionTypes: (state, action: PayloadAction<AuditionType[]>) => {
      state.auditionTypes = action.payload;
    },
    addSelectedAuditionType: (state, action: PayloadAction<AuditionType>) => {
      if (!state.auditionTypes) return;
      state.selectedAuditionTypes.unshift(action.payload);
      const filteredAuditionTypes = state.auditionTypes.filter(
        (a) => a.id !== action.payload.id
      );
      state.auditionTypes = filteredAuditionTypes;
    },
    removeSelectedAuditionType: (
      state,
      action: PayloadAction<AuditionType>
    ) => {
      if (!state.auditionTypes) return;
      const filteredSelectedAuditionTypes = state.selectedAuditionTypes.filter(
        (a) => a.id !== action.payload.id
      );
      state.selectedAuditionTypes = filteredSelectedAuditionTypes;
      state.auditionTypes.unshift(action.payload);
    },
    setSelectedAuditionTypes: (
      state,
      action: PayloadAction<AuditionType[]>
    ) => {
      state.selectedAuditionTypes = action.payload;
    },
    addAuditionType: (state, action: PayloadAction<AuditionType>) => {
      if (!state.auditionTypes) return;
      state.auditionTypes.unshift(action.payload);
    },
    deleteAuditionType: (state, action: PayloadAction<string>) => {
      if (!state.auditionTypes) return;
      const filteredAuditionTypes = state.auditionTypes.filter(
        (a) => a.id !== action.payload
      );
      state.auditionTypes = filteredAuditionTypes;
      const filteredSelectedAuditionTypes = state.selectedAuditionTypes.filter(
        (a) => a.id !== action.payload
      );
      state.selectedAuditionTypes = filteredSelectedAuditionTypes;
    },
    updateSelectedAuditionType: (
      state,
      action: PayloadAction<AuditionType>
    ) => {
      if (!state.selectedAuditionType) return;
      updateItemInArrayIfExists({
        array: state.selectedAuditionTypes,
        item: action.payload,
      });
      updateItemInArrayIfExists({
        array: state.auditionTypes,
        item: action.payload,
      });
    },
    setSelectedAuditionType: (
      state,
      action: PayloadAction<AuditionType | null>
    ) => {
      if (!state.auditionTypes) return;

      if (state.selectedAuditionType?.id === action.payload?.id) {
        state.selectedAuditionType = null;
        return;
      }
      state.selectedAuditionType = action.payload;
    },
  },
});

export const auditionTypesSelector = (state: RootState) =>
  state.auditionTypesSlice.auditionTypes;

export const selectedAuditionTypeSelector = (state: RootState) =>
  state.auditionTypesSlice.selectedAuditionType;

export const selectedAuditionTypesSelector = (state: RootState) =>
  state.auditionTypesSlice.selectedAuditionTypes;

export const {
  setAuditionTypes,
  addAuditionType,
  deleteAuditionType,
  updateSelectedAuditionType,
  addSelectedAuditionType,
  removeSelectedAuditionType,
  setSelectedAuditionType,
  setSelectedAuditionTypes,
} = auditionTypesSlice.actions;
export default auditionTypesSlice.reducer;
