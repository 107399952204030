import React from "react";

export default function SearchIcon({
  className,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="search_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={className}
    >
      <path
        id="Path_1009"
        data-name="Path 1009"
        d="M0,0H40V40H0Z"
        fill="none"
      />
      <path
        id="Path_1010"
        data-name="Path 1010"
        d="M26.935,24.063H25.422l-.536-.517a12.465,12.465,0,1,0-1.34,1.34l.517.536v1.513l9.574,9.555,2.853-2.853Zm-11.489,0a8.617,8.617,0,1,1,8.617-8.617A8.605,8.605,0,0,1,15.446,24.063Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
