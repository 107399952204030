import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocationTagFilter } from "../../types/MainTypes/LocationTagFilter";
import updateItemInArrayIfExists from "../../utils/updateItemInArrayIfExists";
import { RootState } from "../store";

export type initialLocationTagFiltersStateType = {
  locationTagFilters: LocationTagFilter[] | null;
  selectedLocationTagFilter: LocationTagFilter | null;
  selectedLocationTagFilters: LocationTagFilter[];
};

export const initialLocationTagFiltersState: initialLocationTagFiltersStateType =
  {
    locationTagFilters: null,
    selectedLocationTagFilter: null,
    selectedLocationTagFilters: [],
  };

export const locationTagFiltersSlice = createSlice({
  name: "locationTagFilters",
  initialState: initialLocationTagFiltersState,
  reducers: {
    setLocationTagFilters: (
      state,
      action: PayloadAction<LocationTagFilter[]>
    ) => {
      state.locationTagFilters = action.payload;
    },
    addSelectedLocationTagFilter: (
      state,
      action: PayloadAction<LocationTagFilter>
    ) => {
      if (!state.locationTagFilters) return;
      state.selectedLocationTagFilters.unshift(action.payload);
      const filteredLocationTagFilters = state.locationTagFilters.filter(
        (lt) => lt.id !== action.payload.id
      );
      state.locationTagFilters = filteredLocationTagFilters;
    },
    removeSelectedLocationTagFilter: (
      state,
      action: PayloadAction<LocationTagFilter>
    ) => {
      if (!state.locationTagFilters) return;
      const filteredSelectedLocationTagFilters =
        state.selectedLocationTagFilters.filter(
          (lt) => lt.id !== action.payload.id
        );
      state.selectedLocationTagFilters = filteredSelectedLocationTagFilters;
      state.locationTagFilters.unshift(action.payload);
    },
    setSelectedLocationTagFilters: (
      state,
      action: PayloadAction<LocationTagFilter[]>
    ) => {
      state.selectedLocationTagFilters = action.payload;
    },
    addLocationTagFilter: (state, action: PayloadAction<LocationTagFilter>) => {
      if (!state.locationTagFilters) return;
      state.locationTagFilters.unshift(action.payload);
    },
    deleteLocationTagFilter: (state, action: PayloadAction<string>) => {
      if (!state.locationTagFilters) return;
      const filteredLocationTagFilters = state.locationTagFilters.filter(
        (lt) => lt.id !== action.payload
      );
      state.locationTagFilters = filteredLocationTagFilters;
      const filteredSelectedLocationTagFilters =
        state.selectedLocationTagFilters.filter(
          (lt) => lt.id !== action.payload
        );
      state.selectedLocationTagFilters = filteredSelectedLocationTagFilters;
    },
    updateSelectedLocationTagFilter: (
      state,
      action: PayloadAction<LocationTagFilter>
    ) => {
      if (!state.selectedLocationTagFilter) return;
      updateItemInArrayIfExists({
        array: state.selectedLocationTagFilters,
        item: action.payload,
      });
      updateItemInArrayIfExists({
        array: state.locationTagFilters,
        item: action.payload,
      });
    },
    setSelectedLocationTagFilter: (
      state,
      action: PayloadAction<LocationTagFilter | null>
    ) => {
      if (!state.locationTagFilters) return;

      if (state.selectedLocationTagFilter?.id === action.payload?.id) {
        state.selectedLocationTagFilter = null;
        return;
      }
      state.selectedLocationTagFilter = action.payload;
    },
  },
});

export const locationTagFiltersSelector = (state: RootState) =>
  state.locationTagFiltersSlice.locationTagFilters;

export const selectedLocationTagFilterSelector = (state: RootState) =>
  state.locationTagFiltersSlice.selectedLocationTagFilter;

export const selectedLocationTagFiltersSelector = (state: RootState) =>
  state.locationTagFiltersSlice.selectedLocationTagFilters;

export const {
  addLocationTagFilter,
  addSelectedLocationTagFilter,
  deleteLocationTagFilter,
  removeSelectedLocationTagFilter,
  setLocationTagFilters,
  setSelectedLocationTagFilter,
  setSelectedLocationTagFilters,
  updateSelectedLocationTagFilter,
} = locationTagFiltersSlice.actions;
export default locationTagFiltersSlice.reducer;
