import React, { useEffect } from "react";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  selectedUserSelector,
  setSelectedUser,
} from "../../redux/slices/userSlice";
import AllUsersList from "./AllUsersList";
import UserDetails from "./UserDetails";
import UserDelete from "./UserDelete";

export default function AllUsersMain() {
  const dispatch = useIADispatch();

  const selectedUser = useIASelector(selectedUserSelector);

  useEffect(() => {
    dispatch(setSelectedUser(null));
  }, []);

  return (
    <>
      <AllUsersList />
      <UserDetails />
      {selectedUser && <UserDelete />}
    </>
  );
}
