import React, { useEffect } from "react";
import { InitialCompanyStateType } from "../../assets/data/initialState";
import { useIASelector } from "../../redux/hooks";
import { selectedCompanySelector } from "../../redux/slices/companiesSlice";
import { FormikType } from "../../types/types";
import setCompanyFormikValues from "../../utils/setCompanyFormikValues";
import {
  CompanyActiveStatus,
  CompanyAppearance,
  CompanyDescription,
  CompanyExternalLinks,
  CompanyGeneralDetails,
  CompanyImage,
  CompanyType,
  Header,
} from "../Components";

export type Props = FormikType<InitialCompanyStateType>;

export default function CompanyDetails({ formik }: Props) {
  const selectedCompany = useIASelector(selectedCompanySelector);

  useEffect(() => {
    if (!selectedCompany) return;

    setCompanyFormikValues({
      formik: formik,
      selectedCompany: selectedCompany,
    });
  }, [selectedCompany]);

  return (
    <div className="w-full">
      <Header text="Company Details" />
      {selectedCompany ? (
        <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
          <CompanyGeneralDetails formik={formik} />
          <CompanyType formik={formik} />
          <CompanyImage formik={formik} />
          <CompanyAppearance formik={formik} />
          <CompanyDescription formik={formik} />
          <CompanyExternalLinks formik={formik} />
          <CompanyActiveStatus formik={formik} />
        </div>
      ) : (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <p className="font-Switzer font-semibold text-2xl mb-2">
            No Company Selected
          </p>
          <p className="font-Switzer font-normal text-l mb-48">
            Please Select a Company
          </p>
        </div>
      )}
    </div>
  );
}
