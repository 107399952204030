import { Dialog } from "@headlessui/react";
import React from "react";
import CustomButton from "../../buttons/CustomButton";
import CustomModal from "../../CustomModal";
import ModalHeader from "../../headers/ModalHeader";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  onClickButton: () => void;
  title: string;
  description: string;
  buttonText: string;
};

export default function AlertModal({
  isOpen,
  closeModal,
  onClickButton,
  title,
  description,
  buttonText,
}: Props) {
  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/4">
        <ModalHeader title={title} closeModal={closeModal} />
        <div className="font-Switzer text-black mx-4 my-5">{description}</div>
        <div className="flex flex-row items-center mb-6 mx-4">
          <CustomButton
            text="Go back!"
            className="medium-black-button mr-2"
            onClick={closeModal}
          />
          <CustomButton
            text={buttonText}
            className="medium-white-button"
            onClick={() => {
              closeModal();
              onClickButton();
            }}
          />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
