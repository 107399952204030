import React, { useEffect } from "react";
import { useIADispatch } from "../../redux/hooks";
import { setSelectedSubmission } from "../../redux/slices/submissionsSlice";
import SubmissionDetails from "./SubmissionDetails";
import SubmissionsList from "./SubmissionsList";

export default function SubmissionsMain() {
  const dispatch = useIADispatch();

  useEffect(() => {
    dispatch(setSelectedSubmission(null));
  }, []);

  return (
    <>
      <SubmissionsList />
      <SubmissionDetails />
    </>
  );
}
