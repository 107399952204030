import React, { useEffect, useState } from "react";
import { FormikType } from "../../../../types/types";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  addSelectedPerformerTypeFilter,
  performerTypeFiltersSelector,
  selectedPerformerTypeFilterSelector,
  setPerformerTypeFilters,
  setSelectedPerformerTypeFilter,
} from "../../../../redux/slices/performerTypeFiltersSlice";
import { useLazyFetchPerformerTypeFiltersQuery } from "../../../../services/PerformerTypeFiltersServices/PerformerTypeFiltersServices";
import { Primitives } from "../../../../assets/primitives";
import { toast } from "react-toastify";
import BasicLoader from "../../loader/BasicLoader";
import InfiniteScroll from "react-infinite-scroller";
import DataRowCard from "../../cards/DataRowCard";

type Props = FormikType<InitialAuditionStateType>;

export default function PerformerTypeFilterModalPerformerTypeFilters({
  formik,
}: Props) {
  const dispatch = useIADispatch();
  const performerTypeFilters = useIASelector(performerTypeFiltersSelector);
  const selectedPerformerTypeFilter = useIASelector(
    selectedPerformerTypeFilterSelector
  );

  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const [fetchPerformerTypeFiltersQuery, { isLoading, isFetching }] =
    useLazyFetchPerformerTypeFiltersQuery();

  useEffect(() => {
    setSkip(0);
    dispatch(setSelectedPerformerTypeFilter(null));
    fetchPerformerTypeFilters();
  }, []);

  const fetchPerformerTypeFilters = async () => {
    try {
      const response = await fetchPerformerTypeFiltersQuery(
        {
          search: "",
          skip: Primitives.IA_INITIAL_SKIP,
          take: Primitives.IA_TAKE,
        },
        true
      ).unwrap();

      if (response) {
        const filteredPerformerTypeFilters = response.filter(
          (p1) =>
            !formik.values.performerTypeFilters.some((p2) => p1.id === p2.id)
        );
        dispatch(setPerformerTypeFilters(filteredPerformerTypeFilters));
      }

      if (response && response.length < Primitives.IA_TAKE) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMorePerformerTypeFilters();
    }
  };

  const fetchMorePerformerTypeFilters = async () => {
    setIsLoadingMore(true);
    try {
      const response = await fetchPerformerTypeFiltersQuery(
        {
          search: "",
          skip: skip + Primitives.IA_TAKE,
          take: Primitives.IA_TAKE,
        },
        true
      ).unwrap();

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!performerTypeFilters) return;
        const newPerformerTypeFilters = performerTypeFilters.concat(response);
        dispatch(setPerformerTypeFilters(newPerformerTypeFilters));
      }

      setSkip(skip + Primitives.IA_TAKE);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderPerformerTypes = () => {
    if ((isLoading || isFetching) && !isLoadingMore)
      return (
        <div className="w-full">
          <BasicLoader color="black" />
        </div>
      );
    if (!performerTypeFilters)
      return (
        <div className="w-full">
          <p className="font-Switzer text-base text-black">No Location Tags</p>
        </div>
      );

    return (
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={50}
        >
          {performerTypeFilters.map((pt, i) => (
            <DataRowCard
              key={i}
              text={pt.title}
              onClickContainer={() =>
                dispatch(setSelectedPerformerTypeFilter(pt))
              }
              onClickIcon={() => dispatch(addSelectedPerformerTypeFilter(pt))}
              isSelectedToUpdate={
                selectedPerformerTypeFilter
                  ? selectedPerformerTypeFilter.id === pt.id
                  : false
              }
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Performer Types
      </p>
      {renderPerformerTypes()}
    </div>
  );
}
