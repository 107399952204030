import React, { useEffect } from "react";
import { InitialPostStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  removeSelectedPostTag,
  selectedPostTagsSelector,
  setSelectedPostTag,
  setSelectedPostTags,
} from "../../../../redux/slices/postTagsSlice";
import { FormikType } from "../../../../types/types";
import DataRowCard from "../../cards/DataRowCard";

type Props = FormikType<InitialPostStateType>;

export default function PostTagsModalSelectedTags({ formik }: Props) {
  const selectedPostTags = useIASelector(selectedPostTagsSelector);
  const dispatch = useIADispatch();

  useEffect(() => {
    if (!formik.values.postTags) return;
    dispatch(setSelectedPostTags(formik.values.postTags));
  }, [formik.values.postTags]);

  return (
    <div className="mx-4">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Selected Types
      </p>
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        {selectedPostTags.map((p, i) => (
          <DataRowCard
            key={i}
            text={p.title}
            isSelected={true}
            onClickContainer={() => dispatch(setSelectedPostTag(p))}
            onClickIcon={() => dispatch(removeSelectedPostTag(p))}
          />
        ))}
      </div>
    </div>
  );
}
