import React, { useState } from "react";
import {
  CustomButton,
  DashboardLayout,
  SubmissionsMain,
  AllUsersMain,
} from "../../components/Components";

export default function Users() {
  const pages = ["All Users", "Submissions"];
  const [selectedPage, setSelectedPage] = useState<string>("All Users");

  const renderPage = () => {
    switch (selectedPage) {
      case "All Users":
        return <AllUsersMain />;
      case "Submissions":
        return <SubmissionsMain />;
    }
  };

  return (
    <DashboardLayout page="users">
      <>
        <div className="flex flex-row h-12 w-full justify-center items-center border-b-2 border-black space-x-4">
          {pages.map((p, k) => (
            <CustomButton
              className={`
                ${
                  selectedPage === p
                    ? "small-black-button"
                    : "small-white-button"
                }
              `}
              text={p}
              onClick={() => setSelectedPage(p)}
            />
          ))}
        </div>
        <div className="w-screen h-full overflow-hidden grid grid-cols-dashboard gap-4 justify-end">
          {renderPage()}
        </div>
      </>
    </DashboardLayout>
  );
}
