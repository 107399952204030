import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { emptyAgent } from "../../assets/data/emptyState";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addAgent,
  agentsSelector,
  filterEmptyAgent,
  isEmptyAgentExistSelector,
  selectedAgentSelector,
  setAgents,
  setSelectedAgent,
} from "../../redux/slices/agentsSlice";
import { useLazyFetchAgentsQuery } from "../../services/AgentServices/AgentServices";
import { FetchAgentsItem } from "../../services/AgentServices/AgentTypes";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  AgentCard,
  BasicLoader,
  Header,
  LogoLoader,
  SearchInput,
  SelectableRoundedButtons,
} from "../Components";

export default function AgentsList() {
  const take = 10;
  const filters = ["All Agents", "Active", "In-Active"];
  const [selectedFilter, setSelectedFilter] = useState<string>("All Agents");
  const [searchText, setSearchText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const dispatch = useIADispatch();
  const agents = useIASelector(agentsSelector);
  const selectedAgent = useIASelector(selectedAgentSelector);
  const isEmptyAgentExist = useIASelector(isEmptyAgentExistSelector);

  //APIS
  const [fetchAgentsQuery, { isLoading, isFetching }] =
    useLazyFetchAgentsQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;
    if (searchText) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchAgents();
      }, 300);
    } else {
      fetchAgents();
    }
    return () => clearTimeout(timeout);
  }, [searchText, selectedFilter]);

  const getActive = () => {
    switch (selectedFilter) {
      case "All Agents":
        return "";
      case "Active":
        return "true";
      case "In-Active":
        return "false";
      default:
        return "";
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await fetchAgentsQuery(
        {
          search: searchText,
          skip: 0,
          take: take,
          active: getActive(),
        },
        true
      ).unwrap();

      if (response) {
        dispatch(setAgents(response));
      }
      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsTyping(false);
    }
  };
  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreAgents();
    }
  };

  const fetchMoreAgents = async () => {
    setIsLoadingMore(true);
    try {
      const [response] = await Promise.all([
        fetchAgentsQuery({
          search: searchText,
          skip: skip + take,
          take: take,
          active: getActive(),
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!agents) return;
        const newAgents = agents.concat(response);
        dispatch(setAgents(newAgents));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const createEmptyAgent = () => {
    if (isEmptyAgentExist) return;
    dispatch(addAgent(emptyAgent));
    dispatch(setSelectedAgent(emptyAgent));
  };

  const renderAgentsList = () => {
    if ((isLoading || isFetching || isTyping) && !isLoadingMore)
      return (
        <div className="w-full">
          <LogoLoader className="mt-10" />
        </div>
      );

    if (!agents) return <div></div>;

    if (!agents.length)
      return (
        <div className="w-full flex flex-col items-center font-Switzer font-semibold text-lg">
          NO AGENTS
        </div>
      );

    return (
      <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={150}
        >
          {agents.map((agent: FetchAgentsItem, index: number) => (
            <AgentCard
              key={index}
              agent={agent}
              isSelected={selectedAgent ? selectedAgent.id === agent.id : false}
              onClick={() => {
                dispatch(filterEmptyAgent());
                dispatch(setSelectedAgent(agent));
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="w-full">
      <Header text="Agents" type="add" onClick={createEmptyAgent} />
      <SearchInput value={searchText} onChange={setSearchText} />
      <SelectableRoundedButtons
        items={filters}
        selectedItem={selectedFilter}
        setSelectedItem={setSelectedFilter}
      />
      {renderAgentsList()}
    </div>
  );
}
