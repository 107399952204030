import React from "react";
import { FormikType } from "../../../../types/types";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import CustomModal from "../../CustomModal";
import { Dialog } from "@headlessui/react";
import ModalHeader from "../../headers/ModalHeader";
import CustomButton from "../../buttons/CustomButton";
import { selectedLocationTagFiltersSelector } from "../../../../redux/slices/locationTagFiltersSlice";
import { useIASelector } from "../../../../redux/hooks";
import LocationTagFilterModalLocationTagFilters from "./LocationTagFilterModalLocationTagFilters";
import LocationTagFilterModalSelectedTagsFilters from "./LocationTagFilterModalSelectedTagFilters";
import LocationTagFilterModalEdit from "./LocationTagFilterModalEdit";

type Props = FormikType<InitialAuditionStateType> & {
  isOpen: boolean;
  closeModal: () => void;
};

export default function LocationTagFilterModal({
  closeModal,
  formik,
  isOpen,
}: Props) {
  const selectedLocationTagFilters = useIASelector(
    selectedLocationTagFiltersSelector
  );

  const handleSetLocationTagFilters = () => {
    formik.setFieldValue("locationTagFilters", selectedLocationTagFilters);
    closeModal();
  };

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/2">
        <ModalHeader
          title="Location Tag"
          description="Edit, add and manage this Audition’s Location Tag."
          closeModal={closeModal}
        />
        <div className="grid grid-cols-modal px-4 h-full">
          <div className="h-4/5 w-full justify-between flex flex-col">
            <div className="w-full grid grid-cols-auditionType">
              <LocationTagFilterModalLocationTagFilters formik={formik} />
              <LocationTagFilterModalSelectedTagsFilters formik={formik} />
            </div>
            <div className="flex-row flex items-center justify-start my-4">
              <CustomButton
                className="medium-black-button mr-2"
                text="Set Location Tags"
                onClick={() => handleSetLocationTagFilters()}
              />
              <CustomButton
                className="medium-white-button"
                text="Cancel"
                onClick={closeModal}
              />
            </div>
          </div>
          <LocationTagFilterModalEdit formik={formik} />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
