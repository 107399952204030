import React from "react";
import { TextInput } from "../Components";
import { FormikType, RFC } from "../../types/types";
import { InitialAuditionStateType } from "../../assets/data/initialState";

const AuditionExternalLinks: RFC<FormikType<InitialAuditionStateType>> = ({
  formik,
}) => {
  return (
    <div className="pb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1 color-black">
        External Links
      </p>
      <p className="font-Switzer text-base tracking-tight mb-3 color-black">
        Give users a way to access audition submissions or to learn more about
        this audition. This link will be displayed on this auditions overview
        screen. Ensure to accurately title the button as to what the link
        refrences
      </p>
      <TextInput
        label="Button Title(Max 40 Characters)"
        name="Button Title(Max 40 Characters)"
        type="text"
        value={formik.values.buttonTitle}
        required={true}
        onChange={(text) => formik.setFieldValue("buttonTitle", text)}
        isTouched={formik.touched.buttonTitle}
        error={formik.errors.buttonTitle}
      />
      <TextInput
        label="Button Link"
        name="Button Link"
        type="text"
        value={formik.values.buttonLink}
        required={true}
        onChange={(text) => formik.setFieldValue("buttonLink", text)}
        isTouched={formik.touched.buttonLink}
        error={formik.errors.buttonLink}
      />
    </div>
  );
};

export default AuditionExternalLinks;
