import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ErrorIcon } from "../../../assets/icons/Icons";
import "../../../assets/styles/mobileInput.css";
import mobileParser from "../../../utils/mobileParser";

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string | undefined | null;
  isTouched?: boolean | undefined;
};

export default function MobileInput({
  value,
  onChange,
  error,
  isTouched,
}: Props) {
  return (
    <div className="font-Switzer text-base mt-2 text-black tracking-tighter">
      <p className="font-medium">Mobile</p>
      <PhoneInput
        country={"au"}
        placeholder={"Mobile Number"}
        value={value}
        enableSearch={true}
        autoFormat={false}
        onChange={(value, country) => {
          if (!onChange) return;
          onChange("+" + value);
        }}
        containerClass={"input_phone_container"}
        inputClass={"input_phone_text"}
        buttonClass={"input_phone_btn"}
        dropdownClass={""}
        searchClass={""}
        buttonStyle={{ background: "#FFF" }}
      />
      {isTouched && error && (
        <div
          className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          {error}
        </div>
      )}
    </div>
  );
}
