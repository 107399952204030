import React, { useState } from "react";
import { FormikType } from "../../types/types";
import { InitialAuditionStateType } from "../../assets/data/initialState";
import { CustomButton, PerformerTypeFilterModal } from "../Components";

export type Props = FormikType<InitialAuditionStateType>;

export default function PerformerTypeFilter({ formik }: Props) {
  const [
    isPerformerTypeFilterModalVisible,
    setIsPerformerTypeFilterModalVisible,
  ] = useState<boolean>(false);
  return (
    <div className="mb-8">
      <PerformerTypeFilterModal
        isOpen={isPerformerTypeFilterModalVisible}
        closeModal={() => setIsPerformerTypeFilterModalVisible(false)}
        formik={formik}
      />
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Performer Type
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Set what type of company this audition is for.
      </p>
      <div className="flex flex-row space-x-2 overflow-x-auto scrollbar-hide">
        <CustomButton
          text="Edit"
          className="medium-green-button"
          onClick={() => setIsPerformerTypeFilterModalVisible(true)}
        />
        {formik.values.performerTypeFilters &&
          formik.values.performerTypeFilters.map((pt, i) => (
            <CustomButton
              key={i}
              text={pt.title}
              className="medium-white-button-no-hover"
            />
          ))}
      </div>
    </div>
  );
}
