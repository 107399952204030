import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchAgentsItem } from "../../services/AgentServices/AgentTypes";
import { RootState } from "../store";

export type initialAgentsStateType = {
  agents: FetchAgentsItem[] | null;
  selectedAgent: FetchAgentsItem | null;
};

export const initialAgentState: initialAgentsStateType = {
  agents: null,
  selectedAgent: null,
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState: initialAgentState,
  reducers: {
    setAgents: (state, action: PayloadAction<FetchAgentsItem[] | null>) => {
      state.agents = action.payload;
    },
    setSelectedAgent: (
      state,
      action: PayloadAction<FetchAgentsItem | null>
    ) => {
      state.selectedAgent = action.payload;
    },
    addAgent: (state, action: PayloadAction<FetchAgentsItem>) => {
      if (!state.agents) return;
      state.agents.unshift(action.payload);
    },
    removeAgent: (state, action: PayloadAction<string>) => {
      if (!state.agents) return;
      const filteredCompanies = state.agents.filter(
        (a) => a.id !== action.payload
      );
      state.agents = filteredCompanies;
    },
    filterEmptyAgent: (state) => {
      if (!state.agents) return;
      const filteredCompanies = state.agents.filter((a) => a.id !== "new");
      state.agents = filteredCompanies;
    },
    updateAgent: (state, action: PayloadAction<FetchAgentsItem>) => {
      if (!state.agents) return;
      const agentIndex = state.agents.findIndex(
        (a) => a.id === action.payload.id
      );
      if (agentIndex === null || agentIndex === undefined) return;
      state.agents[agentIndex] = action.payload;
    },
  },
});

export const agentsSelector = (state: RootState) => state.agentsSlice.agents;
export const selectedAgentSelector = (state: RootState) =>
  state.agentsSlice.selectedAgent;

export const isEmptyAgentExistSelector = (state: RootState) => {
  if (!state.agentsSlice.agents) return false;
  if (!state.agentsSlice.selectedAgent) return false;

  const emptyAgent = state.agentsSlice.agents.find((a) => a.id === "new");

  if (!emptyAgent) return false;

  return true;
};

export const {
  setSelectedAgent,
  setAgents,
  addAgent,
  removeAgent,
  filterEmptyAgent,
  updateAgent,
} = agentsSlice.actions;
export default agentsSlice.reducer;
