import React from "react";
import { InitialCompanyStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { FileInput } from "../Components";

export type Props = FormikType<InitialCompanyStateType>;

export default function CompanyImage({ formik }: Props) {
  return (
    <div className="mb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Post Image
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Add an image to this Companies profile.
      </p>
      <FileInput
        type="images"
        source={formik.values.image ?? ""}
        onSelect={(file) => formik.setFieldValue("newImage", file)}
        onRemove={() => {
          formik.setFieldValue("image", "");
          formik.setFieldValue("newImage", undefined);
        }}
      />
    </div>
  );
}
