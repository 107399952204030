import React, { useEffect, useState } from "react";
import { UserSubscription } from "../../types/MainTypes/UserSubscription";
import {
  useLazyFindOneSubscriptionQuery,
  useLazyListPricesQuery,
} from "../../services/StripeServices/StripeServices";
import { useIASelector } from "../../redux/hooks";
import { adminSelector } from "../../redux/slices/adminSlice";
import { StripePrice } from "../../types/MainTypes/StripePrice";

type Props = {
  userSubscription: UserSubscription;
};

export default function UserStripeSubscription({ userSubscription }: Props) {
  const subscriptionId = userSubscription.subscriptionId;
  const admin = useIASelector(adminSelector);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPrice, setCurrentPrice] = useState<StripePrice | undefined>(
    undefined
  );
  const [currency, setCurrency] = useState<string | undefined>(undefined);

  const [listPrices] = useLazyListPricesQuery();
  const [findOneSubscription] = useLazyFindOneSubscriptionQuery();

  useEffect(() => {
    getCurrentPrice();
  }, []);

  const getCurrentPrice = async () => {
    if (!admin || !subscriptionId) return;
    setIsLoading(true);
    try {
      const prices = await listPrices({
        userId: admin.id,
      }).unwrap();

      const subscription = await findOneSubscription({
        userId: admin.id,
        subscriptionId,
      });

      setCurrency(subscription.data?.currency);

      const price = prices.data.find(
        (stripePrice) =>
          stripePrice.id === subscription.data?.items.data[0]?.price.id
      );
      setCurrentPrice(price);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-black font-Switzer mb-8">
      <p className="text-3xl font-bold mb-2">User Subscription</p>
      <p className="text-base mb-4">
        See this users current subscription plan.
      </p>
      <div className="text-black font-Switzer">
        <div
          style={{
            backgroundColor: "white",
            borderWidth: 1,
            borderColor: "black",
          }}
          className={`rounded-lg p-4 mb-2`}
        >
          <p>
            {currency &&
              currentPrice &&
              currency.toUpperCase() +
                "$" +
                (
                  currentPrice.currency_options[currency]?.unit_amount! / 100
                ).toFixed(2) +
                " Per " +
                currentPrice.recurring.interval.charAt(0).toUpperCase() +
                currentPrice.recurring.interval.slice(1)}
          </p>
        </div>
      </div>
    </div>
  );
}
