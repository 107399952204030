import React from "react";
import { useIASelector } from "../../../redux/hooks";
import { overlaySelector } from "../../../redux/slices/overlaySlice";
import { BasicLoader, LargeBasicLoader } from "../../Components";

export default function IAOverlay() {
  const overlayState = useIASelector(overlaySelector);

  return (
    <div className="absolute inset-0 bg-black/80 backdrop-blur-sm z-50">
      <div
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
        h-[8em] flex flex-col justify-between text-center"
      >
        <LargeBasicLoader />
        <p className="text-white text-4xl font-semibold font-Switzer">
          ... {overlayState.text}
        </p>
      </div>
    </div>
  );
}
