import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { InitialAgentState } from "../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addAgent,
  filterEmptyAgent,
  selectedAgentSelector,
  setSelectedAgent,
  updateAgent,
} from "../../redux/slices/agentsSlice";
import {
  useCreateAgentMutation,
  useLazyFetchAgentQuery,
  useUpdateAgentMutation,
} from "../../services/AgentServices/AgentServices";
import {
  CreateAgentArgs,
  UpdateAgentArgs,
} from "../../services/AgentServices/AgentTypes";
import { useGetPresignedUploadUrlMutation } from "../../services/FileServices/FileServices";
import { isErrorWithMultipleMessages } from "../../services/helper";
import { Representation } from "../../types/MainTypes/Representation";
import { OmitFields } from "../../types/types";
import asyncTimeout from "../../utils/asyncTimeout";
import { CreateAgentSchema } from "../../utils/validationSchema";
import AgentDetails from "./AgentDetails";
import AgentsDeleteSave from "./AgentsDeleteSave";
import AgentsList from "./AgentsList";

export default function AgentsMain() {
  const dispatch = useIADispatch();
  const selectedAgent = useIASelector(selectedAgentSelector);

  //APIS
  const [createAgent] = useCreateAgentMutation();
  const [updateAgentQuery] = useUpdateAgentMutation();
  const [fetchAgent] = useLazyFetchAgentQuery();
  const [getPresignedUpload] = useGetPresignedUploadUrlMutation();

  useEffect(() => {
    dispatch(setSelectedAgent(null));
  }, []);

  useEffect(() => {
    formik.setTouched({});
  }, [selectedAgent]);

  const onSave = async () => {
    if (!selectedAgent) return;
    formik.setSubmitting(true);
    try {
      var image = await uploadFile({
        file: formik.values.newImage,
      });

      const representations: OmitFields<Representation, "id">[] = [];

      formik.values.representations.map((a) =>
        representations.push({
          title: a.title,
        })
      );

      const { id, agentId, createdAt, updatedAt, ...agentLocation } =
        formik.values.agentLocation;

      const payload: UpdateAgentArgs = {
        id: selectedAgent.id,
        payload: {
          name: formik.values.name,
          appearance: formik.values.appearance,
          description: formik.values.description,
          websiteLink: formik.values.websiteLink,
          instagramLink: formik.values.instagramLink,
          emailLink: formik.values.emailLink,
          isActive: formik.values.isActive,
          agentLocation: agentLocation,
          agentRepresentations: representations,
          image: image,
        },
      };

      const [response] = await Promise.all([
        updateAgentQuery(payload).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response) return;

      const agent = await fetchAgent({
        id: response.id,
      }).unwrap();

      if (!agent) return;

      dispatch(updateAgent(agent));
      toast.success("Successfully updated");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      formik.setSubmitting(false);
    }
  };

  const onCreate = async () => {
    formik.setSubmitting(true);

    var image = await uploadFile({
      file: formik.values.newImage,
    });

    const representations: OmitFields<Representation, "id">[] = [];

    formik.values.representations.map((a) =>
      representations.push({
        title: a.title,
      })
    );

    const { id, agentId, createdAt, updatedAt, ...agentLocation } =
      formik.values.agentLocation;

    const payload: CreateAgentArgs = {
      name: formik.values.name,
      appearance: formik.values.appearance,
      description: formik.values.description,
      websiteLink: formik.values.websiteLink,
      instagramLink: formik.values.instagramLink,
      emailLink: formik.values.emailLink,
      isActive: formik.values.isActive,
      agentLocation: agentLocation,
      agentRepresentations: representations,
      image: image,
    };

    try {
      const [response] = await Promise.all([
        createAgent(payload).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response) return;

      const agent = await fetchAgent({
        id: response.id,
      }).unwrap();

      if (!agent) return;

      dispatch(addAgent(agent));
      dispatch(filterEmptyAgent());
      dispatch(setSelectedAgent(agent));

      toast.success("Successfully created");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: InitialAgentState,
    validationSchema: CreateAgentSchema,
    onSubmit: selectedAgent && selectedAgent.id !== "new" ? onSave : onCreate,
  });

  const uploadFile = async ({ file }: { file: File | undefined }) => {
    var existingUrl = formik.values.image;

    if (!existingUrl && !file) return null;

    if (existingUrl && !file) return existingUrl;

    if (!file) return null;

    try {
      const response = await getPresignedUpload({
        imageKey: file.name,
        rootFolder: "images",
      }).unwrap();
      if (!response) return null;

      const uploading = await axios.put(response.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (uploading.status !== 200) throw Error;
      return response.fileName;
    } catch (error) {
      console.log("error", error);

      throw error;
    }
  };

  return (
    <>
      <AgentsList />
      <AgentDetails formik={formik} />
      <AgentsDeleteSave formik={formik} />
    </>
  );
}
