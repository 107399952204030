import React, { useState } from "react";
import { FormikType } from "../../types/types";
import { InitialAuditionStateType } from "../../assets/data/initialState";
import { CompanyTypeFilterModal, CustomButton } from "../Components";

export type Props = FormikType<InitialAuditionStateType>;

export default function CompanyTypeFilter({ formik }: Props) {
  const [isCompanyTypeFilterModalVisible, setIsCompanyTypeFilterModalVisible] =
    useState<boolean>(false);

  return (
    <div className="mb-8">
      <CompanyTypeFilterModal
        formik={formik}
        isOpen={isCompanyTypeFilterModalVisible}
        closeModal={() => setIsCompanyTypeFilterModalVisible(false)}
      />
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Company Type
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Set what type of company this audition is for.
      </p>
      <div className="flex flex-row space-x-2 overflow-x-auto scrollbar-hide">
        <CustomButton
          text="Edit"
          className="medium-green-button"
          onClick={() => setIsCompanyTypeFilterModalVisible(true)}
        />
        {formik.values.companyTypeFilters &&
          formik.values.companyTypeFilters.map((ct, i) => (
            <CustomButton
              key={i}
              text={ct.title}
              className="medium-white-button-no-hover"
            />
          ))}
      </div>
    </div>
  );
}
