import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type OverlayPayload = Pick<Overlay, "text">;

export interface Overlay {
  isLoading: boolean;
  text: string;
}

export const InitialOverlayState: Overlay = {
  isLoading: false,
  text: "",
};

export const overlaySlice = createSlice({
  name: "overlay",
  initialState: InitialOverlayState,
  reducers: {
    openOverlay: (state, action: PayloadAction<OverlayPayload>) => {
      state.isLoading = true;
      state.text = action.payload.text;
    },
    closeOverlay: (state) => {
      state.isLoading = false;
    },
  },
});

export const overlaySelector = (state: RootState) => state.overlaySlice;
export const { openOverlay, closeOverlay } = overlaySlice.actions;
export default overlaySlice.reducer;
