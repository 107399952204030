import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyType } from "../../types/MainTypes/CompanyType";
import updateItemInArrayIfExists from "../../utils/updateItemInArrayIfExists";
import { RootState } from "../store";

export type initialCompanyTypesStateType = {
  companyTypes: CompanyType[] | null;
  selectedCompanyType: CompanyType | null;
  selectedCompanyTypes: CompanyType[];
};

export const initialCompanyTypesState: initialCompanyTypesStateType = {
  companyTypes: null,
  selectedCompanyType: null,
  selectedCompanyTypes: [],
};

export const companyTypesSlice = createSlice({
  name: "companyTypes",
  initialState: initialCompanyTypesState,
  reducers: {
    setCompanyTypes: (state, action: PayloadAction<CompanyType[]>) => {
      state.companyTypes = action.payload;
    },
    addSelectedCompanyType: (state, action: PayloadAction<CompanyType>) => {
      if (!state.companyTypes) return;
      state.selectedCompanyTypes.unshift(action.payload);
      const filteredCompanyTypes = state.companyTypes.filter(
        (a) => a.id !== action.payload.id
      );
      state.companyTypes = filteredCompanyTypes;
    },
    removeSelectedCompanyType: (state, action: PayloadAction<CompanyType>) => {
      if (!state.companyTypes) return;
      const filteredSelectedCompanyTypes = state.selectedCompanyTypes.filter(
        (a) => a.id !== action.payload.id
      );
      state.selectedCompanyTypes = filteredSelectedCompanyTypes;
      state.companyTypes.unshift(action.payload);
    },
    setSelectedCompanyTypes: (state, action: PayloadAction<CompanyType[]>) => {
      state.selectedCompanyTypes = action.payload;
    },
    addCompanyType: (state, action: PayloadAction<CompanyType>) => {
      if (!state.companyTypes) return;
      state.companyTypes.unshift(action.payload);
    },
    deleteCompanyType: (state, action: PayloadAction<string>) => {
      if (!state.companyTypes) return;
      const filteredCompanyTypes = state.companyTypes.filter(
        (a) => a.id !== action.payload
      );
      state.companyTypes = filteredCompanyTypes;
      const filteredSelectedCompanyTypes = state.selectedCompanyTypes.filter(
        (c) => c.id !== action.payload
      );
      state.selectedCompanyTypes = filteredSelectedCompanyTypes;
    },
    updateSelectedCompanyType: (state, action: PayloadAction<CompanyType>) => {
      if (!state.selectedCompanyType) return;
      updateItemInArrayIfExists({
        array: state.selectedCompanyTypes,
        item: action.payload,
      });
      updateItemInArrayIfExists({
        array: state.companyTypes,
        item: action.payload,
      });
    },
    setSelectedCompanyType: (
      state,
      action: PayloadAction<CompanyType | null>
    ) => {
      if (!state.companyTypes) return;

      if (state.selectedCompanyType?.id === action.payload?.id) {
        state.selectedCompanyType = null;
        return;
      }
      state.selectedCompanyType = action.payload;
    },
  },
});

export const companyTypesSelector = (state: RootState) =>
  state.companyTypesSlice.companyTypes;

export const selectedCompanyTypeSelector = (state: RootState) =>
  state.companyTypesSlice.selectedCompanyType;

export const selectedCompanyTypesSelector = (state: RootState) =>
  state.companyTypesSlice.selectedCompanyTypes;

export const {
  setCompanyTypes,
  addCompanyType,
  deleteCompanyType,
  updateSelectedCompanyType,
  addSelectedCompanyType,
  removeSelectedCompanyType,
  setSelectedCompanyType,
  setSelectedCompanyTypes,
} = companyTypesSlice.actions;
export default companyTypesSlice.reducer;
