import React, { useEffect } from "react";
import { FormikType } from "../../../../types/types";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  removeSelectedPerformerTypeFilter,
  selectedPerformerTypeFilterSelector,
  selectedPerformerTypeFiltersSelector,
  setSelectedPerformerTypeFilter,
  setSelectedPerformerTypeFilters,
} from "../../../../redux/slices/performerTypeFiltersSlice";
import DataRowCard from "../../cards/DataRowCard";

type Props = FormikType<InitialAuditionStateType>;

export default function PerformerTypeFilterModalSelectedTypeFilters({
  formik,
}: Props) {
  const selectedPerformerTypeFilters = useIASelector(
    selectedPerformerTypeFiltersSelector
  );
  const selectedPerformerTypeFilter = useIASelector(
    selectedPerformerTypeFilterSelector
  );

  const dispatch = useIADispatch();

  useEffect(() => {
    if (!formik.values.performerTypeFilters) return;
    dispatch(
      setSelectedPerformerTypeFilters(formik.values.performerTypeFilters)
    );
  }, [formik.values.performerTypeFilters]);

  return (
    <div className="mx-4">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Selected Types
      </p>
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        {selectedPerformerTypeFilters.map((pt, i) => (
          <DataRowCard
            key={i}
            text={pt.title}
            isSelected={true}
            onClickContainer={() =>
              dispatch(setSelectedPerformerTypeFilter(pt))
            }
            onClickIcon={() => dispatch(removeSelectedPerformerTypeFilter(pt))}
            isSelectedToUpdate={
              selectedPerformerTypeFilter
                ? selectedPerformerTypeFilter.id === pt.id
                : false
            }
          />
        ))}
      </div>
    </div>
  );
}
