import React, { useEffect } from "react";
import { InitialAgentStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  removeSelectedAgentRepresentation,
  selectedAgentRepresentationSelector,
  selectedAgentRepresentationsSelector,
  setSelectedAgentRepresentation,
  setSelectedAgentRepresentations,
} from "../../../../redux/slices/agentRepresentationsSlice";
import { FormikType } from "../../../../types/types";
import DataRowCard from "../../cards/DataRowCard";
type Props = FormikType<InitialAgentStateType>;

export default function AgentRepModalSelectedReps({ formik }: Props) {
  const selectedAgentReps = useIASelector(selectedAgentRepresentationsSelector);
  const selectedAgentRep = useIASelector(selectedAgentRepresentationSelector);

  const dispatch = useIADispatch();

  useEffect(() => {
    if (!formik.values.representations) return;
    dispatch(setSelectedAgentRepresentations(formik.values.representations));
  }, [formik.values.representations]);

  return (
    <div className="mx-4">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Selected Types
      </p>
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        {selectedAgentReps.map((a, i) => (
          <DataRowCard
            key={i}
            text={a.title}
            isSelected={true}
            onClickContainer={() => dispatch(setSelectedAgentRepresentation(a))}
            onClickIcon={() => dispatch(removeSelectedAgentRepresentation(a))}
            isSelectedToUpdate={
              selectedAgentRep ? selectedAgentRep.id === a.id : false
            }
          />
        ))}
      </div>
    </div>
  );
}
