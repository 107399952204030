import React from "react";

type Props = {
  color: string;
  isSelected: boolean;
  onClick: () => void;
};

export default function ColoredButton({ color, isSelected, onClick }: Props) {
  return (
    <div
      className={`h-12 w-12 flex items-center justify-center rounded-xl ${
        isSelected ? " border-black" : " border-black/10"
      } border-2 cursor-pointer`}
      onClick={onClick}
    >
      <div
        style={{
          backgroundColor: color,
        }}
        className={`h-10 w-10 rounded-lg`}
      />
    </div>
  );
}
