import React from "react";
import { POST_TYPES } from "../../assets/data/enums";
import { InitialPostStateType } from "../../assets/data/initialState";
import { useIASelector } from "../../redux/hooks";
import { selectedPostSelector } from "../../redux/slices/postsSlice";
import { FormikType } from "../../types/types";
import { SelectableRoundedButtons } from "../Components";

type Props = FormikType<InitialPostStateType>;

export default function PostType({ formik }: Props) {
  const filters = Object.values(POST_TYPES);

  const getColorType = () => {
    switch (formik.values.type) {
      case POST_TYPES.GUIDES:
        return "purple";
      case POST_TYPES.MASTERCLASS:
        return "yellow";
      case POST_TYPES.NEWS:
        return "red";
      case POST_TYPES.UNDER_THE_SPOTLIGHT:
        return "black";
      case POST_TYPES.TIPS_AND_TRICKS:
        return "green";

      default:
        break;
    }
  };

  return (
    <div className="mb-8">
      <p className="font-Switzer font-bold text-3xl mb-2 text-black">
        Post Type
      </p>
      <p className="font-Switzer text-black mb-2">
        Select the type of this post this is. The type of post will dictate
        where this post will be stored and displayed to the user.
      </p>
      <SelectableRoundedButtons
        items={filters}
        selectedItem={formik.values.type}
        setSelectedItem={(item) => formik.setFieldValue("type", item)}
        colorType={getColorType()}
      />
    </div>
  );
}
