import React, { useState } from "react";
import { InitialCompanyStateType } from "../../assets/data/initialState";
import { ErrorIcon } from "../../assets/icons/Icons";
import { FormikType } from "../../types/types";
import { CompanyTypeModal, CustomButton } from "../Components";

type Props = FormikType<InitialCompanyStateType>;

export default function CompanyType({ formik }: Props) {
  const [isCompanyTypeModalVisible, setIsCompanyTypeModalVisible] =
    useState<boolean>(false);

  return (
    <div className="mb-8">
      <CompanyTypeModal
        formik={formik}
        isOpen={isCompanyTypeModalVisible}
        closeModal={() => setIsCompanyTypeModalVisible(false)}
      />
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Company Type
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Select company categorys that best match this company.
      </p>
      <div className="flex flex-row space-x-2 overflow-x-auto scrollbar-hide">
        <CustomButton
          text="Edit"
          className="medium-green-button"
          onClick={() => setIsCompanyTypeModalVisible(true)}
        />
        {formik.values.companyTypes &&
          formik.values.companyTypes.map((c, i) => (
            <CustomButton
              key={i}
              text={c.title}
              className="medium-white-button-no-hover"
            />
          ))}
      </div>
      {formik.touched.companyTypes && formik.errors.companyTypes && (
        <div
          className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          Please select company types
        </div>
      )}
    </div>
  );
}
