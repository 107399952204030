import React from "react";
import { Logo } from "../../../assets/images/Images";

type Props = {
  className?: string;
};

export default function LogoLoader({ className }: Props) {
  return (
    <div className={`flex flex-col justify-center items-center ${className}`}>
      <img src={Logo} alt="logo" className="w-30 h-10 object-contain mb-6" />
      <div
        className={`w-8 h-8 rounded-full animate-spin
                    border-4 border-solid border-t-transparent border-black`}
      />
    </div>
  );
}
