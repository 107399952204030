import React, { useState } from "react";
import { InitialAgentStateType } from "../../assets/data/initialState";
import { ErrorIcon } from "../../assets/icons/Icons";
import { FormikType } from "../../types/types";
import { CustomButton } from "../Components";
import AgentRepresentationModal from "../_core/modals/AgentRepresentationModal/AgentRepresentationModal";
export type Props = FormikType<InitialAgentStateType>;

export default function AgentRepresentation({ formik }: Props) {
  const [
    isAgentRepresentationModalVisible,
    setIsAgentRepresentationModalVisible,
  ] = useState<boolean>(false);
  return (
    <div className="mb-8">
      <AgentRepresentationModal
        formik={formik}
        isOpen={isAgentRepresentationModalVisible}
        closeModal={() => setIsAgentRepresentationModalVisible(false)}
      />
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Representation
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Select the categories this Agent / Agency represents.
      </p>
      <div className="flex flex-row space-x-2 overflow-x-auto scrollbar-hide">
        <CustomButton
          text="Edit"
          className="medium-green-button"
          onClick={() => setIsAgentRepresentationModalVisible(true)}
        />
        {formik.values.representations &&
          formik.values.representations.map((a, i) => (
            <CustomButton
              key={i}
              text={a.title}
              className="medium-white-button-no-hover"
            />
          ))}
      </div>
      {formik.touched.representations && formik.errors.representations && (
        <div
          className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          Please select agent representation
        </div>
      )}
    </div>
  );
}
