import { LoginArgs } from "../../services/AdminServices/AdminTypes";
import { Agent } from "../../types/MainTypes/Agent";
import { AgentLocation } from "../../types/MainTypes/AgentLocation";
import { Representation } from "../../types/MainTypes/Representation";
import { Audition } from "../../types/MainTypes/Audition";
import { AuditionLocation } from "../../types/MainTypes/AuditionLocation";
import { AuditionType } from "../../types/MainTypes/AuditionType";
import { Company } from "../../types/MainTypes/Company";
import { CompanyLocation } from "../../types/MainTypes/CompanyLocation";
import { CompanyType } from "../../types/MainTypes/CompanyType";
import { Post } from "../../types/MainTypes/Post";
import { PostTag } from "../../types/MainTypes/PostTag";
import { PublicAudition } from "../../types/MainTypes/PublicAudition";
import { OmitFields } from "../../types/types";
import { POST_TYPES } from "./enums";
import { User } from "../../types/MainTypes/User";
import { LocationTagFilter } from "../../types/MainTypes/LocationTagFilter";
import { PerformerTypeFilter } from "../../types/MainTypes/PerformerTypeFilter";
import { CompanyTypeFilter } from "../../types/MainTypes/CompanyTypeFilter";

// --- Admin ---
export const InitialLoginState: LoginArgs = {
  email: "",
  password: "",
};

// --- Audition ---
export type InitialAuditionStateType = OmitFields<
  Audition,
  "id" | "createdAt" | "updatedAt"
> &
  OmitFields<PublicAudition, "id" | "auditionId"> & {
    auditionTypes: AuditionType[];
    locationTagFilters: LocationTagFilter[];
    companyTypeFilters: CompanyTypeFilter[];
    performerTypeFilters: PerformerTypeFilter[];
    auditionLocation?: AuditionLocation;
    company: Company | null;
    agent: Agent | null;
  };

export const InitialAuditionState: InitialAuditionStateType = {
  title: "",
  auditioningFor: "",
  appearance: "",
  openDate: "",
  closeDate: "",
  expiryDate: "",
  description: "",
  buttonLink: "",
  buttonTitle: "",
  companyId: "",
  agentId: "",
  auditionTypes: [],
  locationTagFilters: [],
  companyTypeFilters: [],
  performerTypeFilters: [],
  auditionLocation: {
    id: "locationNew",
    streetName: "",
    streetNumber: "",
    suburb: "",
    city: "",
    postCode: "",
    state: "",
    country: "",
    fullAddress: "",
    latitude: 0,
    longitude: 0,
    auditionId: "new",
    createdAt: "",
    updatedAt: "",
  },
  company: null,
  agent: null,
  isOnline: false,
};

// --- Agent ---
export type InitialAgentStateType = OmitFields<
  Agent,
  "id" | "createdAt" | "updatedAt"
> & {
  agentLocation: AgentLocation;
  representations: Representation[];
  newImage: File | undefined;
  newVideo: File | undefined;
};

export const InitialAgentState: InitialAgentStateType = {
  name: "",
  image: "",
  appearance: "",
  description: "",
  websiteLink: "",
  instagramLink: "",
  emailLink: "",
  isActive: true,
  agentLocation: {
    id: "locationNew",
    streetName: "",
    streetNumber: "",
    suburb: "",
    city: "",
    postCode: "",
    state: "",
    country: "",
    fullAddress: "",
    latitude: 0,
    longitude: 0,
    agentId: "new",
    createdAt: "",
    updatedAt: "",
  },
  representations: [],
  newImage: undefined,
  newVideo: undefined,
};

// --- Company ---
export type InitialCompanyStateType = OmitFields<
  Company,
  "id" | "createdAt" | "updatedAt"
> & {
  companyLocation: CompanyLocation;
  companyTypes: CompanyType[];
  newImage: File | undefined;
  newVideo: File | undefined;
};

export const InitialCompanyState: InitialCompanyStateType = {
  name: "",
  image: "",
  appearance: "",
  description: "",
  websiteLink: "",
  instagramLink: "",
  emailLink: "",
  isActive: true,
  companyLocation: {
    id: "locationNew",
    streetName: "",
    streetNumber: "",
    suburb: "",
    city: "",
    postCode: "",
    state: "",
    country: "",
    fullAddress: "",
    latitude: 0,
    longitude: 0,
    companyId: "new",
    createdAt: "",
    updatedAt: "",
  },
  companyTypes: [],
  newImage: undefined,
  newVideo: undefined,
};

// --- Hub ---
export type InitialPostStateType = OmitFields<
  Post,
  "id" | "createdAt" | "updatedAt"
> & {
  postTags: PostTag[];
  newImage: File | undefined;
  newVideo: File | undefined;
};

export const InitialPostState: InitialPostStateType = {
  type: POST_TYPES.MASTERCLASS,
  title: "",
  author: "",
  image: "",
  video: "",
  buttonLink: "",
  buttonTitle: "",
  content: "",
  postTags: [],
  newImage: undefined,
  newVideo: undefined,
};

// --- User ---

export type InitialUserType = Pick<
  User,
  "firstName" | "lastName" | "email" | "mobile" | "password"
> & {
  confirmPassword: string;
};

export const InitialUserState: InitialUserType = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  mobile: "",
};

export const InitialTouchedUserState = {
  firstName: false,
  lastName: false,
  email: false,
  password: false,
  confirmPassword: false,
  mobile: false,
};
