import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { ErrorIcon } from "../../assets/icons/Icons";
import { useGetPresignedDownloadUrlQuery } from "../../services/FileServices/FileServices";
import { BasicLoader } from "../Components";

type Props = {
  fileName: string | null;
};

export default function UserAvatar({ fileName }: Props) {
  const [isImageError, setIsImageError] = useState(false);

  const { data, isLoading, isError } = useGetPresignedDownloadUrlQuery(
    {
      imageKey: fileName ?? "",
    } ?? skipToken
  );

  if (isError || isImageError || !data)
    return (
      <div
        className="w-10 h-10 flex items-center justify-center rounded-full border-[1px] border-black bg-white"
        role="alert"
      ></div>
    );

  return (
    <img
      className="h-10 w-10 object-cover rounded-full border-black border-[1px] bg-white"
      src={data}
      alt="thumnail"
      onError={({ currentTarget }) => {
        setIsImageError(true);
      }}
      loading="lazy"
    />
  );
}
