import React, { useEffect, useState } from "react";
import { FormikType } from "../../../../types/types";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import {
  addLocationTagFilter,
  deleteLocationTagFilter,
  selectedLocationTagFilterSelector,
  selectedLocationTagFiltersSelector,
  setSelectedLocationTagFilter,
  updateSelectedLocationTagFilter,
} from "../../../../redux/slices/locationTagFiltersSlice";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  useCreateLocationTagFilterMutation,
  useDeleteLocationTagFilterMutation,
  useUpdateLocationTagFilterMutation,
} from "../../../../services/LocationTagFiltersServices/LocationTagFiltersServices";
import { LocationTagFilterTitleSchema } from "../../../../utils/validationSchema";
import { useFormik } from "formik";
import asyncTimeout from "../../../../utils/asyncTimeout";
import { toast } from "react-toastify";
import { isErrorWithMessage } from "../../../../services/helper";
import TextInput from "../../inputfields/TextInput";
import CustomButton from "../../buttons/CustomButton";

type Props = FormikType<InitialAuditionStateType>;

export default function LocationTagFilterModalEdit({ formik }: Props) {
  const selectedLocationTagFilter = useIASelector(
    selectedLocationTagFilterSelector
  );
  const selectedLocationTagFilters = useIASelector(
    selectedLocationTagFiltersSelector
  );
  const dispatch = useIADispatch();

  //APIS
  const [createLocationTagFilter] = useCreateLocationTagFilterMutation();
  const [updateLocationTagFilter] = useUpdateLocationTagFilterMutation();
  const [deleteLocationTagFilterQuery] = useDeleteLocationTagFilterMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (!isUpdate) return;
    formik.setFieldValue("locationTagFilters", selectedLocationTagFilters);
    setIsUpdate(false);
  }, [selectedLocationTagFilters, isUpdate]);

  useEffect(() => {
    setFieldTouched("title", false);
    setFieldError("title", "");

    if (!selectedLocationTagFilter) {
      setFieldValue("title", "");
      return;
    }
    setFieldValue("title", selectedLocationTagFilter.title);
  }, [selectedLocationTagFilter]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      const [response] = await Promise.all([
        createLocationTagFilter({
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully created");
        dispatch(addLocationTagFilter(response));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async () => {
    if (!selectedLocationTagFilter) return;

    setIsLoading(true);

    try {
      const [response] = await Promise.all([
        updateLocationTagFilter({
          id: selectedLocationTagFilter.id,
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully updated");
        dispatch(updateSelectedLocationTagFilter(response));
        setIsUpdate(true);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    setIsDeleting(true);
    if (!selectedLocationTagFilter) return;
    try {
      const [response] = await Promise.all([
        deleteLocationTagFilterQuery({
          id: selectedLocationTagFilter.id,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully deleted");
        dispatch(setSelectedLocationTagFilter(null));
        dispatch(deleteLocationTagFilter(response.id));
        setIsUpdate(true);
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: selectedLocationTagFilter ? onUpdate : onSave,
    validationSchema: LocationTagFilterTitleSchema,
  });

  return (
    <div className="h-full w-full justify-between flex flex-col border-l-2 border-black">
      <div className="mx-4 ">
        <p className="font-Switzer font-bold text-black text-2xl my-4">
          {selectedLocationTagFilter
            ? "Update Location Tag"
            : "Create Location Tag"}
        </p>
        <p className="font-Switzer font-normal text-black text-base">
          {selectedLocationTagFilter
            ? "Update location tag to the library."
            : "Add a new location tag to the library."}
        </p>
        <TextInput
          label="Location Tag"
          name="Location Tag"
          type="text"
          value={values.title}
          required={true}
          onChange={(text) => setFieldValue("title", text)}
          isTouched={touched.title}
          error={errors.title}
        />
        <CustomButton
          text={selectedLocationTagFilter ? "Update Tag" : "Add Tag"}
          className="medium-green-button mt-6"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading || isDeleting}
        />
      </div>
      <div>
        <CustomButton
          text="Delete Tag"
          onClick={onDelete}
          className="medium-white-button mb-4 ml-4"
          loaderColor="black"
          isLoading={isDeleting}
          disabled={isLoading || isDeleting}
        />
      </div>
    </div>
  );
}
