import React, { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { useLazyFetchCompaniesQuery } from "../../../../services/CompanyServices/CompanyServices";
import { Company } from "../../../../types/MainTypes/Company";
import { FormikType } from "../../../../types/types";
import CustomButton from "../../buttons/CustomButton";
import DataRowCard from "../../cards/DataRowCard";
import SearchBar from "../../inputfields/SearchInput";
import BasicLoader from "../../loader/BasicLoader";
import { debounce } from "lodash";

type Props = FormikType<InitialAuditionStateType> & {
  companies: Company[];
  setCompanies: (values: Company[]) => void;
  selectedCompany: Company | null;
  setSelectedCompany: (values: Company | null) => void;
  closeModal: () => void;
};

export default function LinkToCompanyCompanies({
  formik,
  companies,
  setCompanies,
  selectedCompany,
  setSelectedCompany,
  closeModal,
}: Props) {
  const [search, setSearch] = useState<string>("");

  const take = 10;
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  //APIS
  const [fetchCompaniesQuery, { isLoading, isFetching }] =
    useLazyFetchCompaniesQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;
    if (search) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchCompanies();
      }, 300);
    } else {
      fetchCompanies();
    }
    return () => clearTimeout(timeout);
  }, [search]);

  const fetchCompanies = async () => {
    try {
      const response = await fetchCompaniesQuery(
        {
          search,
          active: "true",
          skip: 0,
          take: take,
        },
        true
      ).unwrap();

      if (response) {
        const filteredCompanies = response.filter(
          (c) => selectedCompany?.id !== c.id
        );

        setCompanies(filteredCompanies);
      }

      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsTyping(false);
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreCompanies();
    }
  };

  const fetchMoreCompanies = async () => {
    setIsLoadingMore(true);
    try {
      const response = await fetchCompaniesQuery(
        {
          search,
          active: "true",
          skip: skip + take,
          take: take,
        },
        true
      ).unwrap();

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!companies) return;

        const filteredCompanies = response.filter(
          (c) => selectedCompany?.id !== c.id
        );

        const newCompanies = companies.concat(filteredCompanies);

        setCompanies(newCompanies);
      }

      setSkip(skip + take);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleClickCompany = ({ company }: { company: Company }) => {
    if (selectedCompany) {
      const filteredCompanies = companies.filter((c) => c.id !== company.id);
      filteredCompanies.push(selectedCompany);
      setCompanies(filteredCompanies);
    } else {
      const filteredCompanies = companies.filter((c) => c.id !== company.id);
      setCompanies(filteredCompanies);
    }
    setSelectedCompany(company);
  };

  const handleSetLinks = () => {
    formik.setFieldValue("company", selectedCompany);
    closeModal();
  };

  const renderCompanies = () => {
    if (isLoading || isFetching || isTyping)
      return (
        <div className="w-full">
          <BasicLoader color="black" />
        </div>
      );
    if (!companies)
      return (
        <div className="w-full">
          <p className="font-Switzer text-base text-black">No Companies</p>
        </div>
      );

    return (
      <div className="w-full h-[calc(27vh)] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={50}
        >
          <div className="grid grid-cols-2 gap-y-2 gap-x-5">
            {companies.map((c, i) => (
              <DataRowCard
                key={i}
                text={c.name}
                onClickIcon={() => handleClickCompany({ company: c })}
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="h-full w-full justify-between flex flex-col">
      <div>
        <p className="font-bold font-Switzer text-2xl text-black my-4">
          Companies
        </p>
        <div className="ml-14">
          <SearchBar value={search} onChange={setSearch} />
        </div>
        {renderCompanies()}
      </div>
      <div className="flex-row flex items-center justify-start my-4">
        <CustomButton
          text="Set Links"
          className="medium-black-button"
          onClick={handleSetLinks}
        />
        <CustomButton
          className="medium-white-button ml-2"
          text="Cancel"
          onClick={closeModal}
        />
      </div>
    </div>
  );
}
