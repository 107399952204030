import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreatePostTagArgs,
  CreatePostTagReturnedData,
  DeletePostTagArgs,
  DeletePostTagReturnedData,
  FetchPostTagsArgs,
  FetchPostTagsReturnedData,
  UpdatePostTagArgs,
  UpdatePostTagReturnedData,
} from "./PostTagsTypes";

const PostTagsServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchPostTags: builder.query<FetchPostTagsReturnedData, FetchPostTagsArgs>({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `posts/tags?search=${args.search}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchPostTagsReturnedData };
      },
      providesTags: ["PostTags"],
    }),
    createPostTag: builder.mutation<
      CreatePostTagReturnedData,
      CreatePostTagArgs
    >({
      query: (args) => ({
        url: "posts/tags",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["PostTags"],
    }),
    updatePostTag: builder.mutation<
      UpdatePostTagReturnedData,
      UpdatePostTagArgs
    >({
      query: (args) => ({
        url: `posts/tags/${args.id}`,
        method: "PUT",
        body: {
          title: args.title,
        },
      }),
      invalidatesTags: ["PostTags"],
    }),
    deletePostTag: builder.mutation<
      DeletePostTagReturnedData,
      DeletePostTagArgs
    >({
      query: (args) => ({
        url: `posts/tags/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PostTags"],
    }),
  }),
});

export const {
  useLazyFetchPostTagsQuery,
  useCreatePostTagMutation,
  useDeletePostTagMutation,
  useUpdatePostTagMutation,
} = PostTagsServices;
