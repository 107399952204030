import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { InitialCompanyState } from "../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addCompany,
  filterEmptyCompany,
  selectedCompanySelector,
  setSelectedCompany,
  updateCompany,
} from "../../redux/slices/companiesSlice";
import {
  useCreateCompanyMutation,
  useLazyFetchCompanyQuery,
  useUpdateCompanyMutation,
} from "../../services/CompanyServices/CompanyServices";
import {
  CreateCompanyArgs,
  UpdateCompanyArgs,
} from "../../services/CompanyServices/CompanyTypes";
import { useGetPresignedUploadUrlMutation } from "../../services/FileServices/FileServices";
import { isErrorWithMultipleMessages } from "../../services/helper";
import { CompanyType } from "../../types/MainTypes/CompanyType";
import { OmitFields } from "../../types/types";
import asyncTimeout from "../../utils/asyncTimeout";
import { CreateCompanySchema } from "../../utils/validationSchema";
import {
  CompaniesList,
  CompanyDetails,
  CompaniesDeleteSave,
} from "../Components";

export default function CompaniesMain() {
  const dispatch = useIADispatch();
  const selectedCompany = useIASelector(selectedCompanySelector);

  //APIS
  const [createCompany] = useCreateCompanyMutation();
  const [updateCompanyQuery] = useUpdateCompanyMutation();
  const [fetchCompany] = useLazyFetchCompanyQuery();
  const [getPresignedUpload] = useGetPresignedUploadUrlMutation();

  useEffect(() => {
    dispatch(setSelectedCompany(null));
  }, []);

  useEffect(() => {
    formik.setTouched({});
  }, [selectedCompany]);

  const onSave = async () => {
    if (!selectedCompany) return;
    formik.setSubmitting(true);
    try {
      var image = await uploadFile({
        file: formik.values.newImage,
      });

      const companyTypes: OmitFields<CompanyType, "id">[] = [];

      formik.values.companyTypes.map((a) =>
        companyTypes.push({
          title: a.title,
        })
      );

      const { id, companyId, createdAt, updatedAt, ...companyLocation } =
        formik.values.companyLocation;

      const payload: UpdateCompanyArgs = {
        id: selectedCompany.id,
        payload: {
          name: formik.values.name,
          appearance: formik.values.appearance,
          description: formik.values.description,
          websiteLink: formik.values.websiteLink,
          instagramLink: formik.values.instagramLink,
          emailLink: formik.values.emailLink,
          isActive: formik.values.isActive,
          companyTypes: companyTypes,
          companyLocation: companyLocation,
          image: image,
        },
      };

      const [response] = await Promise.all([
        updateCompanyQuery(payload).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response) return;

      const company = await fetchCompany({
        id: response.id,
      }).unwrap();

      if (!company) return;

      dispatch(updateCompany(company));
      toast.success("Successfully updated");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      formik.setSubmitting(false);
    }
  };

  const onCreate = async () => {
    formik.setSubmitting(true);

    var image = await uploadFile({
      file: formik.values.newImage,
    });

    const companyTypes: OmitFields<CompanyType, "id">[] = [];

    formik.values.companyTypes.map((a) =>
      companyTypes.push({
        title: a.title,
      })
    );

    const { id, companyId, createdAt, updatedAt, ...companyLocation } =
      formik.values.companyLocation;

    const payload: CreateCompanyArgs = {
      name: formik.values.name,
      appearance: formik.values.appearance,
      description: formik.values.description,
      websiteLink: formik.values.websiteLink,
      instagramLink: formik.values.instagramLink,
      emailLink: formik.values.emailLink,
      isActive: formik.values.isActive,
      companyTypes: companyTypes,
      companyLocation: companyLocation,
      image: image,
    };

    try {
      const [response] = await Promise.all([
        createCompany(payload).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response) return;

      const company = await fetchCompany({
        id: response.id,
      }).unwrap();

      if (!company) return;

      dispatch(addCompany(company));
      dispatch(filterEmptyCompany());
      dispatch(setSelectedCompany(company));

      toast.success("Successfully created");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      formik.setSubmitting(false);
    }
  };

  const uploadFile = async ({ file }: { file: File | undefined }) => {
    var existingUrl = formik.values.image;

    if (!existingUrl && !file) return null;

    if (existingUrl && !file) return existingUrl;

    if (!file) return null;

    try {
      const response = await getPresignedUpload({
        imageKey: file.name,
        rootFolder: "images",
      }).unwrap();
      if (!response) return null;

      const uploading = await axios.put(response.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (uploading.status !== 200) throw Error;
      return response.fileName;
    } catch (error) {
      console.log("error", error);

      throw error;
    }
  };

  const formik = useFormik({
    initialValues: InitialCompanyState,
    validationSchema: CreateCompanySchema,
    onSubmit:
      selectedCompany && selectedCompany.id !== "new" ? onSave : onCreate,
  });

  return (
    <>
      <CompaniesList />
      <CompanyDetails formik={formik} />
      {selectedCompany ? <CompaniesDeleteSave formik={formik} /> : null}
    </>
  );
}
