import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types/MainTypes/User";
import { RootState } from "../store";

export type initialUsersStateType = {
  users: User[] | null;
  selectedUser: User | null;
};

export const initialUsersState: initialUsersStateType = {
  users: null,
  selectedUser: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState: initialUsersState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[] | null>) => {
      state.users = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<User | null>) => {
      state.selectedUser = action.payload;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      if (!state.users) return;
      const userIndex = state.users.findIndex(
        (u) => u.id === action.payload.id
      );

      if (userIndex === null || userIndex === undefined) return;
      state.users[userIndex] = action.payload;
    },
    addUser: (state, action: PayloadAction<User>) => {
      if (!state.users) return;
      state.users.unshift(action.payload);
    },
    deleteUser: (state, action: PayloadAction<string>) => {
      if (!state.users) return;
      const filteredUsers = state.users.filter((u) => u.id !== action.payload);
      state.users = filteredUsers;
      state.selectedUser = null;
    },
  },
});

export const selectedUserSelector = (state: RootState) =>
  state.usersSlice.selectedUser;

export const usersSelector = (state: RootState) => state.usersSlice.users;

export const { setUsers, setSelectedUser, updateUser, addUser, deleteUser } =
  usersSlice.actions;

export default usersSlice.reducer;
