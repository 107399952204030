import React from "react";

export default function HubIcon({
  onClick,
  className,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 32 32"
      className={className}
      onClick={onClick}
    >
      <g id="diversity_3_black_24dp" transform="translate(-0.216 -0.463)">
        <g
          id="Group_669"
          data-name="Group 669"
          transform="translate(0.216 0.463)"
        >
          <rect
            id="Rectangle_1315"
            data-name="Rectangle 1315"
            width="32"
            height="32"
            transform="translate(0)"
            fill="none"
          />
        </g>
        <g
          id="Group_671"
          data-name="Group 671"
          transform="translate(1.386 5.561)"
        >
          <g id="Group_670" data-name="Group 670">
            <path
              id="Path_992"
              data-name="Path 992"
              d="M6.32,13.739a4.076,4.076,0,0,1,3.261,1.826,5.226,5.226,0,0,0,8.6-.014,4.076,4.076,0,0,1,3.261-1.826C20.483,12.063,16.649,11,13.88,11S7.278,12.063,6.32,13.739Z"
              transform="translate(0.761 -1.461)"
            />
            <path
              id="Path_993"
              data-name="Path 993"
              d="M4.993,15.177h0A4.089,4.089,0,0,0,4.993,7a4.089,4.089,0,0,0,0,8.177Z"
              transform="translate(-1 -2.912)"
            />
            <path
              id="Path_994"
              data-name="Path 994"
              d="M20.993,15.177h0A4.089,4.089,0,1,0,17,11.088,4.036,4.036,0,0,0,20.993,15.177Z"
              transform="translate(4.297 -2.912)"
            />
            <path
              id="Path_995"
              data-name="Path 995"
              d="M12.993,12.177a4.036,4.036,0,0,0,3.993-4.088A3.994,3.994,0,1,0,9,8.088,4.036,4.036,0,0,0,12.993,12.177Z"
              transform="translate(1.649 -4)"
            />
            <path
              id="Path_996"
              data-name="Path 996"
              d="M27.621,14H23.269a2.743,2.743,0,0,0-2.236,1.254,6.543,6.543,0,0,1-10.782,0A2.758,2.758,0,0,0,8.015,14H3.662A2.7,2.7,0,0,0,1,16.726v5.451h9.317V19.1a9.113,9.113,0,0,0,10.649,0v3.08h9.317V16.726A2.7,2.7,0,0,0,27.621,14Z"
              transform="translate(-1 -0.372)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
