import React from "react";
import { InitialPostStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { TextInput } from "../Components";

type Props = FormikType<InitialPostStateType>;

export default function PostGeneralDetails({ formik }: Props) {
  return (
    <div className="mb-8">
      <p className="font-Switzer font-bold text-3xl mb-2 text-black">
        General Details
      </p>
      <p className="font-Switzer text-black mb-2">
        Include the fundamental information regarding this post.
      </p>
      <TextInput
        label="Title Of Post"
        name="Title Of Post"
        type="text"
        value={formik.values.title}
        required={true}
        onChange={(text) => formik.setFieldValue("title", text)}
        isTouched={formik.touched.title}
        error={formik.errors.title}
      />
      <TextInput
        label="Author"
        name="Author"
        type="text"
        value={formik.values.author}
        required={true}
        onChange={(text) => formik.setFieldValue("author", text)}
        isTouched={formik.touched.author}
        error={formik.errors.author}
      />
    </div>
  );
}
