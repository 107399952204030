import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { boolean } from "yup";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import { selectedUserSelector, updateUser } from "../../redux/slices/userSlice";
import { isErrorWithMessage } from "../../services/helper";
import { useBlockUserMutation } from "../../services/UserServices/UserServices";
import { BasicSwitch } from "../Components";

export default function UserManageMent() {
  const selectedUser = useIASelector(selectedUserSelector);
  const dispatch = useIADispatch();

  const [isBlocked, setIsBlocked] = useState<boolean>(false);

  //APIS
  const [blockUser, { isLoading }] = useBlockUserMutation();

  useEffect(() => {
    if (!selectedUser) return;
    setIsBlocked(selectedUser.isRestricted);
  }, [selectedUser]);

  const handleBlockUser = async (checked: boolean) => {
    if (!selectedUser) return;

    try {
      const response = await blockUser({
        userId: selectedUser.id,
        restrict: checked ? "true" : "false",
      }).unwrap();

      if (!response) return;
      dispatch(updateUser(response));
      toast.success("Successfully updated");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    }
    setIsBlocked(checked);
  };

  return (
    <div className="text-black font-Switzer mb-8">
      <p className="text-3xl font-bold mb-2">User Management</p>
      <p className="text-base mb-6">
        Manage this user’s access to the Industry Auditions application. To
        block this user from accessing the app turn the toggle on. To allow the
        user to access their app, turn the toggle off.
      </p>
      <div className="bg-IAGrey items-center flex justify-between p-2 rounded-lg">
        <p className="font-Switzer font-bold">Block User Access</p>
        <BasicSwitch
          disabled={isLoading}
          isChecked={isBlocked}
          onChange={(checked) => handleBlockUser(checked)}
        />
      </div>
    </div>
  );
}
