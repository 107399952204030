import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PerformerTypeFilter } from "../../types/MainTypes/PerformerTypeFilter";
import updateItemInArrayIfExists from "../../utils/updateItemInArrayIfExists";

export type initialPerformerTypeFiltersStateType = {
  performerTypeFilters: PerformerTypeFilter[] | null;
  selectedPerformerTypeFilter: PerformerTypeFilter | null;
  selectedPerformerTypeFilters: PerformerTypeFilter[];
};

export const initialPerformerTypeFiltersState: initialPerformerTypeFiltersStateType =
  {
    performerTypeFilters: null,
    selectedPerformerTypeFilter: null,
    selectedPerformerTypeFilters: [],
  };

export const performerTypeFiltersSlice = createSlice({
  name: "performerTypeFilters",
  initialState: initialPerformerTypeFiltersState,
  reducers: {
    setPerformerTypeFilters: (
      state,
      action: PayloadAction<PerformerTypeFilter[]>
    ) => {
      state.performerTypeFilters = action.payload;
    },
    addSelectedPerformerTypeFilter: (
      state,
      action: PayloadAction<PerformerTypeFilter>
    ) => {
      if (!state.performerTypeFilters) return;
      state.selectedPerformerTypeFilters.unshift(action.payload);
      const filteredPerformerTypeFilters = state.performerTypeFilters.filter(
        (pt) => pt.id !== action.payload.id
      );
      state.performerTypeFilters = filteredPerformerTypeFilters;
    },
    removeSelectedPerformerTypeFilter: (
      state,
      action: PayloadAction<PerformerTypeFilter>
    ) => {
      if (!state.performerTypeFilters) return;
      const filteredSelectedPerformerTypeFilters =
        state.selectedPerformerTypeFilters.filter(
          (pt) => pt.id !== action.payload.id
        );
      state.selectedPerformerTypeFilters = filteredSelectedPerformerTypeFilters;
      state.performerTypeFilters.unshift(action.payload);
    },
    setSelectedPerformerTypeFilters: (
      state,
      action: PayloadAction<PerformerTypeFilter[]>
    ) => {
      state.selectedPerformerTypeFilters = action.payload;
    },
    addPerformerTypeFilter: (
      state,
      action: PayloadAction<PerformerTypeFilter>
    ) => {
      if (!state.performerTypeFilters) return;
      state.performerTypeFilters.unshift(action.payload);
    },
    deletePerformerTypeFilter: (state, action: PayloadAction<string>) => {
      if (!state.performerTypeFilters) return;
      const filteredPerformerTypeFilters = state.performerTypeFilters.filter(
        (pt) => pt.id !== action.payload
      );
      state.performerTypeFilters = filteredPerformerTypeFilters;
      const filteredSelectedPerformerTypeFilters =
        state.selectedPerformerTypeFilters.filter(
          (pt) => pt.id !== action.payload
        );
      state.selectedPerformerTypeFilters = filteredSelectedPerformerTypeFilters;
    },
    updateSelectedPerformerTypeFilters: (
      state,
      action: PayloadAction<PerformerTypeFilter>
    ) => {
      if (!state.selectedPerformerTypeFilter) return;
      updateItemInArrayIfExists({
        array: state.selectedPerformerTypeFilters,
        item: action.payload,
      });
      updateItemInArrayIfExists({
        array: state.performerTypeFilters,
        item: action.payload,
      });
    },
    setSelectedPerformerTypeFilter: (
      state,
      action: PayloadAction<PerformerTypeFilter | null>
    ) => {
      if (!state.performerTypeFilters) return;

      if (state.selectedPerformerTypeFilter?.id === action.payload?.id) {
        state.selectedPerformerTypeFilter = null;
        return;
      }
      state.selectedPerformerTypeFilter = action.payload;
    },
  },
});

export const performerTypeFiltersSelector = (state: RootState) =>
  state.performerTypeFiltersSlice.performerTypeFilters;

export const selectedPerformerTypeFilterSelector = (state: RootState) =>
  state.performerTypeFiltersSlice.selectedPerformerTypeFilter;

export const selectedPerformerTypeFiltersSelector = (state: RootState) =>
  state.performerTypeFiltersSlice.selectedPerformerTypeFilters;

export const {
  addPerformerTypeFilter,
  addSelectedPerformerTypeFilter,
  deletePerformerTypeFilter,
  removeSelectedPerformerTypeFilter,
  setPerformerTypeFilters,
  setSelectedPerformerTypeFilter,
  setSelectedPerformerTypeFilters,
  updateSelectedPerformerTypeFilters,
} = performerTypeFiltersSlice.actions;
export default performerTypeFiltersSlice.reducer;
