import { InitialPostStateType } from "../assets/data/initialState";
import { FetchPostsItem } from "../services/PostServices/PostTypes";
import { PostTag } from "../types/MainTypes/PostTag";
import { FormikType } from "../types/types";

type Props = FormikType<InitialPostStateType> & {
  selectedPost: FetchPostsItem;
};

const setPostFormikValues = ({ formik, selectedPost }: Props) => {
  formik.setFieldValue("type", selectedPost.type);
  formik.setFieldValue("title", selectedPost.title);
  formik.setFieldValue("author", selectedPost.author);

  const postTags: PostTag[] = [];
  if (selectedPost.postToPostTags) {
    selectedPost.postToPostTags.map((p) => postTags.push(p.postTag));
  }

  formik.setFieldValue("postTags", postTags);
  formik.setFieldValue("image", selectedPost.image);
  formik.setFieldValue("newImage", undefined);

  formik.setFieldValue("video", selectedPost.video);
  formik.setFieldValue("newVideo", undefined);

  formik.setFieldValue("buttonTitle", selectedPost.buttonTitle);
  formik.setFieldValue("buttonLink", selectedPost.buttonLink);

  formik.setFieldValue("content", selectedPost.content);
};

export default setPostFormikValues;
