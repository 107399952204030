import { Dialog } from "@headlessui/react";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  children: JSX.Element;
};

export default function CustomModal({ isOpen, closeModal, children }: Props) {
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 z-50 flex justify-center items-center"
    >
      <div className="fixed inset-0 bg-black/50" />
      {children}
    </Dialog>
  );
}
