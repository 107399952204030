import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

const useAuth = () => {
  const token = localStorage.getItem("IAAdminToken");
  if (token) return true;
  return false;
};

export const AuthRequiredRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export const NotAuthRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Navigate to="/auditions" /> : <Outlet />;
};
