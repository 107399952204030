import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { emptyPost } from "../../assets/data/emptyState";
import { POST_FIlTERS } from "../../assets/data/enums";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addPost,
  filterEmptyPost,
  isEmptyPostExistSelector,
  postsSelector,
  selectedPostSelector,
  setPosts,
  setSelectedPost,
} from "../../redux/slices/postsSlice";
import { useLazyFetchPostsQuery } from "../../services/PostServices/PostServices";
import { FetchPostsItem } from "../../services/PostServices/PostTypes";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  BasicLoader,
  Header,
  LogoLoader,
  PostCard,
  SearchInput,
  SelectableRoundedButtons,
} from "../Components";

export default function HubList() {
  const take = 10;
  const filters = Object.values(POST_FIlTERS);
  const [selectedFilter, setSelectedFilter] = useState<
    typeof POST_FIlTERS[keyof typeof POST_FIlTERS]
  >(POST_FIlTERS.VIEW_ALL);
  const [searchText, setSearchText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const dispatch = useIADispatch();
  const posts = useIASelector(postsSelector);
  const selectedPost = useIASelector(selectedPostSelector);
  const isEmptyPostExist = useIASelector(isEmptyPostExistSelector);

  //APIS
  const [fetchPostsQuery, { isLoading, isFetching }] = useLazyFetchPostsQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;
    if (searchText) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchPosts();
      }, 300);
    } else {
      fetchPosts();
    }
    return () => clearTimeout(timeout);
  }, [searchText, selectedFilter]);

  const fetchPosts = async () => {
    try {
      const response = await fetchPostsQuery(
        {
          search: searchText,
          skip: 0,
          take: take,
          type: selectedFilter,
        },
        true
      ).unwrap();

      if (response) {
        dispatch(setPosts(response));
      }
      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsTyping(false);
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMorePosts();
    }
  };

  const fetchMorePosts = async () => {
    setIsLoadingMore(true);
    try {
      const [response] = await Promise.all([
        fetchPostsQuery({
          search: searchText,
          skip: skip + take,
          take: take,
          type: selectedFilter,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!posts) return;
        const newPosts = posts.concat(response);
        dispatch(setPosts(newPosts));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const createEmptyPost = () => {
    if (isEmptyPostExist) return;
    dispatch(addPost(emptyPost));
    dispatch(setSelectedPost(emptyPost));
  };

  const renderPostsList = () => {
    if ((isLoading || isFetching || isTyping) && !isLoadingMore)
      return (
        <div className="w-full">
          <LogoLoader className="mt-10" />
        </div>
      );

    if (!posts) return <div></div>;

    if (!posts.length)
      return (
        <div className="w-full flex flex-col items-center font-Switzer font-semibold text-lg">
          NO POSTS
        </div>
      );

    return (
      <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={150}
        >
          {posts.map((post: FetchPostsItem, index: number) => (
            <PostCard
              key={index}
              post={post}
              isSelected={selectedPost ? selectedPost.id === post.id : false}
              onClick={() => {
                dispatch(filterEmptyPost());
                dispatch(setSelectedPost(post));
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="w-full">
      <Header text="Industry Hub" type="add" onClick={createEmptyPost} />
      <SearchInput value={searchText} onChange={setSearchText} />
      <SelectableRoundedButtons
        items={filters}
        selectedItem={selectedFilter}
        setSelectedItem={setSelectedFilter}
      />
      {renderPostsList()}
    </div>
  );
}
