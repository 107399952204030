import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { InitialAgentStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  addAgentRepresentation,
  deleteAgentRepresentation,
  selectedAgentRepresentationSelector,
  selectedAgentRepresentationsSelector,
  setSelectedAgentRepresentation,
  updateSelectedAgentRepresentation,
} from "../../../../redux/slices/agentRepresentationsSlice";
import {
  useCreateAgentRepresentationMutation,
  useDeleteAgentRepresentationMutation,
  useUpdateAgentRepresentationMutation,
} from "../../../../services/AgentRepresentationsServices/AgentRepresentationsServices";
import { isErrorWithMessage } from "../../../../services/helper";
import { FormikType } from "../../../../types/types";
import asyncTimeout from "../../../../utils/asyncTimeout";
import { AgentRepTitleSchema } from "../../../../utils/validationSchema";
import CustomButton from "../../buttons/CustomButton";
import TextInput from "../../inputfields/TextInput";
type Props = FormikType<InitialAgentStateType>;

export default function AgentRepModalEdit({ formik }: Props) {
  const selectedAgentRep = useIASelector(selectedAgentRepresentationSelector);
  const selectedAgentReps = useIASelector(selectedAgentRepresentationsSelector);
  const dispatch = useIADispatch();

  //APIS
  const [createAgentRep] = useCreateAgentRepresentationMutation();
  const [updateAgentRep] = useUpdateAgentRepresentationMutation();
  const [deleteAgentRepQuery] = useDeleteAgentRepresentationMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (!isUpdate) return;
    formik.setFieldValue("representations", selectedAgentReps);
    setIsUpdate(false);
  }, [selectedAgentReps, isUpdate]);

  useEffect(() => {
    setFieldTouched("title", false);
    setFieldError("title", "");

    if (!selectedAgentRep) {
      setFieldValue("title", "");
      return;
    }
    setFieldValue("title", selectedAgentRep.title);
  }, [selectedAgentRep]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      const [response] = await Promise.all([
        createAgentRep({
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully created");
        dispatch(addAgentRepresentation(response));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async () => {
    if (!selectedAgentRep) return;

    setIsLoading(true);

    try {
      const [response] = await Promise.all([
        updateAgentRep({
          id: selectedAgentRep.id,
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully updated");
        dispatch(updateSelectedAgentRepresentation(response));
        setIsUpdate(true);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    setIsDeleting(true);
    if (!selectedAgentRep) return;
    try {
      const [response] = await Promise.all([
        deleteAgentRepQuery({ id: selectedAgentRep.id }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully deleted");
        dispatch(setSelectedAgentRepresentation(null));
        dispatch(deleteAgentRepresentation(response.id));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: selectedAgentRep ? onUpdate : onSave,
    validationSchema: AgentRepTitleSchema,
  });

  return (
    <div className="h-full w-full justify-between flex flex-col border-l-2 border-black">
      <div className="mx-4">
        <p className="font-Switzer font-bold text-black text-2xl my-4">
          {selectedAgentRep
            ? "Update Representation Type"
            : "Create Representation Type"}
        </p>
        <p className="font-Switzer font-normal text-black text-base">
          {selectedAgentRep
            ? "Update representation type to the library."
            : "Add a new representation type to the library."}
        </p>
        <TextInput
          label="Representation Type"
          name="Representation Type"
          type="text"
          value={values.title}
          required={true}
          onChange={(text) => setFieldValue("title", text)}
          isTouched={touched.title}
          error={errors.title}
        />
        <CustomButton
          text={selectedAgentRep ? "Update Type" : "Add Type"}
          className="medium-green-button mt-6"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading || isDeleting}
        />
      </div>
      <div>
        <CustomButton
          text="Delete Type"
          onClick={onDelete}
          className="medium-white-button mb-4 ml-4"
          loaderColor="black"
          isLoading={isDeleting}
          disabled={isLoading || isDeleting}
        />
      </div>
    </div>
  );
}
