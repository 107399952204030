import moment from "moment";
import React from "react";
import { FetchSubmissionsItem } from "../../services/SubmissionServices/SubmissionTypes";
import { CustomButton } from "../Components";

type Props = {
  submission: FetchSubmissionsItem;
  onClick: () => void;
  isSelected: boolean;
};

export default function SubmissionCard({
  submission,
  onClick,
  isSelected,
}: Props) {
  return (
    <div
      className={`transition-all duration-200 w-full border-2 border-black ${
        isSelected ? "text-white bg-black" : "text-black bg-white"
      } cursor-pointer p-2 group mb-4`}
      onClick={onClick}
    >
      <div className="flex flex-row items-center justify-between">
        <p className="font-bold">
          {submission.user.firstName + " " + submission.user.lastName}
        </p>
        <p className="text-xs">
          {moment(submission.createdAt).format("DD MMM YYYY")}
        </p>
      </div>
      <div className="text-2xl mb-2">{submission.topic}</div>
      <CustomButton
        text={submission.tag}
        className={
          !isSelected
            ? "extra-small-black-button-no-hover"
            : "extra-small-white-button-no-hover"
        }
      />
    </div>
  );
}
