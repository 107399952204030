import React, { useEffect } from "react";
import { useIASelector } from "../../redux/hooks";
import { selectedUserSelector } from "../../redux/slices/userSlice";
import { useFetchSubscriberQuery } from "../../services/RevenueCatServices/RevenueCatServices";
import { BasicLoader, SubscriptionCard } from "../Components";

export default function UserSubscription() {
  const selectedUser = useIASelector(selectedUserSelector);

  //APIS
  const { data, isLoading, isFetching } = useFetchSubscriberQuery({
    appUserId: selectedUser ? selectedUser.id : "",
  });

  if (isLoading || isFetching) {
    return (
      <div className="w-full mb-8">
        <BasicLoader color="black" />
      </div>
    );
  }

  if (!selectedUser) return <div />;
  if (!data) return <div />;
  if (!data.subscriber) return <div />;
  if (!data.subscriber.entitlements) return <div />;
  if (!data.subscriber.entitlements.production) return <div />;
  if (!data.subscriber.entitlements.production.expires_date) return <div />;

  return (
    <div className="text-black font-Switzer mb-8">
      <p className="text-3xl font-bold mb-2">User Subscription</p>
      <p className="text-base mb-4">
        See this users current subscription plan.
      </p>
      {selectedUser && data ? (
        <SubscriptionCard subscriber={data.subscriber} />
      ) : null}
    </div>
  );
}
