import { InitialAgentStateType } from "../assets/data/initialState";
import { FetchAgentsItem } from "../services/AgentServices/AgentTypes";
import { Representation } from "../types/MainTypes/Representation";
import { FormikType } from "../types/types";

export type Props = FormikType<InitialAgentStateType> & {
  selectedAgent: FetchAgentsItem;
};

const setAgentFormikValues = ({ formik, selectedAgent }: Props) => {
  formik.setFieldValue("name", selectedAgent.name);

  formik.setFieldValue("agentLocation", selectedAgent.agentLocation);
  formik.setFieldValue("image", selectedAgent.image);
  formik.setFieldValue("newImage", undefined);
  formik.setFieldValue("appearance", selectedAgent.appearance);
  formik.setFieldValue("description", selectedAgent.description);
  formik.setFieldValue("websiteLink", selectedAgent.websiteLink);
  formik.setFieldValue("instagramLink", selectedAgent.instagramLink);
  formik.setFieldValue("emailLink", selectedAgent.emailLink);
  formik.setFieldValue("isActive", selectedAgent.isActive);

  //AGENT REPRESENTATION
  const representations: Representation[] = [];

  if (selectedAgent.agentRepresentations) {
    selectedAgent.agentRepresentations.map((a) =>
      representations.push(a.representation)
    );
  }

  formik.setFieldValue("representations", representations);
};

export default setAgentFormikValues;
