import React from "react";

type props = {
  color?: string;
  className?: string;
};

export default function LargeBasicLoader({
  color = "white",
  className,
}: props) {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      <div
        className={`w-12 h-12 rounded-full animate-spin
                        border-4 border-solid border-t-transparent border-${color}`}
      />
    </div>
  );
}
