import React, { useEffect } from "react";
import { InitialAgentStateType } from "../../assets/data/initialState";
import { useIASelector } from "../../redux/hooks";
import { selectedAgentSelector } from "../../redux/slices/agentsSlice";
import { FormikType } from "../../types/types";
import setAgentFormikValues from "../../utils/setAgentFormikValues";
import {
  AgentActiveStatus,
  AgentAppearnace,
  AgentDescription,
  AgentExternalLinks,
  AgentGeneralDetails,
  AgentImage,
  Header,
} from "../Components";
import AgentRepresentation from "./AgentRepresentation";

export type Props = FormikType<InitialAgentStateType>;

export default function AgentDetails({ formik }: Props) {
  const selectedAgent = useIASelector(selectedAgentSelector);

  useEffect(() => {
    if (!selectedAgent) return;

    setAgentFormikValues({
      formik: formik,
      selectedAgent: selectedAgent,
    });
  }, [selectedAgent]);

  return (
    <div className="w-full">
      <Header text="Agent Details" />
      {selectedAgent ? (
        <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
          <AgentGeneralDetails formik={formik} />
          <AgentRepresentation formik={formik} />
          <AgentImage formik={formik} />
          <AgentAppearnace formik={formik} />
          <AgentDescription formik={formik} />
          <AgentExternalLinks formik={formik} />
          <AgentActiveStatus formik={formik} />
        </div>
      ) : (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <p className="font-Switzer font-semibold text-2xl mb-2">
            No Agent Selected
          </p>
          <p className="font-Switzer font-normal text-l mb-48">
            Please Select a Agent
          </p>
        </div>
      )}
    </div>
  );
}
