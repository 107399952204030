import React from "react";

export default function DiscoverIcon({
  onClick,
  className,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={className}
      onClick={onClick}
    >
      <g id="travel_explore_black_24dp" transform="translate(0.289 -0.463)">
        <rect
          id="Rectangle_1317"
          data-name="Rectangle 1317"
          width="40"
          height="40"
          transform="translate(-0.289 0.463)"
          fill="none"
        />
        <path
          id="Path_1003"
          data-name="Path 1003"
          d="M30.784,27.381a8.345,8.345,0,0,0,1.165-4.088,7.489,7.489,0,1,0-14.975,0,7.546,7.546,0,0,0,7.487,7.666,7.875,7.875,0,0,0,3.993-1.192l5.324,5.451,2.329-2.385Zm-6.323.17a4.26,4.26,0,1,1,4.16-4.259A4.169,4.169,0,0,1,24.462,27.552Zm-5.823,5.11v3.407A16.846,16.846,0,0,1,2,19.034,16.846,16.846,0,0,1,18.638,2,16.756,16.756,0,0,1,34.944,15.628H31.5a13.532,13.532,0,0,0-7.87-9.216v.7A3.378,3.378,0,0,1,20.3,10.517H16.975v3.407a1.689,1.689,0,0,1-1.664,1.7H11.983v3.407h3.328v5.11H13.647l-7.97-8.16a14.142,14.142,0,0,0-.349,3.049A13.49,13.49,0,0,0,18.638,32.662Z"
          transform="translate(1.308 1.429)"
        />
      </g>
    </svg>
  );
}
