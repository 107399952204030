import React from "react";
import { ArrowLeftIcon } from "../../../assets/icons/Icons";
import { useIADispatch } from "../../../redux/hooks";

type Props = {
  title: string;
  description?: string;
  closeModal: () => void;
};

export default function ModalHeader({ title, description, closeModal }: Props) {
  return (
    <div className="w-full py-4 border-b-2 border-black">
      <div className="flex justify-start items-center">
        <ArrowLeftIcon
          className="cursor-pointer hover:opacity-80"
          onClick={closeModal}
        />
        <p className="font-Switzer font-medium text-black text-3xl">{title}</p>
      </div>
      {description ? (
        <p className="pl-[57px] mt-[-0.5em]">{description}</p>
      ) : null}
    </div>
  );
}
