import React, { useEffect, useState } from "react";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  addSelectedAgentRepresentation,
  agentRepresentationsSelector,
  selectedAgentRepresentationSelector,
  setAgentRepresentations,
  setSelectedAgentRepresentation,
  setSelectedAgentRepresentations,
} from "../../../../redux/slices/agentRepresentationsSlice";
import { useLazyFetchAgentRepresentationsQuery } from "../../../../services/AgentRepresentationsServices/AgentRepresentationsServices";
import { FormikType } from "../../../../types/types";
import asyncTimeout from "../../../../utils/asyncTimeout";
import { InitialAgentStateType } from "../../../../assets/data/initialState";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";
import BasicLoader from "../../loader/BasicLoader";
import DataRowCard from "../../cards/DataRowCard";
type Props = FormikType<InitialAgentStateType>;
export default function AgentRepModalAgentReps({ formik }: Props) {
  const dispatch = useIADispatch();
  const agentReps = useIASelector(agentRepresentationsSelector);
  const selectedAgentRep = useIASelector(selectedAgentRepresentationSelector);

  const take = 10;
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  //APIS
  const [fetchAgentRepsQuery, { isLoading, isFetching }] =
    useLazyFetchAgentRepresentationsQuery();

  useEffect(() => {
    setSkip(0);
    dispatch(setSelectedAgentRepresentation(null));
    fetchAgentReps();
  }, []);

  const fetchAgentReps = async () => {
    try {
      const response = await fetchAgentRepsQuery(
        {
          search: "",
          skip: 0,
          take: take,
        },
        true
      ).unwrap();

      if (response) {
        const filteredAgentReps = response.filter(
          (a1) => !formik.values.representations.some((a2) => a1.id === a2.id)
        );
        dispatch(setAgentRepresentations(filteredAgentReps));
      }

      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreAgentReps();
    }
  };

  const fetchMoreAgentReps = async () => {
    setIsLoadingMore(true);
    try {
      const response = await fetchAgentRepsQuery(
        {
          search: "",
          skip: skip + take,
          take: take,
        },
        true
      ).unwrap();

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!agentReps) return;
        const newAgentReps = agentReps.concat(response);
        dispatch(setAgentRepresentations(newAgentReps));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderAgentReps = () => {
    if ((isLoading || isFetching) && !isLoadingMore)
      return (
        <div className="w-full">
          <BasicLoader color="black" />
        </div>
      );
    if (!agentReps)
      return (
        <div className="w-full">
          <p className="font-Switzer text-base text-black">No Company Types</p>
        </div>
      );

    return (
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={50}
        >
          {agentReps.map((a, i) => (
            <DataRowCard
              key={i}
              text={a.title}
              onClickContainer={() =>
                dispatch(setSelectedAgentRepresentation(a))
              }
              onClickIcon={() => dispatch(addSelectedAgentRepresentation(a))}
              isSelectedToUpdate={
                selectedAgentRep ? selectedAgentRep.id === a.id : false
              }
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="">
      <p className="font-Switzer font-bold text-black text-2xl my-4">Types</p>
      {renderAgentReps()}
    </div>
  );
}
