import React, { useState } from "react";
import { InitialPostStateType } from "../../assets/data/initialState";
import { ErrorIcon } from "../../assets/icons/Icons";
import { FormikType } from "../../types/types";
import { CustomButton, PostTagsModal } from "../Components";

type Props = FormikType<InitialPostStateType>;

export default function PostTags({ formik }: Props) {
  const [isPostTagsModalVisible, setIsPostTagsModalVisible] =
    useState<boolean>(false);

  return (
    <div className="mb-8">
      <PostTagsModal
        formik={formik}
        isOpen={isPostTagsModalVisible}
        closeModal={() => setIsPostTagsModalVisible(false)}
      />
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Post Tags
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Select Audition Types that best match this audition.
      </p>
      <div className="flex flex-row space-x-2 overflow-x-auto scrollbar-hide">
        <CustomButton
          text="Edit"
          className="medium-green-button"
          onClick={() => setIsPostTagsModalVisible(true)}
        />
        {formik.values.postTags &&
          formik.values.postTags.map((p, i) => (
            <CustomButton
              key={i}
              text={p.title}
              className="medium-white-button-no-hover"
            />
          ))}
      </div>
      {formik.touched.postTags && formik.errors.postTags && (
        <div
          className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          Please select post tags
        </div>
      )}
    </div>
  );
}
