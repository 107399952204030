import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";

import {
  CreateAuditionTypeArgs,
  CreateAuditionTypeReturnedData,
  DeleteAuditionTypeArgs,
  DeleteAuditionTypeReturnedData,
  FetchAuditionReturnedData,
  FetchAuditionTypesArgs,
  UpdateAuditionTypeArgs,
  UpdateAuditionTypeReturnedData,
} from "./AuditionTypesTypes";

const AuditionTypesServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchAuditionTypes: builder.query<
      FetchAuditionReturnedData,
      FetchAuditionTypesArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `auditions/type?search=${args.search}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchAuditionReturnedData };
      },
      providesTags: ["AuditionTypes"],
    }),
    createAuditionType: builder.mutation<
      CreateAuditionTypeReturnedData,
      CreateAuditionTypeArgs
    >({
      query: (args) => ({
        url: "auditions/type",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["AuditionTypes"],
    }),
    updateAuditionType: builder.mutation<
      UpdateAuditionTypeReturnedData,
      UpdateAuditionTypeArgs
    >({
      query: (args) => ({
        url: `auditions/type/${args.id}`,
        method: "PUT",
        body: {
          title: args.title,
        },
      }),
      invalidatesTags: ["AuditionTypes"],
    }),
    deleteAuditionType: builder.mutation<
      DeleteAuditionTypeReturnedData,
      DeleteAuditionTypeArgs
    >({
      query: (args) => ({
        url: `auditions/type/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AuditionTypes"],
    }),
  }),
});

export const {
  useCreateAuditionTypeMutation,
  useFetchAuditionTypesQuery,
  useLazyFetchAuditionTypesQuery,
  useUpdateAuditionTypeMutation,
  useDeleteAuditionTypeMutation,
} = AuditionTypesServices;
