import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import updateItemInArrayIfExists from "../../utils/updateItemInArrayIfExists";
import { RootState } from "../store";
import { CompanyTypeFilter } from "../../types/MainTypes/CompanyTypeFilter";

export type initialCompanyTypeFiltersStateType = {
  companyTypeFilters: CompanyTypeFilter[] | null;
  selectedCompanyTypeFilter: CompanyTypeFilter | null;
  selectedCompanyTypeFilters: CompanyTypeFilter[];
};

export const initialCompanyTypeFiltersState: initialCompanyTypeFiltersStateType =
  {
    companyTypeFilters: null,
    selectedCompanyTypeFilter: null,
    selectedCompanyTypeFilters: [],
  };

export const companyTypeFiltersSlice = createSlice({
  name: "companyTypeFilters",
  initialState: initialCompanyTypeFiltersState,
  reducers: {
    setCompanyTypeFilters: (
      state,
      action: PayloadAction<CompanyTypeFilter[]>
    ) => {
      state.companyTypeFilters = action.payload;
    },
    addSelectedCompanyTypeFilter: (
      state,
      action: PayloadAction<CompanyTypeFilter>
    ) => {
      if (!state.companyTypeFilters) return;
      state.selectedCompanyTypeFilters.unshift(action.payload);
      const filteredCompanyTypeFilters = state.companyTypeFilters.filter(
        (ct) => ct.id !== action.payload.id
      );
      state.companyTypeFilters = filteredCompanyTypeFilters;
    },
    removeSelectedCompanyTypeFilter: (
      state,
      action: PayloadAction<CompanyTypeFilter>
    ) => {
      if (!state.companyTypeFilters) return;
      const filteredSelectedCompanyTypeFilters =
        state.selectedCompanyTypeFilters.filter(
          (ct) => ct.id !== action.payload.id
        );
      state.selectedCompanyTypeFilters = filteredSelectedCompanyTypeFilters;
      state.companyTypeFilters.unshift(action.payload);
    },
    setSelectedCompanyTypeFilters: (
      state,
      action: PayloadAction<CompanyTypeFilter[]>
    ) => {
      state.selectedCompanyTypeFilters = action.payload;
    },
    addCompanyTypeFilter: (state, action: PayloadAction<CompanyTypeFilter>) => {
      if (!state.companyTypeFilters) return;
      state.companyTypeFilters.unshift(action.payload);
    },
    deleteCompanyTypeFilter: (state, action: PayloadAction<string>) => {
      if (!state.companyTypeFilters) return;
      const filteredCompanyTypeFilters = state.companyTypeFilters.filter(
        (ct) => ct.id !== action.payload
      );
      state.companyTypeFilters = filteredCompanyTypeFilters;
      const filteredSelectedCompanyTypeFilters =
        state.selectedCompanyTypeFilters.filter(
          (ct) => ct.id !== action.payload
        );
      state.selectedCompanyTypeFilters = filteredSelectedCompanyTypeFilters;
    },
    updateSelectedCompanyTypeFilter: (
      state,
      action: PayloadAction<CompanyTypeFilter>
    ) => {
      if (!state.selectedCompanyTypeFilter) return;
      updateItemInArrayIfExists({
        array: state.selectedCompanyTypeFilters,
        item: action.payload,
      });
      updateItemInArrayIfExists({
        array: state.companyTypeFilters,
        item: action.payload,
      });
    },
    setSelectedCompanyTypeFilter: (
      state,
      action: PayloadAction<CompanyTypeFilter | null>
    ) => {
      if (!state.companyTypeFilters) return;

      if (state.selectedCompanyTypeFilter?.id === action.payload?.id) {
        state.selectedCompanyTypeFilter = null;
        return;
      }
      state.selectedCompanyTypeFilter = action.payload;
    },
  },
});

export const companyTypeFiltersSelector = (state: RootState) =>
  state.companyTypeFiltersSlice.companyTypeFilters;

export const selectedCompanyTypeFilterSelector = (state: RootState) =>
  state.companyTypeFiltersSlice.selectedCompanyTypeFilter;

export const selectedCompanyTypeFiltersSelector = (state: RootState) =>
  state.companyTypeFiltersSlice.selectedCompanyTypeFilters;

export const {
  addCompanyTypeFilter,
  addSelectedCompanyTypeFilter,
  deleteCompanyTypeFilter,
  removeSelectedCompanyTypeFilter,
  setCompanyTypeFilters,
  setSelectedCompanyTypeFilter,
  setSelectedCompanyTypeFilters,
  updateSelectedCompanyTypeFilter,
} = companyTypeFiltersSlice.actions;

export default companyTypeFiltersSlice.reducer;
