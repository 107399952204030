import React from "react";

export default function PlusIcon({
  className,
  onClick,
  disabled,
}: React.HTMLProps<HTMLButtonElement>) {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      <svg
        id="add_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <path
          id="Path_986"
          data-name="Path 986"
          d="M0,0H32V32H0Z"
          fill="none"
        />
        <path
          id="Path_987"
          data-name="Path 987"
          d="M23.667,15.667h-8v8H13v-8H5V13h8V5h2.667v8h8Z"
          transform="translate(1.667 1.667)"
        />
      </svg>
    </button>
  );
}
