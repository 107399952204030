import React, { CSSProperties } from "react";
import { BasicLoader } from "../../Components";

type Props = {
  text: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  loaderColor?: "black" | "white";
  style?: CSSProperties;
};

export default function CustomButton({
  text,
  className,
  disabled,
  isLoading,
  onClick,
  loaderColor = "white",
  style,
}: Props) {
  return (
    <button
      style={style}
      type="button"
      className={className}
      disabled={disabled || isLoading || !onClick}
      onClick={onClick}
    >
      {isLoading ? <BasicLoader color={loaderColor} /> : <>{text}</>}
    </button>
  );
}
