import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { Agent } from "../../../../types/MainTypes/Agent";
import { FormikType } from "../../../../types/types";
import CustomModal from "../../CustomModal";
import ModalHeader from "../../headers/ModalHeader";
import LinkToAgentAgents from "./LinkToAgentAgents";
import LinkToAgentSelectedAgents from "./LinkToAgentSelectedAgents";

type Props = FormikType<InitialAuditionStateType> & {
  isOpen: boolean;
  closeModal: () => void;
};

export default function LinkToAgentModal({
  isOpen,
  closeModal,
  formik,
}: Props) {
  const [selectedAgent, setSelectedAgent] = useState<Agent | null>(null);
  const [agents, setAgents] = useState<Agent[]>([]);

  useEffect(() => {
    setSelectedAgent(formik.values.agent);
  }, [isOpen, , formik.values.agent]);

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/2">
        <ModalHeader
          title="Link To Agent"
          description="Edit, add and manage the Agent links."
          closeModal={closeModal}
        />
        <div className="grid grid-cols-modal px-4 h-full">
          <LinkToAgentAgents
            agents={agents}
            setAgents={setAgents}
            selectedAgent={selectedAgent}
            setSelectedAgent={setSelectedAgent}
            closeModal={closeModal}
            formik={formik}
          />
          <LinkToAgentSelectedAgents
            agents={agents}
            selectedAgent={selectedAgent}
            setSelectedAgent={setSelectedAgent}
          />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
