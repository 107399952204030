import React from "react";
import { InitialPostStateType } from "../../../../assets/data/initialState";
import { FormikType, OmitFields } from "../../../../types/types";
import { Dialog } from "@headlessui/react";
import CustomModal from "../../CustomModal";
import ModalHeader from "../../headers/ModalHeader";
import PostTagsModalPostTags from "./PostTagsModalPostTags";
import PostTagsModalSelectedTags from "./PostTagsModalSelectedTags";
import CustomButton from "../../buttons/CustomButton";
import PostTagsModalEdit from "./PostTagsModalEdit";
import { PostTag } from "../../../../types/MainTypes/PostTag";
import { useIASelector } from "../../../../redux/hooks";
import { selectedPostTagsSelector } from "../../../../redux/slices/postTagsSlice";

type Props = FormikType<InitialPostStateType> & {
  isOpen: boolean;
  closeModal: () => void;
};

export default function PostTagsModal({ formik, isOpen, closeModal }: Props) {
  const selectedPostTags = useIASelector(selectedPostTagsSelector);

  const handleSetPostTags = () => {
    formik.setFieldValue("postTags", selectedPostTags);
    closeModal();
  };

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/2">
        <ModalHeader
          title="Post Tags"
          description="Edit, add and manage the Post Tags for this Post."
          closeModal={closeModal}
        />
        <div className="grid grid-cols-modal px-4 h-full">
          <div className="h-4/5 w-full justify-between flex flex-col">
            <div className="w-full grid grid-cols-postTags">
              <PostTagsModalPostTags formik={formik} />
              <PostTagsModalSelectedTags formik={formik} />
            </div>
            <div className="flex-row flex items-center justify-start mb-4">
              <CustomButton
                className="medium-black-button mr-2"
                text="Set Post Tags"
                onClick={handleSetPostTags}
              />
              <CustomButton
                className="medium-white-button"
                text="Cancel"
                onClick={closeModal}
              />
            </div>
          </div>
          <PostTagsModalEdit formik={formik} />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
