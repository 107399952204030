import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { emptyCompany } from "../../assets/data/emptyState";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addCompany,
  companiesSelector,
  filterEmptyCompany,
  isEmptyCompanyExistSelector,
  selectedCompanySelector,
  setCompanies,
  setSelectedCompany,
} from "../../redux/slices/companiesSlice";
import { useLazyFetchCompaniesQuery } from "../../services/CompanyServices/CompanyServices";
import { FetchCompaniesItem } from "../../services/CompanyServices/CompanyTypes";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  BasicLoader,
  CompanyCard,
  Header,
  LogoLoader,
  SearchInput,
  SelectableRoundedButtons,
} from "../Components";

export default function CompaniesList() {
  const take = 10;
  const filters = ["All Companies", "Active", "In-Active"];
  const [selectedFilter, setSelectedFilter] = useState<string>("All Companies");
  const [searchText, setSearchText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const dispatch = useIADispatch();
  const companies = useIASelector(companiesSelector);
  const selectedCompany = useIASelector(selectedCompanySelector);
  const isEmptyCompanyExist = useIASelector(isEmptyCompanyExistSelector);

  //APIS
  const [fetchCompaniesQuery, { isLoading, isFetching }] =
    useLazyFetchCompaniesQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;
    if (searchText) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchCompanies();
      }, 300);
    } else {
      fetchCompanies();
    }
  }, [searchText, selectedFilter]);

  const getActive = () => {
    switch (selectedFilter) {
      case "All Companies":
        return "";
      case "Active":
        return "true";
      case "In-Active":
        return "false";
      default:
        return "";
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await fetchCompaniesQuery(
        {
          search: searchText,
          skip: 0,
          take: take,
          active: getActive(),
        },
        true
      ).unwrap();

      if (response) {
        dispatch(setCompanies(response));
      }
      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsTyping(false);
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreCompanies();
    }
  };

  const fetchMoreCompanies = async () => {
    setIsLoadingMore(true);
    try {
      const [response] = await Promise.all([
        fetchCompaniesQuery({
          search: searchText,
          skip: skip + take,
          take: take,
          active: getActive(),
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!companies) return;
        const newCompanies = companies.concat(response);
        dispatch(setCompanies(newCompanies));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const createEmptyCompany = () => {
    if (isEmptyCompanyExist) return;
    dispatch(addCompany(emptyCompany));
    dispatch(setSelectedCompany(emptyCompany));
  };

  const renderCompaniesList = () => {
    if ((isLoading || isFetching || isTyping) && !isLoadingMore)
      return (
        <div className="w-full">
          <LogoLoader className="mt-10" />
        </div>
      );

    if (!companies) return <div></div>;

    if (!companies.length)
      return (
        <div className="w-full flex flex-col items-center font-Switzer font-semibold text-lg">
          NO COMPANIES
        </div>
      );

    return (
      <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={150}
        >
          {companies.map((company: FetchCompaniesItem, index: number) => (
            <CompanyCard
              key={index}
              company={company}
              isSelected={
                selectedCompany ? selectedCompany.id === company.id : false
              }
              onClick={() => {
                dispatch(filterEmptyCompany());
                dispatch(setSelectedCompany(company));
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="w-full">
      <Header text="Companies" type="add" onClick={createEmptyCompany} />
      <SearchInput value={searchText} onChange={setSearchText} />
      <SelectableRoundedButtons
        items={filters}
        selectedItem={selectedFilter}
        setSelectedItem={setSelectedFilter}
      />
      {renderCompaniesList()}
    </div>
  );
}
