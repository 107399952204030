import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//COMPONENTS
import { InitialLoginState } from "../../assets/data/initialState";
import { LogoCard, CustomButton, TextInput } from "../../components/Components";
import { useIADispatch } from "../../redux/hooks";
import { useLoginMutation } from "../../services/AdminServices/AdminServices";
import { isErrorWithMessage } from "../../services/helper";
import asyncTimeout from "../../utils/asyncTimeout";
import { LoginSchema } from "../../utils/validationSchema";
import { setAdmin } from "../../redux/slices/adminSlice";

export default function Login() {
  const navigation = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useIADispatch();

  const onSignIn = async () => {
    try {
      const [response] = await Promise.all([
        login({
          email: values.email,
          password: values.password,
        }).unwrap(),
        asyncTimeout(300),
      ]);
      dispatch(setAdmin(response.adminDetails));
      localStorage.setItem("IAAdminToken", response.jwtToken);
      toast.success("Successfully logged-in");
      navigation("/auditions");
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Invalid Credentials"
      );
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: InitialLoginState,
    validationSchema: LoginSchema,
    onSubmit: onSignIn,
  });

  return (
    <div className="min-h-screen w-full flex justify-center items-center px-4">
      <div>
        <LogoCard />
        <p className="font-Switzer mt-10 text-5xl font-bold mb-2 tracking-tighter">
          Sign Back In
        </p>
        <p className="font-Switzer text-base tracking-tighter">
          Sign in with your email address and password to access your Industry
          Auditions account.
        </p>
        <form className="w-full mt-1" onSubmit={handleSubmit}>
          <TextInput
            label="Email"
            name="email"
            type="text"
            value={values.email}
            required={true}
            onChange={(text) => setFieldValue("email", text)}
            isTouched={touched.email}
            error={errors.email}
          />
          <TextInput
            label="Password"
            name="password"
            type="password"
            value={values.password}
            required={true}
            onChange={(text) => setFieldValue("password", text)}
            isTouched={touched.password}
            error={errors.password}
          />
          <div className="w-full mt-6">
            <CustomButton
              text="Sign In!"
              className="black-button"
              isLoading={isLoading}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
