import React from "react";
import { POST_FIlTERS, SUBMISSION_FIlTERS } from "../../assets/data/enums";
import { CustomButton } from "../Components";

type Props<T> = {
  items: T[];
  selectedItem: T;
  setSelectedItem: (value: T) => void;
  colorType?: "black" | "yellow" | "red" | "purple" | "green";
};

const SelectableRoundedButtons = <T extends string>({
  items,
  selectedItem,
  setSelectedItem,
  colorType = "black",
}: Props<T>) => {
  const getText = ({ text }: { text: T }) => {
    switch (text) {
      case POST_FIlTERS.UNDER_THE_SPOTLIGHT:
        return "Under The Spotlight";
      case POST_FIlTERS.TIPS_AND_TRICKS:
        return "Tips & Tricks";
      case POST_FIlTERS.VIEW_ALL:
        return "View All";
      case SUBMISSION_FIlTERS.VIEW_ALL:
        return "View All";
      default:
        return text;
    }
  };

  const getCustomButtonTextStyle = () => {
    switch (colorType) {
      case "black":
        return "small-black-button";
      case "yellow":
        return "small-yellow-button";
      case "green":
        return "small-green-button";
      case "red":
        return "small-red-button";
      case "purple":
        return "small-purple-button";
      default:
        break;
    }
  };

  return (
    <div className="flex mb-8 overflow-x-auto scrollbar-hide">
      {items.map((item, index) => (
        <CustomButton
          key={index}
          className={`mr-1 transition-all duration-150 ${
            selectedItem === item
              ? getCustomButtonTextStyle()
              : "small-white-button"
          }`}
          text={getText({
            text: item,
          })}
          onClick={() => setSelectedItem(item)}
        />
      ))}
    </div>
  );
};

export default SelectableRoundedButtons;
