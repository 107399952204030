import API from "../../redux/api";
import { StripeSubscription } from "../../types/MainTypes/StripeSubscription";
import {
  FindOneSubscriptionArgs,
  PricesListArgs,
  PricesListData,
} from "./StripeTypes";

const StripeService = API.injectEndpoints({
  endpoints: (builder) => ({
    findOneSubscription: builder.query<
      StripeSubscription,
      FindOneSubscriptionArgs
    >({
      query: (args) => ({
        url: `users/${args.userId}/stripe/subscriptions/${args.subscriptionId}`,
      }),
    }),
    listPrices: builder.query<PricesListData, PricesListArgs>({
      query: (args) => ({
        url: `users/${args.userId}/stripe/prices`,
      }),
      providesTags: ["StripePrices"],
    }),
  }),
});

export const { useLazyFindOneSubscriptionQuery, useLazyListPricesQuery } =
  StripeService;
