import React from "react";
import { PlusIcon, TrashBinIcon } from "../../../assets/icons/Icons";
import RefreshIcon from "../../../assets/icons/svg/RefreshIcon";
import BasicLoader from "../loader/BasicLoader";

type Props = {
  type?: "add" | "delete" | "empty";
  isRefreshButton?: boolean;
  text: string;
  isLoading?: boolean;
  onClick?: () => void;
  onRefresh?: () => void;
};

export default function Header({
  type,
  isRefreshButton = false,
  text,
  onClick,
  isLoading = false,
  onRefresh,
}: Props) {
  const getIcon = () => {
    switch (type) {
      case "add":
        return (
          <button
            disabled={isLoading}
            onClick={onClick}
            className="hover:opacity-80 h-12 w-12 rounded-lg bg-IAGreen flex justify-center items-center"
          >
            {isLoading ? <BasicLoader color={"black"} /> : <PlusIcon />}
          </button>
        );
      case "empty":
        return <div />;
      default:
        break;
    }
  };

  return (
    <div className="flex items-center justify-between h-16 my-5">
      <div>
        <p className="font-Switzer text-black text-5xl font-medium tracking-tight">
          {text}
        </p>
      </div>
      <div className="flex items-center gap-4">
        <div>{type ? getIcon() : null}</div>
        {isRefreshButton ? (
          <button
            onClick={onRefresh}
            className="h-12 w-12 flex justify-center items-center hover:opacity-80"
          >
            <RefreshIcon />
          </button>
        ) : null}
      </div>
    </div>
  );
}
