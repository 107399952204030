import { FetchAgentsItem } from "../../services/AgentServices/AgentTypes";
import { FetchAuditionsItem } from "../../services/AuditionServices/AuditionsTypes";
import { FetchCompaniesItem } from "../../services/CompanyServices/CompanyTypes";
import { FetchPostsItem } from "../../services/PostServices/PostTypes";
import { POST_TYPES } from "./enums";

export const emptyAudition: FetchAuditionsItem = {
  id: "new",
  title: "",
  auditioningFor: "",
  appearance: "",
  openDate: "",
  closeDate: null,
  description: "",
  companyId: null,
  agentId: null,
  createdAt: "",
  updatedAt: "",
  auditionLocation: {
    id: "locationNew",
    streetName: "",
    streetNumber: "",
    suburb: "",
    city: "",
    postCode: "",
    state: "",
    country: "",
    fullAddress: "",
    latitude: 0,
    longitude: 0,
    auditionId: "new",
    createdAt: "",
    updatedAt: "",
  },
  auditionToAuditionTypes: [],
  auditionToLocationTagFilters: [],
  auditionToPerformerTypeFilters: [],
  auditionToCompanyTypeFilters: [],
  agentRepresentations: [],
  publicAudition: null,
  company: null,
  agent: null,
  isOnline: false,
};

export const emptyPost: FetchPostsItem = {
  id: "new",
  type: POST_TYPES.MASTERCLASS,
  title: "",
  author: "",
  image: "",
  video: "",
  buttonTitle: "",
  buttonLink: "",
  content: "",
  createdAt: "",
  updatedAt: "",
  postToPostTags: [],
};

export const emptyCompany: FetchCompaniesItem = {
  id: "new",
  name: "",
  image: "",
  appearance: "",
  description: "",
  websiteLink: "",
  instagramLink: "",
  emailLink: "",
  isActive: true,
  companyLocation: {
    id: "locationNew",
    streetName: "",
    streetNumber: "",
    suburb: "",
    city: "",
    postCode: "",
    state: "",
    country: "",
    fullAddress: "",
    latitude: 0,
    longitude: 0,
    companyId: "new",
    createdAt: "",
    updatedAt: "",
  },
  companyToCompanyTypes: [],
  createdAt: "",
  updatedAt: "",
};

export const emptyAgent: FetchAgentsItem = {
  id: "new",
  name: "",
  image: "",
  appearance: "",
  description: "",
  websiteLink: "",
  instagramLink: "",
  emailLink: "",
  isActive: true,
  agentLocation: {
    id: "locationNew",
    streetName: "",
    streetNumber: "",
    suburb: "",
    city: "",
    postCode: "",
    state: "",
    country: "",
    fullAddress: "",
    latitude: 0,
    longitude: 0,
    agentId: "new",
    createdAt: "",
    updatedAt: "",
  },
  agentRepresentations: [],
  createdAt: "",
  updatedAt: "",
};
