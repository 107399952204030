import React from "react";
import { InitialAgentStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { GooglePlacesInput, TextInput } from "../Components";

export type Props = FormikType<InitialAgentStateType>;

export default function AgentGeneralDetails({ formik }: Props) {
  return (
    <div className="mb-8">
      <p className="font-Switzer font-bold text-3xl mb-2 text-black">
        General Details
      </p>
      <p className="font-Switzer text-black mb-2">
        Include the fundamental information regarding this company.
      </p>
      <TextInput
        label="Agent Name"
        name="Agent Name"
        type="text"
        value={formik.values.name}
        required={true}
        onChange={(text) => formik.setFieldValue("name", text)}
        isTouched={formik.touched.name}
        error={formik.errors.name}
      />
      <GooglePlacesInput agentFormik={formik} />
    </div>
  );
}
