import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreateLocationTagFilterArgs,
  CreateLocationTagFilterData,
  DeleteLocationTagFilterArgs,
  DeleteLocationTagFilterData,
  FetchLocationTagFiltersArgs,
  FetchLocationTagFiltersData,
  UpdateLocationTagFilterArgs,
  UpdateLocationTagFilterData,
} from "./LocationTagFiltersTypes";

const LocationTagFiltersServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchLocationTagFilters: builder.query<
      FetchLocationTagFiltersData,
      FetchLocationTagFiltersArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `auditions/location-tag-filter?search=${args.search}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchLocationTagFiltersData };
      },
      providesTags: ["LocationTagFilters"],
    }),
    createLocationTagFilter: builder.mutation<
      CreateLocationTagFilterData,
      CreateLocationTagFilterArgs
    >({
      query: (args) => ({
        url: "auditions/location-tag-filter",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["LocationTagFilters"],
    }),
    updateLocationTagFilter: builder.mutation<
      UpdateLocationTagFilterData,
      UpdateLocationTagFilterArgs
    >({
      query: (args) => ({
        url: `auditions/location-tag-filter/${args.id}`,
        method: "PUT",
        body: {
          title: args.title,
        },
      }),
      invalidatesTags: ["LocationTagFilters"],
    }),
    deleteLocationTagFilter: builder.mutation<
      DeleteLocationTagFilterData,
      DeleteLocationTagFilterArgs
    >({
      query: (args) => ({
        url: `auditions/location-tag-filter/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LocationTagFilters"],
    }),
  }),
});

export const {
  useCreateLocationTagFilterMutation,
  useFetchLocationTagFiltersQuery,
  useLazyFetchLocationTagFiltersQuery,
  useUpdateLocationTagFilterMutation,
  useDeleteLocationTagFilterMutation,
} = LocationTagFiltersServices;
