import { InitialAuditionStateType } from "../assets/data/initialState";
import { FetchAuditionsItem } from "../services/AuditionServices/AuditionsTypes";
import { AuditionType } from "../types/MainTypes/AuditionType";
import { CompanyTypeFilter } from "../types/MainTypes/CompanyTypeFilter";
import { LocationTagFilter } from "../types/MainTypes/LocationTagFilter";
import { PerformerTypeFilter } from "../types/MainTypes/PerformerTypeFilter";
import { FormikType } from "../types/types";

type Props = FormikType<InitialAuditionStateType> & {
  selectedAudition: FetchAuditionsItem;
};

const setAuditionFormikValues = ({ formik, selectedAudition }: Props) => {
  formik.setFieldValue("title", selectedAudition.title);

  formik.setFieldValue("auditionLocation", selectedAudition.auditionLocation);
  formik.setFieldValue("isOnline", selectedAudition.isOnline);
  formik.setFieldValue("auditioningFor", selectedAudition.auditioningFor);
  formik.setFieldValue("appearance", selectedAudition.appearance);

  formik.setFieldValue("openDate", selectedAudition.openDate);
  formik.setFieldValue("closeDate", selectedAudition.closeDate);
  if (selectedAudition.publicAudition) {
    formik.setFieldValue(
      "expiryDate",
      selectedAudition.publicAudition.expiryDate
    );
  } else {
    formik.setFieldValue("expiryDate", "");
  }

  formik.setFieldValue("description", selectedAudition.description);

  if (selectedAudition.publicAudition) {
    formik.setFieldValue(
      "buttonLink",
      selectedAudition.publicAudition.buttonLink
    );
    formik.setFieldValue(
      "buttonTitle",
      selectedAudition.publicAudition.buttonTitle
    );
  } else {
    formik.setFieldValue("buttonLink", "");
    formik.setFieldValue("buttonTitle", "");
  }

  const auditionTypes: AuditionType[] = [];

  if (selectedAudition.auditionToAuditionTypes) {
    selectedAudition.auditionToAuditionTypes.map((a) =>
      auditionTypes.push(a.auditionType)
    );
  }

  formik.setFieldValue("auditionTypes", auditionTypes);

  const locationTagFilters: LocationTagFilter[] = [];

  if (selectedAudition.auditionToLocationTagFilters) {
    selectedAudition.auditionToLocationTagFilters.map((lt) =>
      locationTagFilters.push(lt.locationTagFilter)
    );
  }
  formik.setFieldValue("locationTagFilters", locationTagFilters);

  const performerTypeFilters: PerformerTypeFilter[] = [];

  if (selectedAudition.auditionToPerformerTypeFilters) {
    selectedAudition.auditionToPerformerTypeFilters.map((pt) =>
      performerTypeFilters.push(pt.performerTypeFilter)
    );
  }
  formik.setFieldValue("performerTypeFilters", performerTypeFilters);

  const companyTypeFilters: CompanyTypeFilter[] = [];

  if (selectedAudition) {
    selectedAudition.auditionToCompanyTypeFilters.map((ct) =>
      companyTypeFilters.push(ct.companyTypeFilter)
    );
  }
  formik.setFieldValue("companyTypeFilters", companyTypeFilters);

  formik.setFieldValue("company", selectedAudition.company);
  formik.setFieldValue("agent", selectedAudition.agent);
};

export default setAuditionFormikValues;
