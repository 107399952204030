import React, { useState } from "react";
import { InitialAuditionStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { CustomButton, LinkToAgentModal } from "../Components";

export type Props = FormikType<InitialAuditionStateType>;

export default function LinkToAgent({ formik }: Props) {
  const [isLinkToAgentModalVisible, setIsLinkToAgentModalVisible] =
    useState<boolean>(false);

  return (
    <div className="mb-8">
      <LinkToAgentModal
        formik={formik}
        isOpen={isLinkToAgentModalVisible}
        closeModal={() => setIsLinkToAgentModalVisible(false)}
      />
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Link To Agent
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Choose an Agent to whom you want to link this audition. On Industry
        Auditions, users will be able to see all linked Agents.
      </p>
      <div className="flex flex-row space-x-2">
        <CustomButton
          text="Edit"
          className="medium-green-button"
          onClick={() => setIsLinkToAgentModalVisible(true)}
        />
        {formik.values.agent ? (
          <CustomButton
            text={formik.values.agent.name}
            className="medium-white-button-no-hover"
          />
        ) : null}
      </div>
    </div>
  );
}
