import React from "react";
import { CheckedIcon, PlusIcon } from "../../../assets/icons/Icons";
import CrossIcon from "../../../assets/icons/svg/CrossIcon";

type Props = {
  text: string;
  isSelected?: boolean;
  onClickContainer?: () => void;
  onClickIcon: () => void;
  disabledIcon?: boolean;
  isSelectedToUpdate?: boolean;
};

export default function DataRowCard({
  text,
  isSelected = false,
  onClickContainer,
  onClickIcon,
  disabledIcon = false,
  isSelectedToUpdate = false,
}: Props) {
  const textStyle = () => {
    var className = "";
    if (onClickContainer) {
      className = className + " cursor-pointer";
      if (!isSelected) {
        className = className + " hover:text-black/60";
      } else {
        className = className + " hover:text-white/60";
      }
    }

    if (!isSelected) {
      className = className + " text-black";
    } else {
      className = className + " text-white";
    }

    return className;
  };

  return (
    <div
      className={`flex border-2 border-black rounded-3xl w-full justify-between items-center mb-2 bg-${
        !isSelected ? "white" : "black"
      }`}
    >
      <div
        onClick={onClickContainer}
        className="flex-row flex items-center w-full"
      >
        <p
          className={`w-full pl-4 font-medium font-Switzer text-l tracking-tight ${textStyle()}`}
        >
          {text}
        </p>
        {isSelectedToUpdate ? (
          <CheckedIcon
            className="mr-2"
            color={isSelected ? "white" : "black"}
          />
        ) : null}
      </div>
      {!isSelected ? (
        <PlusIcon
          className={`border-l-2 border-black cursor-pointer hover:opacity-60`}
          onClick={onClickIcon}
          disabled={disabledIcon}
        />
      ) : (
        <CrossIcon
          className={`border-l-2 border-white cursor-pointer hover:opacity-60`}
          onClick={onClickIcon}
          disabled={disabledIcon}
        />
      )}
    </div>
  );
}
