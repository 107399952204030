import React, { useEffect } from "react";
import { InitialCompanyStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  removeSelectedCompanyType,
  selectedCompanyTypeSelector,
  selectedCompanyTypesSelector,
  setSelectedCompanyType,
  setSelectedCompanyTypes,
} from "../../../../redux/slices/companyTypesSlice";
import { FormikType } from "../../../../types/types";
import DataRowCard from "../../cards/DataRowCard";

type Props = FormikType<InitialCompanyStateType>;

export default function CompanyTypeModalSelectedTypes({ formik }: Props) {
  const selectedCompanyTypes = useIASelector(selectedCompanyTypesSelector);
  const selectedCompanyType = useIASelector(selectedCompanyTypeSelector);

  const dispatch = useIADispatch();

  useEffect(() => {
    if (!formik.values.companyTypes) return;
    dispatch(setSelectedCompanyTypes(formik.values.companyTypes));
  }, [formik.values.companyTypes]);

  return (
    <div className="mx-4">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Selected Types
      </p>
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        {selectedCompanyTypes.map((c, i) => (
          <DataRowCard
            key={i}
            text={c.title}
            isSelected={true}
            onClickContainer={() => dispatch(setSelectedCompanyType(c))}
            onClickIcon={() => dispatch(removeSelectedCompanyType(c))}
            isSelectedToUpdate={
              selectedCompanyType ? selectedCompanyType.id === c.id : false
            }
          />
        ))}
      </div>
    </div>
  );
}
