export enum POST_FIlTERS {
  VIEW_ALL = "",
  MASTERCLASS = "Masterclass",
  NEWS = "News",
  UNDER_THE_SPOTLIGHT = "UnderTheSpotlight",
  GUIDES = "Guides",
  TIPS_AND_TRICKS = "TipsAndTricks",
}

export enum POST_TYPES {
  MASTERCLASS = "Masterclass",
  NEWS = "News",
  UNDER_THE_SPOTLIGHT = "UnderTheSpotlight",
  GUIDES = "Guides",
  TIPS_AND_TRICKS = "TipsAndTricks",
}

export enum SUBMISSION_TYPES {
  GENERAL = "General",
  COMPANY = "Company",
  AGENT = "Agent",
}

export enum SUBMISSION_FIlTERS {
  VIEW_ALL = "",
  GENERAL = "General",
  COMPANY = "Company",
  AGENT = "Agent",
}
