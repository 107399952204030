import React from "react";
import { InitialCompanyStateType } from "../../assets/data/initialState";
import { ErrorIcon } from "../../assets/icons/Icons";
import { FormikType } from "../../types/types";
import { ColoredButton, CustomButton } from "../Components";
export type Props = FormikType<InitialCompanyStateType>;

export default function CompanyAppearance({ formik }: Props) {
  const appearances = ["#D08D79", "#84B293", "#D0CD81", "#A6A2D3"];

  return (
    <div className="mb-8">
      <p className="mt-6 font-bold font-Switzer text-black mb-1 text-2xl tracking-tight">
        Appearance
      </p>
      <p className="mb-4 text-base font-Switzer text-black tracking-tight">
        Pick a cool colour for your audition.
      </p>
      <div className="flex space-x-2 items-center ">
        {appearances.map((a, i) => (
          <ColoredButton
            key={i}
            color={a}
            isSelected={a === formik.values.appearance}
            onClick={() => formik.setFieldValue("appearance", a)}
          />
        ))}
        <CustomButton
          text="Random"
          className="small-black-button"
          onClick={() =>
            formik.setFieldValue(
              "appearance",
              appearances[Math.floor(Math.random() * appearances.length)]
            )
          }
        />
      </div>
      {formik.touched.appearance && formik.errors.appearance && (
        <div
          className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          {formik.errors.appearance}
        </div>
      )}
    </div>
  );
}
