import React, { useEffect } from "react";
import { FormikType } from "../../../../types/types";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  removeSelectedCompanyTypeFilter,
  selectedCompanyTypeFilterSelector,
  selectedCompanyTypeFiltersSelector,
  setSelectedCompanyTypeFilter,
  setSelectedCompanyTypeFilters,
} from "../../../../redux/slices/companyTypeFiltersSlice";
import DataRowCard from "../../cards/DataRowCard";

type Props = FormikType<InitialAuditionStateType>;

export default function CompanyTypeFilterModalSelectedTypeFilters({
  formik,
}: Props) {
  const selectedCompanyTypeFilters = useIASelector(
    selectedCompanyTypeFiltersSelector
  );
  const selectedCompanyTypeFilter = useIASelector(
    selectedCompanyTypeFilterSelector
  );

  const dispatch = useIADispatch();

  useEffect(() => {
    if (!formik.values.companyTypeFilters) return;
    dispatch(setSelectedCompanyTypeFilters(formik.values.companyTypeFilters));
  }, [formik.values.companyTypeFilters]);

  return (
    <div className="mx-4">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Selected Types
      </p>
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        {selectedCompanyTypeFilters.map((ct, i) => (
          <DataRowCard
            key={i}
            text={ct.title}
            isSelected={true}
            onClickContainer={() => dispatch(setSelectedCompanyTypeFilter(ct))}
            onClickIcon={() => dispatch(removeSelectedCompanyTypeFilter(ct))}
            isSelectedToUpdate={
              selectedCompanyTypeFilter
                ? selectedCompanyTypeFilter.id === ct.id
                : false
            }
          />
        ))}
      </div>
    </div>
  );
}
