import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreateAgentRepresentationArgs,
  CreateAgentRepresentationReturnedData,
  DeleteAgentRepresentationArgs,
  DeleteAgentRepresentationReturnedData,
  FetchAgentRepresentationsArgs,
  FetchAgentRepresentationsReturnedData,
  UpdateAgentRepresentationArgs,
  UpdateAgentRepresentationReturnedData,
} from "./AgentRepresentationsTypes";

const AgentRepresentationsServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchAgentRepresentations: builder.query<
      FetchAgentRepresentationsReturnedData,
      FetchAgentRepresentationsArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `agents/representations?search=${args.search}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchAgentRepresentationsReturnedData };
      },
      providesTags: ["AgentRepresentations"],
    }),
    createAgentRepresentation: builder.mutation<
      CreateAgentRepresentationReturnedData,
      CreateAgentRepresentationArgs
    >({
      query: (args) => ({
        url: "agents/representations",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["AgentRepresentations"],
    }),
    updateAgentRepresentation: builder.mutation<
      UpdateAgentRepresentationReturnedData,
      UpdateAgentRepresentationArgs
    >({
      query: (args) => ({
        url: `agents/representations/${args.id}`,
        method: "PUT",
        body: {
          title: args.title,
        },
      }),
      invalidatesTags: ["AgentRepresentations"],
    }),
    deleteAgentRepresentation: builder.mutation<
      DeleteAgentRepresentationReturnedData,
      DeleteAgentRepresentationArgs
    >({
      query: (args) => ({
        url: `agents/representations/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AgentRepresentations"],
    }),
  }),
});

export const {
  useCreateAgentRepresentationMutation,
  useDeleteAgentRepresentationMutation,
  useUpdateAgentRepresentationMutation,
  useLazyFetchAgentRepresentationsQuery,
} = AgentRepresentationsServices;
