import React, { useEffect, useState } from "react";
import { BoldIcon, ErrorIcon, ItalicIcon } from "../../assets/icons/Icons";
import { FormikType } from "../../types/types";
import { InitialPostStateType } from "../../assets/data/initialState";
import { HtmlTextInput } from "../Components";

export type Props = FormikType<InitialPostStateType>;

export default function PostContent({ formik }: Props) {
  return (
    <div className="pb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1 color-black">
        Post Content
      </p>
      <p className="font-Switzer text-base tracking-tight mb-3 color-black">
        Write out a thorough amount of post content to accompany this post.
      </p>
      <HtmlTextInput
        text={formik.values.content}
        onChange={(text) => formik.setFieldValue("content", text)}
      />
      {formik.touched.content && formik.errors.content && (
        <div
          className="w-full mt-5 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          {formik.errors.content}
        </div>
      )}
    </div>
  );
}
