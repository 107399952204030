import React from "react";
import { Logo } from "../../../assets/images/Images";

export default function SplashLoader() {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <img src={Logo} alt="logo" />
      <div className="flex space-x-10 mt-14">
        <span className="animate-ping h-8 w-8 rounded-full bg-black"></span>
        <span className="animate-ping h-8 w-8 rounded-full bg-black animation-delay-75"></span>
        <span className="animate-ping h-8 w-8 rounded-full bg-black animation-delay-150"></span>
      </div>
    </div>
  );
}
