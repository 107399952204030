import React from "react";
import { FetchAgentsItem } from "../../../../services/AgentServices/AgentTypes";
import { Agent } from "../../../../types/MainTypes/Agent";
import DataRowCard from "../../cards/DataRowCard";

type Props = {
  agents: Agent[];
  selectedAgent: Agent | null;
  setSelectedAgent: (values: Agent | null) => void;
};

export default function LinkToAgentSelectedAgents({
  agents,
  selectedAgent,
  setSelectedAgent,
}: Props) {
  const handleClickAgent = () => {
    if (selectedAgent) {
      agents.unshift(selectedAgent);
    }
    setSelectedAgent(null);
  };

  return (
    <div className="ml-4">
      <p className="font-bold font-Switzer text-2xl text-black my-4">
        Selected Agent
      </p>
      <div className="w-full h-[35vh] overflow-y-auto">
        {selectedAgent ? (
          <DataRowCard
            text={selectedAgent.name}
            isSelected={true}
            onClickIcon={handleClickAgent}
          />
        ) : null}
      </div>
    </div>
  );
}
