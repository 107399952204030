import React from "react";
import { SearchIcon } from "../../../assets/icons/Icons";

type Props = {
  value: string;
  onChange: (text: string) => void;
};

export default function SearchBar({ value, onChange }: Props) {
  return (
    <form
      className="relative w-full h-10 items-center flex mb-4"
      onSubmit={(e) => e.preventDefault()}
    >
      <SearchIcon className="absolute left-[-3.5em]" />
      <input
        className="h-full w-full peer pt-8 pb-4 font-Switzer text-black border-b-2 border-black outline-none"
        value={value}
        type="text"
        required={true}
        onChange={(e) => onChange(e.target.value)}
      />
      <span
        className="absolute font-Switzer text-2xl left-0 text-black font-medium tracking-tight opacity-30
                  transition-all duration-[0.5s] pointer-events-none ease-in-out
                  peer-focus:translate-x-0 peer-focus:-translate-y-2
                  peer-focus:text-base peer-focus:opacity-100 peer-focus:pb-3
                  peer-valid:translate-x-0 peer-valid:-translate-y-2
                  peer-valid:text-base peer-valid:opacity-100 peer-valid:pb-3"
      >
        Search
      </span>
    </form>
  );
}
