import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useState } from "react";
import { ErrorIcon } from "../../../assets/icons/Icons";
import { useGetPresignedDownloadUrlQuery } from "../../../services/FileServices/FileServices";
import BasicLoader from "../loader/BasicLoader";

type Props = {
  fileName: string | undefined;
};

export default function AsyncImage({ fileName }: Props) {
  const [isImageError, setIsImageError] = useState(false);
  const { data, isError, isLoading } = useGetPresignedDownloadUrlQuery(
    {
      imageKey: fileName ?? "",
    } ?? skipToken
  );

  if (isLoading || !data)
    return (
      <div className="h-full w-full flex justify-center items-center rounded-lg">
        <BasicLoader color="black" />
      </div>
    );

  if (isError || isImageError)
    return (
      <div
        className="w-full h-full flex items-center font-medium text-lg text-ErrorRed justify-center"
        role="alert"
      >
        <ErrorIcon className="mr-2" width={"28"} height={"28"} />
        Image unavailable
      </div>
    );

  return (
    <img
      className="h-full w-full rounded-lg object-cover"
      src={data}
      alt="thumnail"
      onError={({ currentTarget }) => {
        setIsImageError(true);
      }}
      loading="lazy"
    />
  );
}
