import { InitialAuditionStateType } from "../../assets/data/initialState";
import { ErrorIcon } from "../../assets/icons/Icons";
import { FormikType, RFC } from "../../types/types";
import {
  TextInput,
  GooglePlacesInput,
  ColoredButton,
  CustomButton,
  BasicSwitch,
} from "../Components";

export type props = FormikType<InitialAuditionStateType>;

const AuditionGeneralDetails: RFC<props> = ({ formik }) => {
  const appearances = ["#D08D79", "#84B293", "#D0CD81", "#A6A2D3"];

  return (
    <div>
      <p className="font-Switzer font-bold text-3xl mb-2 text-black">
        General Details
      </p>
      <p className="font-Switzer text-black">
        Include the fundamental information regrading this audition.
      </p>
      <TextInput
        label="Title / Audition Name"
        name="Title / Audition Name"
        type="text"
        value={formik.values.title}
        required={true}
        onChange={(text) => formik.setFieldValue("title", text)}
        isTouched={formik.touched.title}
        error={formik.errors.title}
      />
      {!formik.values.isOnline ? (
        <GooglePlacesInput auditionFormik={formik} />
      ) : null}
      <div className="bg-IAGrey mt-2 items-center flex justify-between p-2 rounded-lg">
        <p className="font-Switzer font-bold">Online Audition</p>
        <BasicSwitch
          isChecked={formik.values.isOnline}
          onChange={(checked) => formik.setFieldValue("isOnline", checked)}
        />
      </div>
      <TextInput
        label="Auditioning For ..."
        name="Auditioning For"
        type="text"
        value={formik.values.auditioningFor}
        required={true}
        onChange={(text) => formik.setFieldValue("auditioningFor", text)}
        isTouched={formik.touched.auditioningFor}
        error={formik.errors.auditioningFor}
      />
      <p className="mt-6 font-bold font-Switzer text-black mb-1 text-2xl tracking-tight">
        Appearance
      </p>
      <p className="mb-4 text-base font-Switzer text-black tracking-tight">
        Pick a cool colour for your audition.
      </p>
      <div className="mb-8">
        <div className="flex space-x-2 items-center ">
          {appearances.map((a, i) => (
            <ColoredButton
              key={i}
              color={a}
              isSelected={a === formik.values.appearance}
              onClick={() => formik.setFieldValue("appearance", a)}
            />
          ))}
          <CustomButton
            text="Random"
            className="small-black-button"
            onClick={() =>
              formik.setFieldValue(
                "appearance",
                appearances[Math.floor(Math.random() * appearances.length)]
              )
            }
          />
        </div>
        {formik.touched.appearance && formik.errors.appearance && (
          <div
            className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
            role="alert"
          >
            <ErrorIcon className="mr-2" width={"28"} height={"28"} />
            {formik.errors.appearance}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuditionGeneralDetails;
