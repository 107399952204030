import React from "react";
import { InitialPostStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { TextInput } from "../Components";

export type Props = FormikType<InitialPostStateType>;

export default function PostExternalLinks({ formik }: Props) {
  return (
    <div className="pb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1 color-black">
        External Links
      </p>
      <p className="font-Switzer text-base tracking-tight mb-3 color-black">
        Allow users a method of accessing any additional information related to
        this post via an external link.
      </p>
      <TextInput
        label="Button Title(Max 40 Characters)"
        name="Button Title(Max 40 Characters)"
        type="text"
        value={formik.values.buttonTitle}
        required={true}
        onChange={(text) => formik.setFieldValue("buttonTitle", text)}
        isTouched={formik.touched.buttonTitle}
        error={formik.errors.buttonTitle}
      />
      <TextInput
        label="Button Link"
        name="Button Link"
        type="text"
        value={formik.values.buttonLink}
        required={true}
        onChange={(text) => formik.setFieldValue("buttonLink", text)}
        isTouched={formik.touched.buttonLink}
        error={formik.errors.buttonLink}
      />
    </div>
  );
}
