import { getLatLng } from "react-places-autocomplete";

const parseAddressComponents = async (details: google.maps.GeocoderResult) => {
  const subPremiseObj = details.address_components.find((obj) =>
    obj.types.includes("subpremise")
  );
  const subPremise = subPremiseObj?.long_name;
  const streetNumberObj = details.address_components.find((obj) =>
    obj.types.includes("street_number")
  );
  let streetNumber = streetNumberObj?.long_name;
  if (subPremise) {
    streetNumber = `${subPremise}/${streetNumber}`;
  }
  const streetNameObj = details.address_components.find((obj) =>
    obj.types.includes("route")
  );
  const streetName = streetNameObj?.long_name;

  const suburbObj = details.address_components.find((obj) =>
    obj.types.includes("locality")
  );
  const suburb = suburbObj?.long_name;

  const cityObj = details.address_components.find((obj) =>
    obj.types.includes("administrative_area_level_2")
  );
  const city = cityObj?.short_name;

  const stateObj = details.address_components.find((obj) =>
    obj.types.includes("administrative_area_level_1")
  );
  const state = stateObj?.short_name;

  const countryObj = details.address_components.find((obj) =>
    obj.types.includes("country")
  );
  const country = countryObj?.long_name;

  const postCodeObj = details.address_components.find((obj) =>
    obj.types.includes("postal_code")
  );
  const postCode = postCodeObj?.long_name;

  const fullAddress = details.formatted_address;

  const latlng = await getLatLng(details);

  return {
    streetNumber: streetNumber ? streetNumber : "",
    streetName: streetName ? streetName : "",
    suburb: suburb ? suburb : "",
    city: city ? city : "",
    postCode: postCode ? postCode : "",
    country: country ? country : "",
    state: state ? state : "",
    fullAddress: fullAddress ? fullAddress : "",
    longitude: latlng.lng,
    latitude: latlng.lat,
  };
};
export default parseAddressComponents;
