import React from "react";
import { DateInput } from "../Components";
import { FormikType, RFC } from "../../types/types";
import { InitialAuditionStateType } from "../../assets/data/initialState";

const AuditionDates: RFC<FormikType<InitialAuditionStateType>> = ({
  formik,
}) => {
  return (
    <div className="mb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1 color-black">
        Dates
      </p>
      <p className="font-Switzer text-base tracking-tight mb-3 color-black">
        Include the dates that users need to be aware of for this audition.
        Dates for auditions, submissions, and audition deadlines are included
      </p>
      <p className="font-Switzer font-semibold color-black">
        Submission/Audition Open Date
      </p>
      <DateInput
        value={formik.values.openDate}
        onChange={(text) => formik.setFieldValue("openDate", text)}
        isTouched={formik.touched.openDate}
        error={formik.errors.openDate}
      />
      <div className="flex mt-4">
        <p className="font-Switzer font-semibold color-black">
          Submission/Audition Close Date
        </p>
        <p className="font-normal ml-2">Leave Blank if none</p>
      </div>
      <DateInput
        value={formik.values.closeDate}
        onChange={(text) => formik.setFieldValue("closeDate", text)}
      />
      <p className="font-Switzer font-semibold color-black mt-4">
        Audition Expiry Date
      </p>
      <DateInput
        value={formik.values.expiryDate}
        onChange={(text) => formik.setFieldValue("expiryDate", text)}
        isTouched={formik.touched.expiryDate}
        error={formik.errors.expiryDate}
      />
    </div>
  );
};

export default AuditionDates;
