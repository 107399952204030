import { Dialog } from "@headlessui/react";
import React from "react";
import { InitialAgentStateType } from "../../../../assets/data/initialState";
import { useIASelector } from "../../../../redux/hooks";
import { selectedAgentRepresentationsSelector } from "../../../../redux/slices/agentRepresentationsSlice";
import { FormikType } from "../../../../types/types";
import CustomButton from "../../buttons/CustomButton";
import CustomModal from "../../CustomModal";
import ModalHeader from "../../headers/ModalHeader";
import AgentRepModalAgentReps from "./AgentRepModalAgentReps";
import AgentRepModalEdit from "./AgentRepModalEdit";
import AgentRepModalSelectedReps from "./AgentRepModalSelectedReps";

type Props = FormikType<InitialAgentStateType> & {
  isOpen: boolean;
  closeModal: () => void;
};

export default function AgentRepresentationModal({
  formik,
  isOpen,
  closeModal,
}: Props) {
  const selectedAuditionReps = useIASelector(
    selectedAgentRepresentationsSelector
  );

  const handleSetAgentRepresentation = () => {
    formik.setFieldValue("representations", selectedAuditionReps);
    closeModal();
  };

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/2">
        <ModalHeader
          title="Representation"
          description="Edit, add and manage the Representation Type tags for this Agent."
          closeModal={closeModal}
        />
        <div className="grid grid-cols-modal px-4 h-full">
          <div className="h-4/5 w-full justify-between flex flex-col">
            <div className="w-full grid grid-cols-auditionType">
              <AgentRepModalAgentReps formik={formik} />
              <AgentRepModalSelectedReps formik={formik} />
            </div>
            <div className="flex-row flex items-center justify-start my-4">
              <CustomButton
                className="medium-black-button mr-2"
                text="Set Representation Types"
                onClick={() => handleSetAgentRepresentation()}
              />
              <CustomButton
                className="medium-white-button"
                text="Cancel"
                onClick={closeModal}
              />
            </div>
          </div>
          <AgentRepModalEdit formik={formik} />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
