import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PostTag } from "../../types/MainTypes/PostTag";
import updateItemInArrayIfExists from "../../utils/updateItemInArrayIfExists";
import { RootState } from "../store";

export type initialPostTagsStateType = {
  postTags: PostTag[] | null;
  selectedPostTag: PostTag | null;
  selectedPostTags: PostTag[];
};

export const initialPostTagsState: initialPostTagsStateType = {
  postTags: null,
  selectedPostTag: null,
  selectedPostTags: [],
};

export const postTagsSlice = createSlice({
  name: "postTags",
  initialState: initialPostTagsState,
  reducers: {
    setPostTags: (state, action: PayloadAction<PostTag[]>) => {
      state.postTags = action.payload;
    },
    addSelectedPostTag: (state, action: PayloadAction<PostTag>) => {
      if (!state.postTags) return;
      state.selectedPostTags.unshift(action.payload);
      const filteredPostTags = state.postTags.filter(
        (p) => p.id !== action.payload.id
      );
      state.postTags = filteredPostTags;
    },
    removeSelectedPostTag: (state, action: PayloadAction<PostTag>) => {
      if (!state.postTags) return;
      const filteredSelectedPostTags = state.selectedPostTags.filter(
        (a) => a.id !== action.payload.id
      );
      state.selectedPostTags = filteredSelectedPostTags;
      state.postTags.unshift(action.payload);
    },
    setSelectedPostTags: (state, action: PayloadAction<PostTag[]>) => {
      state.selectedPostTags = action.payload;
    },
    addPostTag: (state, action: PayloadAction<PostTag>) => {
      if (!state.postTags) return;
      state.postTags.unshift(action.payload);
    },
    deletePostTag: (state, action: PayloadAction<string>) => {
      if (!state.postTags) return;
      const filteredPostTags = state.postTags.filter(
        (p) => p.id !== action.payload
      );
      state.postTags = filteredPostTags;
      const filteredSelectedPostTags = state.selectedPostTags.filter(
        (p) => p.id !== action.payload
      );
      state.selectedPostTags = filteredSelectedPostTags;
    },
    updateSelectedPostTag: (state, action: PayloadAction<PostTag>) => {
      if (!state.selectedPostTag) return;

      updateItemInArrayIfExists({
        array: state.selectedPostTags,
        item: action.payload,
      });
      updateItemInArrayIfExists({
        array: state.postTags,
        item: action.payload,
      });
    },
    setSelectedPostTag: (state, action: PayloadAction<PostTag | null>) => {
      if (!state.postTags) return;

      if (state.selectedPostTag?.id === action.payload?.id) {
        state.selectedPostTag = null;
        return;
      }
      state.selectedPostTag = action.payload;
    },
  },
});

export const postTagsSelector = (state: RootState) =>
  state.postTagsSlice.postTags;

export const selectedPostTagSelector = (state: RootState) =>
  state.postTagsSlice.selectedPostTag;

export const selectedPostTagsSelector = (state: RootState) =>
  state.postTagsSlice.selectedPostTags;

export const {
  setPostTags,
  addSelectedPostTag,
  removeSelectedPostTag,
  setSelectedPostTags,
  addPostTag,
  deletePostTag,
  updateSelectedPostTag,
  setSelectedPostTag,
} = postTagsSlice.actions;

export default postTagsSlice.reducer;
