import React from "react";

export default function TrashBinIcon({
  className,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="delete_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={className}
    >
      <path
        id="Path_1038"
        data-name="Path 1038"
        d="M0,0H40V40H0Z"
        fill="none"
      />
      <path
        id="Path_1039"
        data-name="Path 1039"
        d="M6.667,29.667A3.343,3.343,0,0,0,10,33H23.333a3.343,3.343,0,0,0,3.333-3.333v-20h-20Zm21.667-25H22.5L20.833,3H12.5L10.833,4.667H5V8H28.333Z"
        transform="translate(3.333 2)"
      />
    </svg>
  );
}
