import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { toast } from "react-toastify";
import { InitialCompanyStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import { setSelectedAuditionTypes } from "../../../../redux/slices/auditionTypesSlice";
import {
  addSelectedCompanyType,
  companyTypesSelector,
  selectedCompanyTypeSelector,
  setCompanyTypes,
  setSelectedCompanyType,
} from "../../../../redux/slices/companyTypesSlice";
import { useLazyFetchCompanyTypesQuery } from "../../../../services/CompanyTypesServices/CompanyTypesServices";
import { FormikType } from "../../../../types/types";
import asyncTimeout from "../../../../utils/asyncTimeout";
import DataRowCard from "../../cards/DataRowCard";
import BasicLoader from "../../loader/BasicLoader";

type Props = FormikType<InitialCompanyStateType>;

export default function CompanyTypeModalCompanyTypes({ formik }: Props) {
  const dispatch = useIADispatch();
  const companyTypes = useIASelector(companyTypesSelector);
  const selectedCompanyType = useIASelector(selectedCompanyTypeSelector);

  const take = 10;
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  //APIS
  const [fetchCompanyTypesQuery, { isLoading, isFetching }] =
    useLazyFetchCompanyTypesQuery();

  useEffect(() => {
    setSkip(0);
    dispatch(setSelectedCompanyType(null));
    fetchCompanyTypes();
  }, []);

  const fetchCompanyTypes = async () => {
    try {
      const response = await fetchCompanyTypesQuery(
        {
          search: "",
          skip: 0,
          take: take,
        },
        true
      ).unwrap();

      if (response) {
        const filteredCompanyTypes = response.filter(
          (c1) => !formik.values.companyTypes.some((c2) => c1.id === c2.id)
        );
        dispatch(setCompanyTypes(filteredCompanyTypes));
      }

      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreCompanyTypes();
    }
  };

  const fetchMoreCompanyTypes = async () => {
    setIsLoadingMore(true);
    try {
      const response = await fetchCompanyTypesQuery(
        {
          search: "",
          skip: skip + take,
          take: take,
        },
        true
      ).unwrap();

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!companyTypes) return;
        const newCompanyTypes = companyTypes.concat(response);
        dispatch(setCompanyTypes(newCompanyTypes));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderCompanyTypes = () => {
    if ((isLoading || isFetching) && !isLoadingMore)
      return (
        <div className="w-full">
          <BasicLoader color="black" />
        </div>
      );
    if (!companyTypes)
      return (
        <div className="w-full">
          <p className="font-Switzer text-base text-black">No Company Types</p>
        </div>
      );

    return (
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={50}
        >
          {companyTypes.map((c, i) => (
            <DataRowCard
              key={i}
              text={c.title}
              onClickContainer={() => dispatch(setSelectedCompanyType(c))}
              onClickIcon={() => dispatch(addSelectedCompanyType(c))}
              isSelectedToUpdate={
                selectedCompanyType ? selectedCompanyType.id === c.id : false
              }
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Company Types
      </p>
      {renderCompanyTypes()}
    </div>
  );
}
