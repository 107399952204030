import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreateAuditionArgs,
  CreateAuditionReturnedData,
  DeleteAuditionArgs,
  DeleteAuditionReturnedData,
  FetchAuditionArgs,
  FetchAuditionReturnedData,
  FetchAuditionsArgs,
  FetchAuditionsReturnedData,
  UpdateAuditionArgs,
  UpdateAuditionReturnedData,
} from "./AuditionsTypes";

const AuditionsServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchAuditions: builder.query<
      FetchAuditionsReturnedData,
      FetchAuditionsArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `auditions/admin?search=${args.search}&active=${args.active}&expired=${args.expired}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchAuditionsReturnedData };
      },
      providesTags: ["Auditions"],
    }),
    deleteAudition: builder.mutation<
      DeleteAuditionReturnedData,
      DeleteAuditionArgs
    >({
      query: (args) => ({
        url: `auditions/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Auditions"],
    }),
    fetchAudition: builder.query<FetchAuditionReturnedData, FetchAuditionArgs>({
      query: (args) => ({
        url: `auditions/${args.id}`,
        method: "GET",
      }),
    }),
    createAudition: builder.mutation<
      CreateAuditionReturnedData,
      CreateAuditionArgs
    >({
      query: (args) => ({
        url: "auditions",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["Auditions"],
    }),
    updateAudition: builder.mutation<
      UpdateAuditionReturnedData,
      UpdateAuditionArgs
    >({
      query: (args) => ({
        url: `auditions/${args.id}`,
        method: "PUT",
        body: args.payload,
      }),
      invalidatesTags: ["Auditions"],
    }),
  }),
});
export const {
  useLazyFetchAuditionsQuery,
  useCreateAuditionMutation,
  useLazyFetchAuditionQuery,
  useDeleteAuditionMutation,
  useUpdateAuditionMutation,
} = AuditionsServices;
