import React, { useEffect } from "react";
import { FormikType } from "../../../../types/types";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  removeSelectedLocationTagFilter,
  selectedLocationTagFilterSelector,
  selectedLocationTagFiltersSelector,
  setSelectedLocationTagFilter,
  setSelectedLocationTagFilters,
} from "../../../../redux/slices/locationTagFiltersSlice";
import DataRowCard from "../../cards/DataRowCard";
type Props = FormikType<InitialAuditionStateType>;

export default function LocationTagFilterModalSelectedTagFilters({
  formik,
}: Props) {
  const selectedLocationTagFilters = useIASelector(
    selectedLocationTagFiltersSelector
  );
  const selectedLocationTagFilter = useIASelector(
    selectedLocationTagFilterSelector
  );

  const dispatch = useIADispatch();

  useEffect(() => {
    if (!formik.values.locationTagFilters) return;
    dispatch(setSelectedLocationTagFilters(formik.values.locationTagFilters));
  }, [formik.values.locationTagFilters]);

  return (
    <div className="mx-4">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Selected Types
      </p>
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        {selectedLocationTagFilters.map((lt, i) => (
          <DataRowCard
            key={i}
            text={lt.title}
            isSelected={true}
            onClickContainer={() => dispatch(setSelectedLocationTagFilter(lt))}
            onClickIcon={() => dispatch(removeSelectedLocationTagFilter(lt))}
            isSelectedToUpdate={
              selectedLocationTagFilter
                ? selectedLocationTagFilter.id === lt.id
                : false
            }
          />
        ))}
      </div>
    </div>
  );
}
