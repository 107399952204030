import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  InitialAgentStateType,
  InitialAuditionStateType,
  InitialCompanyStateType,
} from "../../../assets/data/initialState";
import { ErrorIcon } from "../../../assets/icons/Icons";
import parseAddressComponents from "../../../utils/parseAddressComponent";
import BasicLoader from "../loader/BasicLoader";

export type ConditionProps =
  | {
      auditionFormik?: FormikState<InitialAuditionStateType> &
        FormikHelpers<InitialAuditionStateType> &
        FormikHandlers;
      companyFormik?: never;
      agentFormik?: never;
    }
  | {
      auditionFormik?: never;
      companyFormik?: FormikState<InitialCompanyStateType> &
        FormikHelpers<InitialCompanyStateType> &
        FormikHandlers;
      agentFormik?: never;
    }
  | {
      auditionFormik?: never;
      companyFormik?: never;
      agentFormik?: FormikState<InitialAgentStateType> &
        FormikHelpers<InitialAgentStateType> &
        FormikHandlers;
    };

export type Props = ConditionProps;

export default function GooglePlacesInput({
  auditionFormik,
  companyFormik,
  agentFormik,
}: ConditionProps) {
  const [searchAddress, setSearchAddress] = useState("");

  useEffect(() => {
    if (!auditionFormik) return;
    if (!auditionFormik.values.auditionLocation) {
      setSearchAddress("");
      return;
    }
    if (auditionFormik.values && auditionFormik.values.auditionLocation) {
      setSearchAddress(auditionFormik.values.auditionLocation.fullAddress);
    } else {
      setSearchAddress("");
    }
  }, [auditionFormik]);

  useEffect(() => {
    if (!companyFormik) return;
    if (!companyFormik.values.companyLocation) return;
    if (companyFormik.values && companyFormik.values.companyLocation) {
      setSearchAddress(companyFormik.values.companyLocation.fullAddress);
    } else {
      setSearchAddress("");
    }
  }, [companyFormik]);

  useEffect(() => {
    if (!agentFormik) return;
    if (!agentFormik.values.agentLocation) return;
    if (agentFormik.values && agentFormik.values.agentLocation) {
      setSearchAddress(agentFormik.values.agentLocation.fullAddress);
    } else {
      setSearchAddress("");
    }
  }, [agentFormik]);

  const handleSelect = async (address: string) => {
    const result = await geocodeByAddress(address);
    if (!result[0]) return;
    const parseAddress = await parseAddressComponents(result[0]);

    setSearchAddress(parseAddress.fullAddress);
    if (auditionFormik) {
      auditionFormik.setFieldValue("auditionLocation", parseAddress);
    }
    if (companyFormik) {
      companyFormik.setFieldValue("companyLocation", parseAddress);
    }
    if (agentFormik) {
      agentFormik.setFieldValue("agentLocation", parseAddress);
    }
  };

  return (
    <>
      <PlacesAutocomplete
        value={searchAddress}
        onChange={(address) => {
          if (!address && auditionFormik) {
            auditionFormik.setFieldValue("auditionLocation", "");
            auditionFormik.setFieldError("auditionLocation", "");
            auditionFormik.setFieldTouched("auditionLocation", false);
          }
          setSearchAddress(address);
        }}
        onSelect={handleSelect}
      >
        {({ suggestions, loading, getInputProps, getSuggestionItemProps }) => (
          <>
            <div className="relative w-full">
              <input
                name="googleMaps"
                required={true}
                {...getInputProps({
                  className:
                    "w-full pt-7 pb-2 outline-none text-l border-b-2 border-black font-Switzer peer",
                })}
              />
              <span
                className="absolute font-Switzer text-2xl left-0 py-4 text-black font-medium tracking-tighter
                    transition-all duration-[0.5s] pointer-events-none ease-in-out
                    peer-focus:translate-x-0 peer-focus:-translate-y-2
                    peer-focus:text-base peer-focus:py-3
                    peer-valid:translate-x-0 peer-valid:-translate-y-2
                    peer-valid:text-base peer-valid:py-3"
              >
                Location
              </span>
              <div className="autocomplete-dropdown-container">
                {loading && <BasicLoader color="black" className="py-2" />}
                {suggestions.map((suggestion, index) => {
                  return (
                    <div
                      className={`cursor-pointer ${
                        suggestion.active ? "bg-black/20" : "bg-white"
                      } font-Switzer text-base text-black py-2`}
                      {...getSuggestionItemProps(suggestion, {})}
                      key={index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </PlacesAutocomplete>
      {auditionFormik &&
        auditionFormik.touched.auditionLocation &&
        auditionFormik.errors.auditionLocation && (
          <div
            className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
            role="alert"
          >
            <ErrorIcon className="mr-2" width={"28"} height={"28"} />
            Country is required
          </div>
        )}
      {companyFormik &&
        companyFormik.touched.companyLocation &&
        companyFormik.errors.companyLocation && (
          <div
            className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
            role="alert"
          >
            <ErrorIcon className="mr-2" width={"28"} height={"28"} />
            Country is required
          </div>
        )}
      {agentFormik &&
        agentFormik.touched.agentLocation &&
        agentFormik.errors.agentLocation && (
          <div
            className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
            role="alert"
          >
            <ErrorIcon className="mr-2" width={"28"} height={"28"} />
            Country is required
          </div>
        )}
    </>
  );
}
