import React from "react";
import { useIASelector } from "../../redux/hooks";
import { selectedSubmissionSelector } from "../../redux/slices/submissionsSlice";
import {
  CustomButton,
  Header,
  SubmissionUserCard,
  TextInput,
  UserCard,
} from "../Components";

export default function SubmissionDetails() {
  const selectedSubmission = useIASelector(selectedSubmissionSelector);

  return (
    <div className="w-full">
      {selectedSubmission ? (
        <>
          <Header text={selectedSubmission.topic} />
          <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide text-black font-Switzer">
            <p className="text-3xl font-bold mb-2">Submission Details</p>
            <p className="text-base mb-4">
              See all the details that the user submitted in the form.
            </p>
            <p className="font-bold text-2xl mb-2">User</p>
            <SubmissionUserCard submission={selectedSubmission} />
            <p className="font-bold text-2xl mb-1">Title</p>
            <div className="pb-5 border-b-2 border-black mb-6">
              <p>{selectedSubmission.topic}</p>
            </div>
            <div className="flex flex-row items-center pb-5 border-b-2 border-black mb-6">
              <p className="font-bold text-2xl mr-4">Tag</p>
              <CustomButton
                text={selectedSubmission.tag}
                className={"medium-black-button-no-hover"}
              />
            </div>
            <p className="font-bold text-2xl mb-2">Details</p>
            <p className="text-base">{selectedSubmission.details}</p>
          </div>
        </>
      ) : (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <p className="font-Switzer font-semibold text-2xl mb-2">
            No Submission Selected
          </p>
          <p className="font-Switzer font-normal text-l mb-48">
            Please Select a Submission
          </p>
        </div>
      )}
    </div>
  );
}
