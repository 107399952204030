import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreateCompanyArgs,
  CreateCompanyReturnedData,
  DeleteCompanyArgs,
  DeleteCompanyReturnedData,
  FetchCompaniesArgs,
  FetchCompaniesReturnedData,
  UpdateCompanyArgs,
  UpdateCompanyReturnedData,
  FetchCompanyReturnedData,
  FetchCompanyArgs,
} from "./CompanyTypes";

const CompanyServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanies: builder.query<
      FetchCompaniesReturnedData,
      FetchCompaniesArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `companies/admin?search=${args.search}&active=${args.active}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);

        return { data: data as FetchCompaniesReturnedData };
      },
      providesTags: ["Companies"],
    }),
    fetchCompany: builder.query<FetchCompanyReturnedData, FetchCompanyArgs>({
      query: (args) => ({
        url: `companies/${args.id}`,
        method: "GET",
      }),
    }),
    updateCompany: builder.mutation<
      UpdateCompanyReturnedData,
      UpdateCompanyArgs
    >({
      query: (args) => ({
        url: `companies/${args.id}`,
        method: "PUT",
        body: args.payload,
      }),
      invalidatesTags: ["Companies"],
    }),
    deleteCompany: builder.mutation<
      DeleteCompanyReturnedData,
      DeleteCompanyArgs
    >({
      query: (args) => ({ url: `companies/${args.id}`, method: "DELETE" }),
      invalidatesTags: ["Companies"],
    }),
    createCompany: builder.mutation<
      CreateCompanyReturnedData,
      CreateCompanyArgs
    >({
      query: (args) => ({
        url: "companies",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const {
  useLazyFetchCompaniesQuery,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
  useCreateCompanyMutation,
  useLazyFetchCompanyQuery,
} = CompanyServices;
