import * as Yup from "yup";
import YupPassword from "yup-password";
import "yup-phone";
YupPassword(Yup);

const URL =
  /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g;

const email =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const instagramURL =
  /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/;

export const LoginSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  password: Yup.string().required("Password is required"),
});

export const AuditionTypeTitleSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export const LocationTagFilterTitleSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export const CompanyTypeFilterTitleSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export const PerformerTypeFilterTitleSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export const CompanyTypeTitleSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});
export const AgentRepTitleSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export const CreateAuditionSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  auditioningFor: Yup.string().required("Auditioning For is required"),
  appearance: Yup.string().required("Appearance is required"),
  openDate: Yup.string().required("Open date is required"),
  expiryDate: Yup.string().required("Expiry date is required"),
  buttonTitle: Yup.string()
    .required("Button title is required")
    .max(40, "Max 40 characters"),
  buttonLink: Yup.string()
    .required("Button link is required")
    .matches(URL, "Invalid URL(eg. https://www.google.com)"),
  description: Yup.string().required("Description is required"),
  isOnline: Yup.boolean(),
  auditionLocation: Yup.object()
    .when("isOnline", {
      is: false,
      then: Yup.object()
        .shape({
          latitude: Yup.number().required("Latitude is required"),
          longitude: Yup.number().required("Longitude is required"),
          country: Yup.string().required("Country is required"),
          fullAddress: Yup.string().required("FullAddress is required"),
        })
        .nullable(),
    })
    .nullable(),
});

export const CreateHubSchema = Yup.object({
  type: Yup.string().required("Please select the type"),
  title: Yup.string().required("Title is required"),
  author: Yup.string().required("Author is required"),
  buttonLink: Yup.string().matches(
    URL,
    "Invalid URL(eg. https://www.google.com)"
  ),
  content: Yup.string().required("Content is required"),
  postTags: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Please select post tags"),
      })
    )
    .min(1, "Please select audition types"),
});

export const PostTagTitleSchema = Yup.object({
  title: Yup.string().required("Title is required"),
});

export const CreateCompanySchema = Yup.object({
  name: Yup.string().required("Name is required"),
  appearance: Yup.string().required("Appearance is required"),
  description: Yup.string().required("Description is required"),
  websiteLink: Yup.string()
    .required("Website link is required")
    .matches(URL, "Invalid URL(eg. https://www.google.com)"),
  instagramLink: Yup.string()
    .required("Instagram link is required")
    .matches(
      instagramURL,
      "Invalid Instagram URL(eg. https://www.instagram.com/your-username)"
    ),
  emailLink: Yup.string().matches(email, "Invalid email"),
  companyTypes: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Please select company types"),
    })
  ),
  companyLocation: Yup.object().shape({
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    country: Yup.string().required("Country is required"),
    fullAddress: Yup.string().required("FullAddress is required"),
  }),
});

export const CreateAgentSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  appearance: Yup.string().required("Appearance is required"),
  description: Yup.string().required("Description is required"),
  websiteLink: Yup.string()
    .required("Website link is required")
    .matches(URL, "Invalid URL(eg. https://www.google.com)"),
  instagramLink: Yup.string()
    .required("Instagram link is required")
    .matches(
      instagramURL,
      "Invalid Instagram URL(eg. https://www.instagram.com/your-username)"
    ),
  emailLink: Yup.string()
    .required("Email link is required")
    .matches(email, "Invalid email"),
  agentRepresentations: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Please select agent representations"),
    })
  ),
  agentLocation: Yup.object().shape({
    latitude: Yup.number().required("Latitude is required"),
    longitude: Yup.number().required("Longitude is required"),
    country: Yup.string().required("Country is required"),
    fullAddress: Yup.string().required("FullAddress is required"),
  }),
});

export const CreateUserSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Must be a valid email").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .minLowercase(1, "Password must contain at least 1 lower case letter")
    .minUppercase(1, "Password must contain at least 1 upper case letter")
    .minNumbers(1, "Password must contain at least 1 number")
    .minSymbols(1, "Password must contain at least 1 special character"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  mobile: Yup.string()
    .required("Required")
    .phone("Must be a valid phone number"),
});
