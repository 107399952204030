import { Dialog } from "@headlessui/react";
import React from "react";
import { InitialCompanyStateType } from "../../../../assets/data/initialState";
import { useIASelector } from "../../../../redux/hooks";
import { selectedCompanyTypesSelector } from "../../../../redux/slices/companyTypesSlice";
import { FormikType } from "../../../../types/types";
import CustomButton from "../../buttons/CustomButton";
import CustomModal from "../../CustomModal";
import ModalHeader from "../../headers/ModalHeader";
import CompanyTypeModalCompanyTypes from "./CompanyTypeModalCompanyTypes";
import CompanyTypeModalEdit from "./CompanyTypeModalEdit";
import CompanyTypeModalSelectedTypes from "./CompanyTypeModalSelectedTypes";

type Props = FormikType<InitialCompanyStateType> & {
  isOpen: boolean;
  closeModal: () => void;
};

export default function CompanyTypeModal({
  formik,
  isOpen,
  closeModal,
}: Props) {
  const selectedCompanyTypes = useIASelector(selectedCompanyTypesSelector);

  const handleSetCompanyTypes = () => {
    formik.setFieldValue("companyTypes", selectedCompanyTypes);
    closeModal();
  };

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/2">
        <ModalHeader
          title="Company Type"
          description="Edit, add and manage the Company Types for this Company."
          closeModal={closeModal}
        />

        <div className="grid grid-cols-modal px-4 h-full">
          <div className="h-4/5 w-full justify-between flex flex-col">
            <div className="w-full grid grid-cols-auditionType">
              <CompanyTypeModalCompanyTypes formik={formik} />
              <CompanyTypeModalSelectedTypes formik={formik} />
            </div>
            <div className="flex-row flex items-center justify-start my-4">
              <CustomButton
                className="medium-black-button mr-2"
                text="Set Company Types"
                onClick={() => handleSetCompanyTypes()}
              />
              <CustomButton
                className="medium-white-button"
                text="Cancel"
                onClick={closeModal}
              />
            </div>
          </div>
          <CompanyTypeModalEdit formik={formik} />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
