import React from "react";
import { InitialCompanyStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { TextInput } from "../Components";

export type Props = FormikType<InitialCompanyStateType>;
export default function CompanyExternalLinks({ formik }: Props) {
  return (
    <div className="pb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1 color-black">
        External Links
      </p>
      <p className="font-Switzer text-base tracking-tight mb-3 color-black">
        Give users a way to access more information regarding this Company. Add
        a website, Instagram and email link.
      </p>
      <TextInput
        label="Website Link"
        name="Website Link"
        type="text"
        value={formik.values.websiteLink}
        required={true}
        onChange={(text) => formik.setFieldValue("websiteLink", text)}
        isTouched={formik.touched.websiteLink}
        error={formik.errors.websiteLink}
      />
      <TextInput
        label="Instagram Link"
        name="Instagram Link"
        type="text"
        value={formik.values.instagramLink}
        required={true}
        onChange={(text) => formik.setFieldValue("instagramLink", text)}
        isTouched={formik.touched.instagramLink}
        error={formik.errors.instagramLink}
      />
      <TextInput
        label="Email Link"
        name="Email Link"
        type="text"
        value={formik.values.emailLink}
        required={true}
        onChange={(text) => formik.setFieldValue("emailLink", text)}
        isTouched={formik.touched.emailLink}
        error={formik.errors.emailLink}
      />
    </div>
  );
}
