import React from "react";

type Props = {
  label: string;
  value: string;
};

export default function DateSingleItemInput({ label, value }: Props) {
  return (
    <>
      <div className="relative w-full border-b-2 border-black mt-4">
        <div className="h-10 flex w-full outline-none text-lfont-Switzer items-end pb-2">
          {value}
        </div>
        <span
          className={`top-0 absolute font-Switzer left-0 text-black tracking-tighter
                    transition-all duration-[0.5s] pointer-events-none ease-in-out
                    ${
                      value
                        ? "translate-x-0 -translate-y-4 font-medium text-base"
                        : "font-medium text-2xl"
                    }
                    `}
        >
          {label}
        </span>
      </div>
    </>
  );
}
