import React, {
  forwardRef,
  useState,
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  useEffect,
} from "react";
import { ErrorIcon } from "../../../assets/icons/Icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextInput from "./TextInput";
import moment from "moment";
import DateSingleItemInput from "./DateSingleItemInput";

type Props = {
  value: string | null;
  onChange: (value: string) => void;
  error?: string | undefined | null;
  isTouched?: boolean | undefined;
};

export default function DateInput({
  value,
  onChange,
  error,
  isTouched,
}: Props) {
  const [startDate, setStartDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!value) {
      setStartDate(null);
      return;
    }
    setStartDate(moment(value).toDate());
  }, [value]);

  const CustomInput = forwardRef<
    HTMLButtonElement,
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  >((props, ref) => (
    <button ref={ref} {...props}>
      {
        <div className="grid grid-cols-3 w-full gap-x-2">
          <DateSingleItemInput
            label="Day"
            value={
              moment(startDate).isValid() ? moment(startDate).format("DD") : ""
            }
          />
          <DateSingleItemInput
            label="Month"
            value={
              moment(startDate).isValid()
                ? moment(startDate).format("MMMM")
                : ""
            }
          />
          <DateSingleItemInput
            label="Year"
            value={
              moment(startDate).isValid()
                ? moment(startDate).format("YYYY")
                : ""
            }
          />
        </div>
      }
    </button>
  ));

  return (
    <>
      <div className="relative w-full">
        <DatePicker
          className="w-full justify-start flex cursor-pointer"
          selected={startDate}
          onChange={(date: Date) => {
            onChange(moment(date).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"));
            setStartDate(date);
          }}
          customInput={<CustomInput />}
        />
      </div>
      {isTouched && error && (
        <div
          className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          {error}
        </div>
      )}
    </>
  );
}
