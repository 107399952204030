import React from "react";

export default function ArrowLeftIcon({
  onClick,
  className,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="57"
      viewBox="0 0 57 57"
      className={className}
      onClick={onClick}
    >
      <g id="Group_601" data-name="Group 601" transform="translate(-16 -58)">
        <path
          id="Path_979"
          data-name="Path 979"
          d="M0,0H57V57H0Z"
          transform="translate(16 58)"
          fill="none"
        />
        <path
          id="Path_980"
          data-name="Path 980"
          d="M28,14.5H9.745L18.13,6.115,16,4,4,16,16,28l2.115-2.115L9.745,17.5H28Z"
          transform="translate(28.079 71)"
        />
      </g>
    </svg>
  );
}
