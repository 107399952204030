export type CustomErrorType = {
  data: {
    data: {
      message: string;
    };
  };
};

export type CustomMultipleErrorType = {
  data: {
    data: {
      message: string[];
    };
  };
};

export function isErrorWithMessage(error: unknown): error is CustomErrorType {
  return (
    typeof error === "object" &&
    error != null &&
    "data" in error &&
    typeof (error as any).data === "object" &&
    typeof (error as any).data.data === "object" &&
    (error as any).data.data.message != null
  );
}

export function isErrorWithMultipleMessages(
  error: unknown
): error is CustomMultipleErrorType {
  return (
    typeof error === "object" &&
    error != null &&
    typeof (error as any).data === "object" &&
    typeof (error as any).data.data === "object" &&
    (error as any).data.data.message != null
  );
}
