import React from "react";
import { ErrorIcon } from "../../../assets/icons/Icons";

type Props = {
  label: string;
  name: string;
  type: string;
  value: string;
  required?: boolean;
  onChange: (text: string) => void;
  isVisible?: boolean;
  onIconPress?: (visible: boolean) => void;
  error?: string | undefined | null;
  isTouched?: boolean | undefined;
  disabled?: boolean;
};

export default function TextInput({
  label,
  name,
  type,
  value,
  required,
  onChange,
  error,
  isTouched,
  disabled = false,
}: Props) {
  return (
    <div>
      <div className="relative w-full">
        <input
          disabled={disabled}
          name={name}
          type={type}
          value={value}
          required={required}
          onChange={(e) => onChange(e.target.value)}
          className={`w-full pt-7 pb-2 outline-none text-l border-b-2 border-black peer font-Switzer`}
        />
        <span
          className="absolute font-Switzer text-2xl left-0 py-4 text-black font-medium tracking-tighter
                    transition-all duration-300 pointer-events-none ease-in-out
                    peer-focus:translate-x-0 peer-focus:-translate-y-2
                    peer-focus:text-base peer-focus:py-3
                    peer-valid:translate-x-0 peer-valid:-translate-y-2
                    peer-valid:text-base peer-valid:py-3"
        >
          {label}
        </span>
      </div>
      {isTouched && error && (
        <div
          className="w-full mt-2 flex items-center font-medium text-lg text-ErrorRed"
          role="alert"
        >
          <ErrorIcon className="mr-2" width={"28"} height={"28"} />
          {error}
        </div>
      )}
    </div>
  );
}
