import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Admin } from "../../types/MainTypes/Admin";

export type initialAdminStateType = {
  admin?: Admin;
};

export const initialAdminState: initialAdminStateType = {
  admin: undefined,
};

export const adminSlice = createSlice({
  name: "admin",
  initialState: initialAdminState,
  reducers: {
    setAdmin: (state, action: PayloadAction<Admin | undefined>) => {
      state.admin = action.payload;
    },
  },
});

export const adminSelector = (state: RootState) => state.adminSlice.admin;

export const { setAdmin } = adminSlice.actions;
export default adminSlice.reducer;
