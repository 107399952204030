import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchPostsItem } from "../../services/PostServices/PostTypes";
import { RootState } from "../store";

export type initialPostsStateType = {
  posts: FetchPostsItem[] | null;
  selectedPost: FetchPostsItem | null;
};

export const initialPostsState: initialPostsStateType = {
  posts: null,
  selectedPost: null,
};

export const postsSlice = createSlice({
  name: "posts",
  initialState: initialPostsState,
  reducers: {
    setPosts: (state, action: PayloadAction<FetchPostsItem[] | null>) => {
      state.posts = action.payload;
    },
    setSelectedPost: (state, action: PayloadAction<FetchPostsItem | null>) => {
      state.selectedPost = action.payload;
    },
    filterEmptyPost: (state) => {
      if (!state.posts) return;
      const filteredPost = state.posts.filter((p) => p.id !== "new");
      state.posts = filteredPost;
    },
    addPost: (state, action: PayloadAction<FetchPostsItem>) => {
      if (!state.posts) return;
      state.posts.unshift(action.payload);
    },
    removePost: (state, action: PayloadAction<string>) => {
      if (!state.posts) return;
      const filteredPosts = state.posts.filter((p) => p.id !== action.payload);
      state.posts = filteredPosts;
    },
    updatePost: (state, action: PayloadAction<FetchPostsItem>) => {
      if (!state.posts) return;
      const postIndex = state.posts.findIndex(
        (p) => p.id === action.payload.id
      );
      if (postIndex === null || postIndex === undefined) return;
      state.posts[postIndex] = action.payload;
    },
  },
});

export const selectedPostSelector = (state: RootState) =>
  state.postsSlice.selectedPost;

export const postsSelector = (state: RootState) => state.postsSlice.posts;

export const isEmptyPostExistSelector = (state: RootState) => {
  if (!state.postsSlice.posts) return false;
  if (!state.postsSlice.selectedPost) return false;

  const emptyPost = state.postsSlice.posts.find((p) => p.id === "new");

  if (!emptyPost) return false;

  return true;
};

export const {
  setPosts,
  setSelectedPost,
  filterEmptyPost,
  addPost,
  removePost,
  updatePost,
} = postsSlice.actions;

export default postsSlice.reducer;
