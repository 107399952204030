import API from "../../redux/api";
import {
  GetPresignedDownloadUrlArgs,
  GetPresignedUploadUrlArgs,
  GetPresignedUploadUrlReturnedData,
} from "./FileTypes";

const FileServices = API.injectEndpoints({
  endpoints: (builder) => ({
    getPresignedUploadUrl: builder.mutation<
      GetPresignedUploadUrlReturnedData,
      GetPresignedUploadUrlArgs
    >({
      query: (args) => ({
        url: `pre-signed-url/upload/${args.rootFolder}`,
        method: "POST",
        body: {
          imageKey: args.imageKey,
        },
      }),
    }),
    getPresignedDownloadUrl: builder.query<string, GetPresignedDownloadUrlArgs>(
      {
        query: (args) => ({
          url: `pre-signed-url/download?imageKey=${args.imageKey}`,
          method: "GET",
          responseHandler: "text",
        }),
        providesTags: ["DownloadedFile"],
        extraOptions: { maxRetries: 0 },
      }
    ),
  }),
});

export const {
  useGetPresignedUploadUrlMutation,
  useGetPresignedDownloadUrlQuery,
} = FileServices;
