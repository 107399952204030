import React from "react";
import { InitialPostStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { FileInput } from "../Components";

type Props = FormikType<InitialPostStateType>;

export default function PostVideo({ formik }: Props) {
  return (
    <div className="mb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1">
        Video Content
      </p>
      <p className="font-Switzer text-base tracking-tight mb-2">
        Upload a video to this post. Before uploading a video, maximise upload
        and download times by compressing the video down
      </p>
      <FileInput
        type="videos"
        source={formik.values.video ?? ""}
        onSelect={(file) => formik.setFieldValue("newVideo", file)}
        onRemove={() => {
          formik.setFieldValue("video", "");
          formik.setFieldValue("newVideo", undefined);
        }}
      />
    </div>
  );
}
