import React from "react";

export default function LogoutIcon({
  className,
  onClick,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="logout_black_24dp_2_"
      data-name="logout_black_24dp (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={className}
      onClick={onClick}
    >
      <path
        id="Path_1129"
        data-name="Path 1129"
        d="M0,0H40V40H0Z"
        fill="none"
      />
      <path
        id="Path_1130"
        data-name="Path 1130"
        d="M29,10.556l-2.538,2.663,4.644,4.892H12.8v3.778H31.106l-4.644,4.873L29,29.444,38,20ZM5.6,6.778H20V3H5.6A3.7,3.7,0,0,0,2,6.778V33.222A3.7,3.7,0,0,0,5.6,37H20V33.222H5.6Z"
        transform="translate(0 0)"
      />
    </svg>
  );
}
