import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreatePostArgs,
  CreatePostReturnedData,
  DeletePostArgs,
  DeletePostReturnedData,
  FetchPostArgs,
  FetchPostReturnedData,
  FetchPostsArgs,
  FetchPostsReturnedData,
  UpdatePostArgs,
  UpdatePostReturnedData,
} from "./PostTypes";

const PostsServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchPost: builder.query<FetchPostReturnedData, FetchPostArgs>({
      query: (args) => ({
        url: `posts/${args.id}`,
        method: "GET",
      }),
    }),
    fetchPosts: builder.query<FetchPostsReturnedData, FetchPostsArgs>({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `posts?search=${args.search}&type=${args.type}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);

        return { data: data as FetchPostsReturnedData };
      },
      providesTags: ["Posts"],
    }),
    createPost: builder.mutation<CreatePostReturnedData, CreatePostArgs>({
      query: (args) => ({
        url: "posts",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["Posts"],
    }),
    updatePost: builder.mutation<UpdatePostReturnedData, UpdatePostArgs>({
      query: (args) => ({
        url: `posts/${args.id}`,
        method: "PUT",
        body: args.payload,
      }),
      invalidatesTags: ["Posts"],
    }),
    deletePost: builder.mutation<DeletePostReturnedData, DeletePostArgs>({
      query: (args) => ({
        url: `posts/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Posts"],
    }),
  }),
});

export const {
  useLazyFetchPostsQuery,
  useCreatePostMutation,
  useDeletePostMutation,
  useUpdatePostMutation,
  useLazyFetchPostQuery,
} = PostsServices;
