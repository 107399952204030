import { Switch } from "@headlessui/react";
import React from "react";
import { InitialAgentStateType } from "../../assets/data/initialState";
import { FormikType } from "../../types/types";
import { BasicSwitch } from "../Components";

export type Props = FormikType<InitialAgentStateType>;

export default function AgentActiveStatus({ formik }: Props) {
  return (
    <div className="pb-8">
      <p className="font-Switzer font-bold text-2xl tracking-tight mb-1 color-black">
        Company Active Status
      </p>
      <p className="font-Switzer text-base tracking-tight mb-3 color-black">
        You can dynamically hide and show this content to users on Industry
        Auditions by modifying the Company's Active Status Settings. When this
        Company is set to 'Off', it is In-Active and not visible to users. When
        this Company is 'On' it is active and, users will see it.
      </p>
      <div className="bg-IAGrey items-center flex justify-between p-2 rounded-lg">
        <p className="font-Switzer font-bold">Company Active Status</p>
        <BasicSwitch
          isChecked={formik.values.isActive}
          onChange={(checked) => formik.setFieldValue("isActive", checked)}
        />
      </div>
    </div>
  );
}
