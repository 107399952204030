import parsePhoneNumber from "libphonenumber-js";

const mobileParser = (phone: string) => {
  const phoneNumber = parsePhoneNumber(phone);
  if (phoneNumber) {
    return "+" + phoneNumber.countryCallingCode + phoneNumber.nationalNumber;
  } else {
    return phone;
  }
};

export default mobileParser;
