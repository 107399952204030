import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { FetchAuditionsItem } from "../../services/AuditionServices/AuditionsTypes";
import { AuditionType } from "../../types/MainTypes/AuditionType";

export type initialAuditionsStateType = {
  auditions: FetchAuditionsItem[] | null;
  selectedAudition: FetchAuditionsItem | null;
};

export const initialAuditionsState: initialAuditionsStateType = {
  auditions: null,
  selectedAudition: null,
};

export const auditionsSlice = createSlice({
  name: "auditions",
  initialState: initialAuditionsState,
  reducers: {
    setAuditions: (
      state,
      action: PayloadAction<FetchAuditionsItem[] | null>
    ) => {
      state.auditions = action.payload;
    },
    setSelectedAudition: (
      state,
      action: PayloadAction<FetchAuditionsItem | null>
    ) => {
      state.selectedAudition = action.payload;
    },
    addAudition: (state, action: PayloadAction<FetchAuditionsItem>) => {
      if (!state.auditions) return;
      state.auditions.unshift(action.payload);
    },
    removeAudition: (state, action: PayloadAction<string>) => {
      if (!state.auditions) return;
      const filteredAuditions = state.auditions.filter(
        (a) => a.id !== action.payload
      );
      state.auditions = filteredAuditions;
    },
    filterEmptyAudition: (state) => {
      if (!state.auditions) return;
      const filteredAuditions = state.auditions.filter((a) => a.id !== "new");
      state.auditions = filteredAuditions;
    },
    updateAudition: (state, action: PayloadAction<FetchAuditionsItem>) => {
      if (!state.auditions) return;
      const auditionIndex = state.auditions.findIndex(
        (a) => a.id === action.payload.id
      );
      if (auditionIndex === null || auditionIndex === undefined) return;
      state.auditions[auditionIndex] = action.payload;
    },
  },
});

export const selectedAuditionSelector = (state: RootState) =>
  state.auditionsSlice.selectedAudition;

export const auditionsSelector = (state: RootState) => {
  return state.auditionsSlice.auditions;
};

export const isEmptyAuditionExistSelector = (state: RootState) => {
  if (!state.auditionsSlice.auditions) return false;
  if (!state.auditionsSlice.selectedAudition) return false;

  const emptyAudition = state.auditionsSlice.auditions.find(
    (a) => a.id === "new"
  );

  if (!emptyAudition) return false;

  return true;
};

export const {
  setAuditions,
  setSelectedAudition,
  addAudition,
  removeAudition,
  filterEmptyAudition,
  updateAudition,
} = auditionsSlice.actions;
export default auditionsSlice.reducer;
