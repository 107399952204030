import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { InitialAuditionStateType } from "../../assets/data/initialState";
import { useIASelector } from "../../redux/hooks";
import { selectedAuditionSelector } from "../../redux/slices/auditionsSlice";
import { FormikType } from "../../types/types";
import setAuditionFormikValues from "../../utils/setAuditionFormikValues";
import {
  Header,
  AuditionGeneralDetails,
  AuditionType,
  LinkToCompany,
  LinkToAgent,
  AuditionDates,
  AuditionDescription,
  AuditionExternalLinks,
} from "../Components";
import LocationTagFilter from "./LocationTagFilter";
import PerformerTypeFilter from "./PerformerTypeFilter";
import CompanyTypeFilter from "./CompanyTypeFilter";

export type Props = FormikType<InitialAuditionStateType>;

export default function AuditionDetail({ formik }: Props) {
  const selectedAudition = useIASelector(selectedAuditionSelector);

  useEffect(() => {
    if (!selectedAudition) return;

    console.log("selectedAudition", selectedAudition);

    setAuditionFormikValues({
      formik: formik,
      selectedAudition: selectedAudition,
    });
  }, [selectedAudition]);

  return (
    <div className="w-full">
      <Header text="Audition Details" />
      {selectedAudition ? (
        <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
          <AuditionGeneralDetails formik={formik} />
          <LocationTagFilter formik={formik} />
          <PerformerTypeFilter formik={formik} />
          <CompanyTypeFilter formik={formik} />
          <LinkToCompany formik={formik} />
          <LinkToAgent formik={formik} />
          <AuditionDates formik={formik} />
          <AuditionDescription formik={formik} />
          <AuditionExternalLinks formik={formik} />
        </div>
      ) : (
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <p className="font-Switzer font-semibold text-2xl mb-2">
            No Audition Selected
          </p>
          <p className="font-Switzer font-normal text-l mb-48">
            Please Select a Audition
          </p>
        </div>
      )}
    </div>
  );
}
