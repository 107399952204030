import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { Company } from "../../../../types/MainTypes/Company";
import { FormikType } from "../../../../types/types";
import CustomModal from "../../CustomModal";
import ModalHeader from "../../headers/ModalHeader";
import LinkToCompanyCompanies from "./LinkToCompanyCompanies";
import LinkToCompanySelectedCompanies from "./LinkToCompanySelectedCompanies";
type Props = FormikType<InitialAuditionStateType> & {
  isOpen: boolean;
  closeModal: () => void;
};

export default function LinkToCompanyModal({
  isOpen,
  closeModal,
  formik,
}: Props) {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    setSelectedCompany(formik.values.company);
  }, [formik.values.company, isOpen]);

  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal}>
      <Dialog.Panel className="relative bg-white rounded-lg w-1/2">
        <ModalHeader
          title="Link To Company"
          description="Edit, add and manage the Company links."
          closeModal={closeModal}
        />
        <div className="grid grid-cols-modal px-4 h-full">
          <LinkToCompanyCompanies
            companies={companies}
            setCompanies={setCompanies}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            closeModal={closeModal}
            formik={formik}
          />
          <LinkToCompanySelectedCompanies
            companies={companies}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
        </div>
      </Dialog.Panel>
    </CustomModal>
  );
}
