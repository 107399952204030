import React from "react";

export default function CheckedIcon({
  onClick,
  className,
  color = "black",
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Group_589"
      data-name="Group 589"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 52.25 49.875"
      className={className}
      onClick={onClick}
      fill={color}
    >
      <g id="Group_588" data-name="Group 588">
        <path
          id="Path_966"
          data-name="Path 966"
          d="M53.25,26.414l-5.8-6.626.808-8.764L39.689,9.076,35.2,1.5,27.125,4.968,19.05,1.5,14.561,9.076,5.987,11,6.8,19.788,1,26.414,6.8,33.04l-.807,8.787,8.574,1.947,4.489,7.6,8.075-3.491L35.2,51.351l4.489-7.576,8.574-1.948-.808-8.764Zm-9.381,3.515-1.33,1.544.19,2.019.428,4.631-4.512,1.021-2,.451L35.6,41.353l-2.351,3.99-4.228-1.829-1.9-.807-1.876.807-4.227,1.829L18.67,41.376l-1.045-1.758-2-.451-4.513-1.021.427-4.655.19-2.019-1.33-1.544L7.341,26.438l3.064-3.515,1.33-1.544-.214-2.043-.427-4.608,4.513-1.021,2-.451L18.646,11.5,21,7.509l4.227,1.829,1.9.807L29,9.337l4.227-1.829L35.58,11.5l1.045,1.758,1.995.451,4.512,1.021L42.7,19.36l-.19,2.019,1.33,1.544,3.064,3.491Z"
          transform="translate(-1 -1.5)"
        />
        <path
          id="Path_967"
          data-name="Path 967"
          d="M15.315,21.835,9.805,16.3,6.29,19.84l9.025,9.049,17.433-17.48L29.233,7.87Z"
          transform="translate(6.274 7.259)"
          fill={color}
        />
      </g>
    </svg>
  );
}
