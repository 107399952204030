import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  FetchSubmissionsArgs,
  FetchSubmissionsReturnedData,
} from "./SubmissionTypes";

const SubmissionServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubmissions: builder.query<
      FetchSubmissionsReturnedData,
      FetchSubmissionsArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `admin/submissions?search=${args.search}&take=${args.take}&skip=${args.skip}&tag=${args.tag}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchSubmissionsReturnedData };
      },
      providesTags: ["Submissions"],
    }),
  }),
});

export const { useLazyFetchSubmissionsQuery } = SubmissionServices;
