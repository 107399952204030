import RCApi from "../../redux/RCApi";
import {
  fetchSubscriberArgs,
  fetchSubscriberReturnedData,
} from "./RevenueCatTypes";

const RevenueCatServices = RCApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubscriber: builder.query<
      fetchSubscriberReturnedData,
      fetchSubscriberArgs
    >({
      query: (args) => ({
        url: `subscribers/${args.appUserId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useFetchSubscriberQuery, useLazyFetchSubscriberQuery } =
  RevenueCatServices;
