import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { InitialCompanyStateType } from "../../../../assets/data/initialState";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  addCompanyType,
  deleteCompanyType,
  selectedCompanyTypeSelector,
  selectedCompanyTypesSelector,
  setSelectedCompanyType,
  updateSelectedCompanyType,
} from "../../../../redux/slices/companyTypesSlice";
import {
  useCreateCompanyTypeMutation,
  useDeleteCompanyTypeMutation,
  useUpdateCompanyTypeMutation,
} from "../../../../services/CompanyTypesServices/CompanyTypesServices";
import { isErrorWithMessage } from "../../../../services/helper";
import { FormikType } from "../../../../types/types";
import asyncTimeout from "../../../../utils/asyncTimeout";
import { CompanyTypeTitleSchema } from "../../../../utils/validationSchema";
import CustomButton from "../../buttons/CustomButton";
import TextInput from "../../inputfields/TextInput";

type Props = FormikType<InitialCompanyStateType>;

export default function CompanyTypeModalEdit({ formik }: Props) {
  const selectedCompanyType = useIASelector(selectedCompanyTypeSelector);
  const selectedCompanyTypes = useIASelector(selectedCompanyTypesSelector);
  const dispatch = useIADispatch();

  //APIS
  const [createCompanyType] = useCreateCompanyTypeMutation();
  const [updateCompanyType] = useUpdateCompanyTypeMutation();
  const [deleteCompanyTypeQuery] = useDeleteCompanyTypeMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (!isUpdate) return;
    formik.setFieldValue("companyTypes", selectedCompanyTypes);
    setIsUpdate(false);
  }, [selectedCompanyTypes, isUpdate]);

  useEffect(() => {
    setFieldTouched("title", false);
    setFieldError("title", "");

    if (!selectedCompanyType) {
      setFieldValue("title", "");
      return;
    }
    setFieldValue("title", selectedCompanyType.title);
  }, [selectedCompanyType]);

  const onSave = async () => {
    setIsLoading(true);
    try {
      const [response] = await Promise.all([
        createCompanyType({
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully created");
        dispatch(addCompanyType(response));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async () => {
    if (!selectedCompanyType) return;

    setIsLoading(true);

    try {
      const [response] = await Promise.all([
        updateCompanyType({
          id: selectedCompanyType.id,
          title: values.title,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully updated");
        dispatch(updateSelectedCompanyType(response));
        setIsUpdate(true);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onDelete = async () => {
    setIsDeleting(true);
    if (!selectedCompanyType) return;
    try {
      const [response] = await Promise.all([
        deleteCompanyTypeQuery({ id: selectedCompanyType.id }).unwrap(),
        asyncTimeout(300),
      ]);

      if (response) {
        toast.success("Successfully deleted");
        dispatch(setSelectedCompanyType(null));
        dispatch(deleteCompanyType(response.id));
      }
    } catch (error) {
      toast.error(
        isErrorWithMessage(error)
          ? error.data.data.message
          : "Something went wrong. Please try again"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: selectedCompanyType ? onUpdate : onSave,
    validationSchema: CompanyTypeTitleSchema,
  });

  return (
    <div className="h-full w-full justify-between flex flex-col border-l-2 border-black">
      <div className="mx-4">
        <p className="font-Switzer font-bold text-black text-2xl my-4">
          {selectedCompanyType ? "Update Company Type" : "Create Company Type"}
        </p>
        <p className="font-Switzer font-normal text-black text-base">
          {selectedCompanyType
            ? "Update company type to the library."
            : "Add a new company type to the library."}
        </p>
        <TextInput
          label="Company Type"
          name="Company Type"
          type="text"
          value={values.title}
          required={true}
          onChange={(text) => setFieldValue("title", text)}
          isTouched={touched.title}
          error={errors.title}
        />
        <CustomButton
          text={selectedCompanyType ? "Update Type" : "Add Type"}
          className="medium-green-button mt-6"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading || isDeleting}
        />
      </div>
      <div>
        <CustomButton
          text="Delete Type"
          onClick={onDelete}
          className="medium-white-button mb-4 ml-4"
          loaderColor="black"
          isLoading={isDeleting}
          disabled={isLoading || isDeleting}
        />
      </div>
    </div>
  );
}
