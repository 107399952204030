import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreatePerformerTypeFilterArgs,
  CreatePerformerTypeFilterData,
  DeletePerformerTypeFilterArgs,
  DeletePerformerTypeFilterData,
  FetchPerformerTypeFiltersArgs,
  FetchPerformerTypeFiltersData,
  UpdatePerformerTypeFilterArgs,
  UpdatePerformerTypeFilterData,
} from "./PerformerTypeFiltersTypes";

const PerformerTypeFiltersServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchPerformerTypeFilters: builder.query<
      FetchPerformerTypeFiltersData,
      FetchPerformerTypeFiltersArgs
    >({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `auditions/performer-type-filter?search=${args.search}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);
        return { data: data as FetchPerformerTypeFiltersData };
      },
      providesTags: ["PerformerTypeFilters"],
    }),
    createPerformerTypeFilter: builder.mutation<
      CreatePerformerTypeFilterData,
      CreatePerformerTypeFilterArgs
    >({
      query: (args) => ({
        url: "auditions/performer-type-filter",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["PerformerTypeFilters"],
    }),
    updatePerformerTypeFilter: builder.mutation<
      UpdatePerformerTypeFilterData,
      UpdatePerformerTypeFilterArgs
    >({
      query: (args) => ({
        url: `auditions/performer-type-filter/${args.id}`,
        method: "PUT",
        body: {
          title: args.title,
        },
      }),
      invalidatesTags: ["PerformerTypeFilters"],
    }),
    deletePerformerTypeFilter: builder.mutation<
      DeletePerformerTypeFilterData,
      DeletePerformerTypeFilterArgs
    >({
      query: (args) => ({
        url: `auditions/performer-type-filter/${args.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PerformerTypeFilters"],
    }),
  }),
});

export const {
  useCreatePerformerTypeFilterMutation,
  useFetchPerformerTypeFiltersQuery,
  useLazyFetchPerformerTypeFiltersQuery,
  useUpdatePerformerTypeFilterMutation,
  useDeletePerformerTypeFilterMutation,
} = PerformerTypeFiltersServices;
