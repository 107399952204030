import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { FetchCompaniesItem } from "../../services/CompanyServices/CompanyTypes";

export type initialCompaniesStateType = {
  companies: FetchCompaniesItem[] | null;
  selectedCompany: FetchCompaniesItem | null;
};

export const initialCompaniesState: initialCompaniesStateType = {
  companies: null,
  selectedCompany: null,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState: initialCompaniesState,
  reducers: {
    setCompanies: (
      state,
      action: PayloadAction<FetchCompaniesItem[] | null>
    ) => {
      state.companies = action.payload;
    },
    setSelectedCompany: (
      state,
      action: PayloadAction<FetchCompaniesItem | null>
    ) => {
      state.selectedCompany = action.payload;
    },
    addCompany: (state, action: PayloadAction<FetchCompaniesItem>) => {
      if (!state.companies) return;
      state.companies.unshift(action.payload);
    },
    removeCompany: (state, action: PayloadAction<string>) => {
      if (!state.companies) return;
      const filteredCompanies = state.companies.filter(
        (c) => c.id !== action.payload
      );
      state.companies = filteredCompanies;
    },
    filterEmptyCompany: (state) => {
      if (!state.companies) return;
      const filteredCompanies = state.companies.filter((c) => c.id !== "new");
      state.companies = filteredCompanies;
    },
    updateCompany: (state, action: PayloadAction<FetchCompaniesItem>) => {
      if (!state.companies) return;
      const companyIndex = state.companies.findIndex(
        (c) => c.id === action.payload.id
      );
      if (companyIndex === null || companyIndex === undefined) return;
      state.companies[companyIndex] = action.payload;
    },
  },
});

export const companiesSelector = (state: RootState) =>
  state.companiesSlice.companies;
export const selectedCompanySelector = (state: RootState) =>
  state.companiesSlice.selectedCompany;
export const isEmptyCompanyExistSelector = (state: RootState) => {
  if (!state.companiesSlice.companies) return false;
  if (!state.companiesSlice.selectedCompany) return false;

  const emptyCompany = state.companiesSlice.companies.find(
    (c) => c.id === "new"
  );

  if (!emptyCompany) return false;

  return true;
};

export const {
  setCompanies,
  setSelectedCompany,
  addCompany,
  removeCompany,
  filterEmptyCompany,
  updateCompany,
} = companiesSlice.actions;
export default companiesSlice.reducer;
