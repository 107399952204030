import React from "react";
import Navbar from "./Navbar";

type Props = {
  page: "auditions" | "discover" | "hub" | "users";
  children: JSX.Element;
};

export default function DashboardLayout({ children, page }: Props) {
  return (
    <div className="w-screen h-screen overflow-hidden">
      <Navbar page={page} />
      {children}
    </div>
  );
}
