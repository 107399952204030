import React, { useEffect, useState } from "react";
import { InitialAuditionStateType } from "../../../../assets/data/initialState";
import { FormikType } from "../../../../types/types";
import { useIADispatch, useIASelector } from "../../../../redux/hooks";
import {
  addSelectedCompanyTypeFilter,
  companyTypeFiltersSelector,
  selectedCompanyTypeFilterSelector,
  setCompanyTypeFilters,
  setSelectedCompanyTypeFilter,
} from "../../../../redux/slices/companyTypeFiltersSlice";
import { useLazyFetchCompanyTypeFiltersQuery } from "../../../../services/CompanyTypeFiltersServices/CompanyTypeFiltersServices";
import { Primitives } from "../../../../assets/primitives";
import { toast } from "react-toastify";
import BasicLoader from "../../loader/BasicLoader";
import InfiniteScroll from "react-infinite-scroller";
import DataRowCard from "../../cards/DataRowCard";

type Props = FormikType<InitialAuditionStateType>;

export default function CompanyTypeFilterModalCompanyTypeFilters({
  formik,
}: Props) {
  const dispatch = useIADispatch();
  const companyTypeFilters = useIASelector(companyTypeFiltersSelector);
  const selectedCompanyTypeFilter = useIASelector(
    selectedCompanyTypeFilterSelector
  );

  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const [fetchCompanyTypeFiltersQuery, { isLoading, isFetching }] =
    useLazyFetchCompanyTypeFiltersQuery();

  useEffect(() => {
    setSkip(0);
    dispatch(setSelectedCompanyTypeFilter(null));
    fetchCompanyTypeFilters();
  }, []);

  const fetchCompanyTypeFilters = async () => {
    try {
      const response = await fetchCompanyTypeFiltersQuery(
        {
          search: "",
          skip: Primitives.IA_INITIAL_SKIP,
          take: Primitives.IA_TAKE,
        },
        true
      ).unwrap();

      if (response) {
        const filteredCompanyTypeFilters = response.filter(
          (c1) =>
            !formik.values.companyTypeFilters.some((c2) => c1.id === c2.id)
        );
        dispatch(setCompanyTypeFilters(filteredCompanyTypeFilters));
      }

      if (response && response.length < Primitives.IA_TAKE) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreCompanyTypeFilters();
    }
  };

  const fetchMoreCompanyTypeFilters = async () => {
    setIsLoadingMore(true);
    try {
      const response = await fetchCompanyTypeFiltersQuery(
        {
          search: "",
          skip: skip + Primitives.IA_TAKE,
          take: Primitives.IA_TAKE,
        },
        true
      ).unwrap();

      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!companyTypeFilters) return;
        const newCompanyTypeFilters = companyTypeFilters.concat(response);
        dispatch(setCompanyTypeFilters(newCompanyTypeFilters));
      }

      setSkip(skip + Primitives.IA_TAKE);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const renderCompanyTypes = () => {
    if ((isLoading || isFetching) && !isLoadingMore)
      return (
        <div className="w-full">
          <BasicLoader color="black" />
        </div>
      );
    if (!companyTypeFilters)
      return (
        <div className="w-full">
          <p className="font-Switzer text-base text-black">No Location Tags</p>
        </div>
      );

    return (
      <div className="w-full h-[calc(31vh)] overflow-y-auto">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={50}
        >
          {companyTypeFilters.map((ct, i) => (
            <DataRowCard
              key={i}
              text={ct.title}
              onClickContainer={() =>
                dispatch(setSelectedCompanyTypeFilter(ct))
              }
              onClickIcon={() => dispatch(addSelectedCompanyTypeFilter(ct))}
              isSelectedToUpdate={
                selectedCompanyTypeFilter
                  ? selectedCompanyTypeFilter.id === ct.id
                  : false
              }
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="">
      <p className="font-Switzer font-bold text-black text-2xl my-4">
        Company Types
      </p>
      {renderCompanyTypes()}
    </div>
  );
}
