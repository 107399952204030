import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addAudition,
  auditionsSelector,
  filterEmptyAudition,
  isEmptyAuditionExistSelector,
  selectedAuditionSelector,
  setAuditions,
  setSelectedAudition,
} from "../../redux/slices/auditionsSlice";
import { useLazyFetchAuditionsQuery } from "../../services/AuditionServices/AuditionsServices";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  Header,
  SearchInput,
  SelectableRoundedButtons,
  AuditionCard,
  LogoLoader,
  BasicLoader,
} from "../Components";
import { emptyAudition } from "../../assets/data/emptyState";
import { toast } from "react-toastify";

export default function AuditionsList() {
  const filters = ["All Auditions", "Active", "Expired"];
  const take = 10;

  const [selectedFilter, setSelectedFilter] = useState<string>("All Auditions");
  const [searchText, setSearchText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const dispatch = useIADispatch();
  const auditions = useIASelector(auditionsSelector);
  const selectedAudition = useIASelector(selectedAuditionSelector);
  const isEmptyAuditionExist = useIASelector(isEmptyAuditionExistSelector);

  //APIS
  const [fetchAuditionsQuery, { isLoading, isFetching }] =
    useLazyFetchAuditionsQuery();

  useEffect(() => {
    setSkip(0);
    var timeout: ReturnType<typeof setTimeout>;
    if (searchText) {
      setIsTyping(true);
      timeout = setTimeout(() => {
        fetchAuditions();
      }, 300);
    } else {
      fetchAuditions();
    }
    return () => clearTimeout(timeout);
  }, [searchText, selectedFilter]);

  const fetchAuditions = async () => {
    console.log("...fetching auditions");

    try {
      const response = await fetchAuditionsQuery(
        {
          search: searchText,
          skip: 0,
          take: take,
          active: selectedFilter === "Active" ? true : false,
          expired: selectedFilter === "Expired" ? true : false,
        },
        true
      ).unwrap();

      if (response) {
        dispatch(setAuditions(response));
      }

      if (response && response.length < take) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsTyping(false);
    }
  };

  const fetchMore = () => {
    if (!isLoadingMore && !isLoading) {
      fetchMoreAuditions();
    }
  };

  const fetchMoreAuditions = async () => {
    setIsLoadingMore(true);

    try {
      const [response] = await Promise.all([
        fetchAuditionsQuery(
          {
            search: searchText,
            skip: skip + take,
            take: take,
            active: selectedFilter === "Active" ? true : false,
            expired: selectedFilter === "Expired" ? true : false,
          },
          true
        ).unwrap(),
        asyncTimeout(300),
      ]);
      if (!response || !response.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
        if (!auditions) return;
        const newAuditions = auditions.concat(response);
        dispatch(setAuditions(newAuditions));
      }

      setSkip(skip + take);
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoadingMore(false);
    }
  };

  const createEmptyAudition = () => {
    if (isEmptyAuditionExist) return;
    dispatch(addAudition(emptyAudition));
    dispatch(setSelectedAudition(emptyAudition));
  };

  const renderAuditionsList = () => {
    if ((isLoading || isFetching || isTyping) && !isLoadingMore)
      return (
        <div className="w-full">
          <LogoLoader className="mt-10" />
        </div>
      );

    if (!auditions) return <div></div>;

    if (!auditions.length)
      return (
        <div className="w-full flex flex-col items-center font-Switzer font-semibold text-lg">
          NO AUDITIONS
        </div>
      );

    return (
      <div className="w-full h-screen overflow-y-auto pb-96 scrollbar-hide">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          useWindow={false}
          loader={<BasicLoader color="black" className="py-1" />}
          initialLoad={true}
          threshold={150}
        >
          {auditions.map((audition, index) => (
            <AuditionCard
              key={index}
              audition={audition}
              isSelected={
                selectedAudition ? selectedAudition.id === audition.id : false
              }
              onClick={() => {
                dispatch(filterEmptyAudition());
                dispatch(setSelectedAudition(audition));
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <div className="w-full">
      <Header text="Auditions" type="add" onClick={createEmptyAudition} />
      <SearchInput value={searchText} onChange={setSearchText} />
      <SelectableRoundedButtons
        items={filters}
        selectedItem={selectedFilter}
        setSelectedItem={setSelectedFilter}
      />
      {renderAuditionsList()}
    </div>
  );
}
