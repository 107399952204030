type initialType = {
  id: string;
};

type Props<T> = {
  array: T[] | null;
  item: T;
};

const updateItemInArrayIfExists = <T extends initialType>({
  array,
  item,
}: Props<T>) => {
  if (!array) return;
  var findIndex = array.findIndex((i) => i.id === item.id);

  if (findIndex === null || findIndex === undefined || findIndex === -1) return;
  if (!array[findIndex]) return;
  array[findIndex] = item;
  return array;
};

export default updateItemInArrayIfExists;
