import React from "react";

export default function UserIcon({
  className,
  onClick,
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="people_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      className={className}
      onClick={onClick}
    >
      <path
        id="Path_1044"
        data-name="Path 1044"
        d="M0,0H40V40H0Z"
        fill="none"
      />
      <path
        id="Path_1045"
        data-name="Path 1045"
        d="M26,15a5,5,0,1,0-5-5A4.979,4.979,0,0,0,26,15ZM12.667,15a5,5,0,1,0-5-5A4.979,4.979,0,0,0,12.667,15Zm0,3.333C8.783,18.333,1,20.283,1,24.167v4.167H24.333V24.167C24.333,20.283,16.55,18.333,12.667,18.333Zm13.333,0c-.483,0-1.033.033-1.617.083a7.033,7.033,0,0,1,3.283,5.75v4.167h10V24.167C37.667,20.283,29.883,18.333,26,18.333Z"
        transform="translate(0.667 3.333)"
      />
    </svg>
  );
}
