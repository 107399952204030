import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { InitialAuditionState } from "../../assets/data/initialState";
import {
  DashboardLayout,
  Header,
  AuditionsList,
  AuditionDetail,
  AuditionDeleteSave,
} from "../../components/Components";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addAudition,
  filterEmptyAudition,
  selectedAuditionSelector,
  setSelectedAudition,
  updateAudition,
} from "../../redux/slices/auditionsSlice";
import {
  useCreateAuditionMutation,
  useLazyFetchAuditionQuery,
  useUpdateAuditionMutation,
} from "../../services/AuditionServices/AuditionsServices";
import {
  CreateAuditionArgs,
  UpdateAuditionArgs,
} from "../../services/AuditionServices/AuditionsTypes";
import { isErrorWithMultipleMessages } from "../../services/helper";
import { AuditionType } from "../../types/MainTypes/AuditionType";
import { OmitFields } from "../../types/types";
import asyncTimeout from "../../utils/asyncTimeout";
import { CreateAuditionSchema } from "../../utils/validationSchema";
import { LocationTagFilter } from "../../types/MainTypes/LocationTagFilter";
import { PerformerTypeFilter } from "../../types/MainTypes/PerformerTypeFilter";
import { CompanyTypeFilter } from "../../types/MainTypes/CompanyTypeFilter";

export default function Auditions() {
  const dispatch = useIADispatch();

  const selectedAudition = useIASelector(selectedAuditionSelector);

  //APIS
  const [createAudition] = useCreateAuditionMutation();
  const [updateAuditionQuery] = useUpdateAuditionMutation();
  const [fetchAudition] = useLazyFetchAuditionQuery();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setSelectedAudition(null));
  }, []);

  useEffect(() => {
    formik.setTouched({});
  }, [selectedAudition]);

  const onCreate = async () => {
    setIsLoading(true);
    try {
      const auditionTypes: OmitFields<AuditionType, "id">[] = [];
      const locationTagFilters: OmitFields<LocationTagFilter, "id">[] = [];
      const performerTypeFilters: OmitFields<PerformerTypeFilter, "id">[] = [];
      const companyTypeFilters: OmitFields<CompanyTypeFilter, "id">[] = [];
      formik.values.auditionTypes.map((a) =>
        auditionTypes.push({
          title: a.title,
        })
      );

      formik.values.locationTagFilters.map((lt) =>
        locationTagFilters.push({
          title: lt.title,
        })
      );

      formik.values.performerTypeFilters.map((pt) =>
        performerTypeFilters.push({
          title: pt.title,
        })
      );

      formik.values.companyTypeFilters.map((ct) =>
        companyTypeFilters.push({
          title: ct.title,
        })
      );

      var payload: CreateAuditionArgs = {
        title: formik.values.title,
        auditioningFor: formik.values.auditioningFor,
        appearance: formik.values.appearance,
        openDate: formik.values.openDate,
        closeDate: formik.values.closeDate,
        expiryDate: formik.values.expiryDate,
        description: formik.values.description,
        auditionTypes: auditionTypes,
        locationTagFilters: locationTagFilters,
        performerTypeFilters: performerTypeFilters,
        companyTypeFilters: companyTypeFilters,
        companyId: formik.values.company ? formik.values.company.id : null,
        agentId: formik.values.agent ? formik.values.agent.id : null,
        buttonTitle: formik.values.buttonTitle,
        buttonLink: formik.values.buttonLink,
        isOnline: formik.values.isOnline,
      };

      if (
        formik.values.auditionLocation &&
        formik.values.auditionLocation.fullAddress &&
        !formik.values.isOnline
      ) {
        const { id, auditionId, createdAt, updatedAt, ...auditionLocation } =
          formik.values.auditionLocation;
        payload.auditionLocation = auditionLocation;
      }

      const [response] = await Promise.all([
        createAudition(payload).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response) return;

      const audition = await fetchAudition({
        id: response.id,
      }).unwrap();

      if (!audition) return;

      dispatch(addAudition(audition));
      dispatch(filterEmptyAudition());
      dispatch(setSelectedAudition(audition));

      toast.success("Successfully created");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    if (!selectedAudition) return;

    try {
      const auditionTypes: OmitFields<AuditionType, "id">[] = [];
      const locationTagFilters: OmitFields<LocationTagFilter, "id">[] = [];
      const performerTypeFilters: OmitFields<PerformerTypeFilter, "id">[] = [];
      const companyTypeFilters: OmitFields<CompanyTypeFilter, "id">[] = [];
      formik.values.auditionTypes.map((a) =>
        auditionTypes.push({
          title: a.title,
        })
      );

      formik.values.locationTagFilters.map((lt) =>
        locationTagFilters.push({
          title: lt.title,
        })
      );

      formik.values.performerTypeFilters.map((pt) =>
        performerTypeFilters.push({
          title: pt.title,
        })
      );

      formik.values.companyTypeFilters.map((ct) =>
        companyTypeFilters.push({
          title: ct.title,
        })
      );

      var payload: UpdateAuditionArgs = {
        id: selectedAudition.id,
        payload: {
          title: formik.values.title,
          auditioningFor: formik.values.auditioningFor,
          appearance: formik.values.appearance,
          openDate: formik.values.openDate,
          closeDate: formik.values.closeDate,
          expiryDate: formik.values.expiryDate,
          description: formik.values.description,
          auditionTypes: auditionTypes,
          locationTagFilters: locationTagFilters,
          performerTypeFilters: performerTypeFilters,
          companyTypeFilters: companyTypeFilters,
          companyId: formik.values.company ? formik.values.company.id : null,
          agentId: formik.values.agent ? formik.values.agent.id : null,
          buttonTitle: formik.values.buttonTitle,
          buttonLink: formik.values.buttonLink,
          isOnline: formik.values.isOnline,
        },
      };

      if (
        formik.values.auditionLocation &&
        formik.values.auditionLocation.fullAddress &&
        !formik.values.isOnline
      ) {
        const { id, auditionId, createdAt, updatedAt, ...auditionLocation } =
          formik.values.auditionLocation;
        payload.payload.auditionLocation = auditionLocation;
      }


      const [response] = await Promise.all([
        updateAuditionQuery(payload).unwrap(),
        asyncTimeout(300),
      ]);

      const audition = await fetchAudition({
        id: response.id,
      }).unwrap();

      if (audition) {
        dispatch(updateAudition(audition));
        toast.success("Successfully updated");
      }
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: InitialAuditionState,
    validationSchema: CreateAuditionSchema,
    onSubmit:
      selectedAudition && selectedAudition.id !== "new" ? onSave : onCreate,
  });

  return (
    <DashboardLayout page="auditions">
      <div className="w-screen h-full overflow-hidden grid grid-cols-dashboard gap-4 justify-end">
        <AuditionsList />
        <AuditionDetail formik={formik} />
        {selectedAudition ? (
          <AuditionDeleteSave formik={formik} isLoading={isLoading} />
        ) : null}
      </div>
    </DashboardLayout>
  );
}
