import React, { useState } from "react";
import {
  AgentsMain,
  CompaniesMain,
  CustomButton,
  DashboardLayout,
} from "../../components/Components";

export default function Discover() {
  const pages = ["Companies", "Agents"];
  const [selectedPage, setSelectedPage] = useState<string>("Companies");

  const renderPage = () => {
    switch (selectedPage) {
      case "Companies":
        return <CompaniesMain />;
      case "Agents":
        return <AgentsMain />;
    }
  };

  return (
    <DashboardLayout page="discover">
      <>
        <div className="flex flex-row h-12 w-full justify-center items-center border-b-2 border-black space-x-4">
          {pages.map((p, k) => (
            <CustomButton
              className={`
                ${
                  selectedPage === p
                    ? "small-black-button"
                    : "small-white-button"
                }
              `}
              text={p}
              onClick={() => setSelectedPage(p)}
            />
          ))}
        </div>
        <div className="w-screen h-full overflow-hidden grid grid-cols-dashboard gap-4 justify-end">
          {renderPage()}
        </div>
      </>
    </DashboardLayout>
  );
}
