import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

import "./App.css";
import { NotAuthRoutes, AuthRequiredRoutes } from "./utils/CustomRoutes";
import Login from "./pages/Auth/Login";
import "react-toastify/dist/ReactToastify.css";
import { useLazyFetchMeQuery } from "./services/AdminServices/AdminServices";
import asyncTimeout from "./utils/asyncTimeout";
import { CustomModal, IAOverlay, SplashLoader } from "./components/Components";
import Auditions from "./pages/Auditions/Auditions";
import Discover from "./pages/Discover/Discover";
import Hub from "./pages/Hub/Hub";
import Users from "./pages/Users/Users";
import { useIADispatch, useIASelector } from "./redux/hooks";
import { overlaySelector } from "./redux/slices/overlaySlice";
import { setAdmin } from "./redux/slices/adminSlice";

function App() {
  const overlayState = useIASelector(overlaySelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchMeQuery] = useLazyFetchMeQuery();
  const dispatch = useIADispatch();

  useEffect(() => {
    fetchMe();
  }, []);

  const fetchMe = async () => {
    try {
      setIsLoading(true);
      const [response] = await Promise.all([
        fetchMeQuery().unwrap(),
        asyncTimeout(300),
      ]);
      dispatch(setAdmin(response));
    } catch (error) {
      localStorage.removeItem("IAAdminToken");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <SplashLoader />;
  }

  return (
    <Router>
      <ToastContainer />
      {overlayState.isLoading && <IAOverlay />}

      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route element={<NotAuthRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<AuthRequiredRoutes />}>
          <Route path="/auditions" element={<Auditions />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/hub" element={<Hub />} />
          <Route path="/users" element={<Users />} />
        </Route>

        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
