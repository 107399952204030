import React from "react";
import { useIASelector } from "../../redux/hooks";
import { selectedUserSelector } from "../../redux/slices/userSlice";

export default function UserOverview() {
  const selectedUser = useIASelector(selectedUserSelector);

  return (
    <div className="text-black font-Switzer mb-8">
      <p className="text-3xl font-bold mb-2">User Overview</p>
      <p className="text-base mb-6">See all of this users basic details.</p>
      <div className="flex flex-row items-center mb-2 text-2xl">
        <p className="font-semibold mr-4">Full Name:</p>
        <p className="font-light">
          {selectedUser
            ? selectedUser.firstName + " " + selectedUser.lastName
            : "N/A"}
        </p>
      </div>
      <div className="flex flex-row items-center mb-2 text-2xl">
        <p className="font-semibold mr-4">Email Address:</p>
        <p className="font-light">
          {selectedUser
            ? selectedUser.email
            : "N/A"}
        </p>
      </div>
      <div className="flex flex-row items-center text-2xl">
        <p className="font-semibold mr-4">Mobile Number:</p>
        <p className="font-light">
          {selectedUser
            ? selectedUser.mobile
            : "N/A"}
        </p>
      </div>
    </div>
  );
}
