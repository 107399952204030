import API from "../../redux/api";
import asyncTimeout from "../../utils/asyncTimeout";
import {
  CreateAgentArgs,
  CreateAgentReturnedData,
  DeleteAgentArgs,
  DeleteAgentReturnedData,
  FetchAgentArgs,
  FetchAgentReturnedData,
  FetchAgentsArgs,
  FetchAgentsReturnedData,
  UpdateAgentArgs,
  UpdateAgentReturnedData,
} from "./AgentTypes";

const AgentsServices = API.injectEndpoints({
  endpoints: (builder) => ({
    fetchAgents: builder.query<FetchAgentsReturnedData, FetchAgentsArgs>({
      async queryFn(args, api, extraOptions, baseQuery) {
        const [{ data }] = await Promise.all([
          baseQuery({
            url: `agents/admin?search=${args.search}&active=${args.active}&take=${args.take}&skip=${args.skip}`,
          }),
          asyncTimeout(300),
        ]);

        return { data: data as FetchAgentsReturnedData };
      },
      providesTags: ["Agents"],
    }),
    fetchAgent: builder.query<FetchAgentReturnedData, FetchAgentArgs>({
      query: (args) => ({
        url: `agents/${args.id}`,
        method: "GET",
      }),
    }),
    updateAgent: builder.mutation<UpdateAgentReturnedData, UpdateAgentArgs>({
      query: (args) => ({
        url: `agents/${args.id}`,
        method: "PUT",
        body: args.payload,
      }),
      invalidatesTags: ["Agents"],
    }),
    createAgent: builder.mutation<CreateAgentReturnedData, CreateAgentArgs>({
      query: (args) => ({
        url: "agents",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["Agents"],
    }),
    deleteAgent: builder.mutation<DeleteAgentReturnedData, DeleteAgentArgs>({
      query: (args) => ({ url: `agents/${args.id}`, method: "DELETE" }),
      invalidatesTags: ["Agents"],
    }),
  }),
});

export const {
  useFetchAgentsQuery,
  useLazyFetchAgentsQuery,
  useCreateAgentMutation,
  useDeleteAgentMutation,
  useLazyFetchAgentQuery,
  useUpdateAgentMutation,
} = AgentsServices;
