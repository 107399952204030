import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { InitialPostState } from "../../assets/data/initialState";
import {
  DashboardLayout,
  HubDeleteSave,
  HubDetail,
  HubList,
} from "../../components/Components";
import { useIADispatch, useIASelector } from "../../redux/hooks";
import {
  addPost,
  filterEmptyPost,
  selectedPostSelector,
  setSelectedPost,
  updatePost,
} from "../../redux/slices/postsSlice";
import { useGetPresignedUploadUrlMutation } from "../../services/FileServices/FileServices";
import { isErrorWithMultipleMessages } from "../../services/helper";
import {
  useCreatePostMutation,
  useLazyFetchPostQuery,
  useUpdatePostMutation,
} from "../../services/PostServices/PostServices";
import { Post } from "../../types/MainTypes/Post";
import { PostTag } from "../../types/MainTypes/PostTag";
import { OmitFields } from "../../types/types";
import asyncTimeout from "../../utils/asyncTimeout";
import { CreateHubSchema } from "../../utils/validationSchema";

export default function Hub() {
  const selectedPost = useIASelector(selectedPostSelector);
  const dispatch = useIADispatch();

  //APIS
  const [createPost] = useCreatePostMutation();
  const [updatePostQuery] = useUpdatePostMutation();
  const [fetchPost] = useLazyFetchPostQuery();
  const [getPresignedUpload] = useGetPresignedUploadUrlMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setSelectedPost(null));
  }, []);

  useEffect(() => {
    formik.setTouched({});
  }, [selectedPost]);

  const onCreate = async () => {

    setIsLoading(true);
    try {
      const postTags: OmitFields<PostTag, "id">[] = [];

      formik.values.postTags.map((p) =>
        postTags.push({
          title: p.title,
        })
      );

      var image = await uploadFile({
        file: formik.values.newImage,
        type: "image",
      });
      var video = await uploadFile({
        file: formik.values.newVideo,
        type: "video",
      });

      var payload: OmitFields<Post, "id" | "createdAt" | "updatedAt"> & {
        postTags: OmitFields<PostTag, "id">[];
      } = {
        type: formik.values.type,
        title: formik.values.title,
        author: formik.values.author,
        image: image,
        video: video,
        buttonTitle: formik.values.buttonTitle,
        buttonLink: formik.values.buttonLink,
        postTags: postTags,
        content: formik.values.content,
      };

      const [response] = await Promise.all([
        createPost(payload).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response) return;

      const post = await fetchPost({
        id: response.id,
      }).unwrap();

      if (!post) return;

      dispatch(addPost(post));
      dispatch(filterEmptyPost());
      dispatch(setSelectedPost(post));

      toast.success("Successfully created");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async () => {
    if (!selectedPost) return;

    setIsLoading(true);
    try {
      const postTags: OmitFields<PostTag, "id">[] = [];

      formik.values.postTags.map((p) =>
        postTags.push({
          title: p.title,
        })
      );

      var image = await uploadFile({
        file: formik.values.newImage,
        type: "image",
      });
      var video = await uploadFile({
        file: formik.values.newVideo,
        type: "video",
      });

      var payload: OmitFields<Post, "id" | "createdAt" | "updatedAt"> & {
        postTags: OmitFields<PostTag, "id">[];
      } = {
        type: formik.values.type,
        title: formik.values.title,
        author: formik.values.author,
        image: image,
        video: video,
        buttonTitle: formik.values.buttonTitle,
        buttonLink: formik.values.buttonLink,
        postTags: postTags,
        content: formik.values.content,
      };

      const [response] = await Promise.all([
        updatePostQuery({
          id: selectedPost.id,
          payload,
        }).unwrap(),
        asyncTimeout(300),
      ]);

      if (!response) return;

      const post = await fetchPost({
        id: response.id,
      }).unwrap();

      if (!post) return;

      dispatch(updatePost(post));
      toast.success("Successfully updated");
    } catch (error) {
      console.log("error", error);
      toast.error(
        isErrorWithMultipleMessages(error)
          ? error.data.data.message[0]
          : "Something went wrong. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFile = async ({
    file,
    type,
  }: {
    file: File | undefined;
    type: "image" | "video";
  }) => {
    var existingUrl = formik.values[type];

    if (!existingUrl && !file) return null;

    if (existingUrl && !file) return existingUrl;

    if (!file) return null;

    try {
      const response = await getPresignedUpload({
        imageKey: file.name,
        rootFolder: type === "image" ? "images" : "videos",
      }).unwrap();
      if (!response) return null;

      const uploading = await axios.put(response.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      if (uploading.status !== 200) throw Error;
      return response.fileName;
    } catch (error) {
      console.log("error", error);

      throw error;
    }
  };

  const formik = useFormik({
    initialValues: InitialPostState,
    validationSchema: CreateHubSchema,
    onSubmit: selectedPost && selectedPost.id !== "new" ? onSave : onCreate,
  });

  return (
    <DashboardLayout page="hub">
      <div className="w-screen h-full overflow-hidden grid grid-cols-dashboard gap-4 justify-end">
        <HubList />
        <HubDetail formik={formik} />
        <HubDeleteSave formik={formik} isLoading={isLoading} />
      </div>
    </DashboardLayout>
  );
}
