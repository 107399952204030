import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomButton from "../buttons/CustomButton";
import AsyncImage from "../asyncUploads/AsyncImage";
import AsyncVideo from "../asyncUploads/AsyncVideo";
import { InputChangeEventHandler } from "../../../types/types";

type Props = {
  type: "images" | "videos";
  source: string | null;
  onSelect: (file: File | undefined) => void;
  onRemove: () => void;
};

export default function FileInput({ type, source, onSelect, onRemove }: Props) {
  const [imagePreview, setImagePreview] = useState<File | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImagePreview(undefined);
  }, [source]);

  const onImageSelect: InputChangeEventHandler = ({ target }) => {
    if (!target || !target.files) return;
    setImagePreview(target.files[0]);
    onSelect(target.files[0]);
  };

  const onImageRemove = () => {
    setImagePreview(undefined);
    onRemove();
  };

  const renderThumnail = () => {
    if (source && !imagePreview) {
      return (
        <div className="bg-IAGrey h-52 items-center justify-center flex flex-col rounded-lg">
          {type === "images" && <AsyncImage fileName={source} />}
          {type === "videos" && <AsyncVideo fileName={source} />}
        </div>
      );
    }

    if (imagePreview) {
      if (type === "images") {
        return (
          <img
            src={URL.createObjectURL(imagePreview)}
            alt="PreviewImage"
            className="h-52 object-cover rounded-lg"
          />
        );
      } else {
        return (
          <video
            src={URL.createObjectURL(imagePreview)}
            className="h-52 object-cover rounded-lg"
            controls
          />
        );
      }
    }

    return (
      <div className="bg-IAGrey h-52 items-center justify-center flex flex-col rounded-lg">
        <p className="font-Switzer text-base font-normal text-black/60 tracking-tight">{`No ${
          type === "images" ? "Image" : "Video"
        } here yet.`}</p>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center w-full overflow-x-hidden">
        <div className="w-full">{renderThumnail()}</div>
        <div className="flex-col justify-center items-center ml-6 relative w-full">
          <input
            ref={inputRef}
            type="file"
            accept={
              type === "images" ? "image/*" : "video/mp4,video/x-m4v,video/*"
            }
            className="absolute invisible"
            onChange={onImageSelect}
          />
          <CustomButton
            text={`Upload ${type === "images" ? "Image" : "Video"}`}
            className="medium-black-button mb-2 ml-[3px]"
            onClick={() => {
              if (!inputRef.current) return;
              inputRef.current.click();
            }}
          />

          <CustomButton
            text={`Remove ${type === "images" ? "Image" : "Video"}`}
            className="medium-white-button"
            onClick={onImageRemove}
          />
        </div>
      </div>
    </>
  );
}
